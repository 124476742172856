import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";


export default function IFrame({src} : {src: string}) {
    const { setInfo } = usePageNameContext();

    const [iframeUrl, setIframeUrl] = useState(src);
    
    useEffect(() => {
        setIframeUrl(src);
    }, [src]);

    useEffect(() => {
        setInfo({
          name: "Widget",
          desc: "View and edit widget settings",
          fill: true,
        });
    }, []);
    
    return (
        <iframe
            src={iframeUrl}
            width="100%"
            // height="650px"
            style={{ height: "90vh" }}
            className="rounded-lg shadow"
        />
    );
}