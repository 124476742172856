import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { usePageNameContext } from "../../contexts/PageTitleContext";

import { IVehicle } from "../../models/Vehicle";

import LoadingWheel from "../../components/shared/LoadingWheel";

import VehicleTable from "../../components/vehicles/VehicleTable";

import useVehicles from "../../data/useVehicles";
import useContacts from "../../data/useContacts";
import useContactSingle from "../../data/useContactsSingle";


export default function VehicleList() {
  const location = useLocation();
  const { setInfo } = usePageNameContext();
  const { vehicles: allVehicles } = useVehicles();
  const { contact } = useContactSingle(location.state? location.state.filter.contactId : "");
  const [vehicles, setVehicles] = useState<IVehicle[] | undefined>(undefined);

  useEffect(() => {
    if (location.state?.filter && !contact.isLoading) {
      let foundContact = contact.data;
      setInfo({
        name: `Vehicles for ${foundContact?.data.fullname}`,
        desc: "View and manage filtered vehicles here",
      });
    } else {
      if (!allVehicles.isLoading) {
        setVehicles(allVehicles.data?.data);
      }
      setInfo({
        name: "Vehicles",
        desc: "View and manage all vehicles here",
      });
    }
  }, [setInfo, location, allVehicles.isLoading, allVehicles.data]);

  return allVehicles.isLoading || !vehicles ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      {location.state?.filter && (
        <div className="mt-8">
          <Link
            to={"/vehicles"}
            replace={true}
            state={undefined}
            className="inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-700"
          >
            <XMarkIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            Clear filter
          </Link>
        </div>
      )}
      <VehicleTable data={vehicles} />
    </>
  );
}
