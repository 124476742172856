import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";

import date from "date-and-time";
import { useNavigate } from "react-router-dom";

import { CameraIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import useLeads from "../../../data/useLeads";
import useMessages from "../../../data/useMessages";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import Modal from "../../../layouts/Modal";
import { ILead, LeadQualified, LeadStatus } from "../../../models/Lead";
import {
  CollectionReminder,
  QualifyLead,
} from "../../../services/TemplateService";
import classNames from "../../../utils/classNames";
import useNotification from "../../notifications/useNotifications";
import Pagination from "../../shared/Tables/Pagination";
import LeadsTableCallCount from "./TableSubComponents/LeadsTableCallCount";
import LeadsTableSubComponent from "./TableSubComponents/LeadsTableSubComponent";
import LeadUnreadMessage from "./TableSubComponents/LeadUnreadMessage";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import BulkCompleteLeads from "./BulkCompleteLeads";
import openInNewTab from "../../../utils/openInNewTab";
import PriceFormat from "../../../utils/priceFormat";
import { BookDropOffModal } from "./TableSubComponents/BookDropOffModal";

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + "p-3 cursor-pointer"}
      {...rest}
    />
  );
}

const colHelper = createColumnHelper<ILead>();

const cols = [
  {
    id: "select",
    header: ({ table }: any) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }: any) => (
      <div
        className="flex justify-center items-center flex-1 p-4"
        onClick={(e) => e.stopPropagation()}
      >
        <IndeterminateCheckbox
          {...{
            id: row.original.id,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
        <button
          onClick={(e) => {
            e.stopPropagation();
            row.toggleExpanded();
          }}
          style={{
            transform: `rotate(${!row.getIsExpanded() ? "0" : "-270"}deg)`,
          }}
          className="ml-3 p-1 transition duration-500 cursor-pointer border border-gray-400 rounded-full hover:bg-gray-200"
        >
          {/* {row.getIsExpanded() ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4"/>} */}
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    ),
  },
  colHelper.display({
    id: "image",
    cell: (info) => (
      <span className="flex justify-center relative">
        {info.row.original.vehicle?.vehicleImageUrl ? (
          <img
            className="w-32 h-20 object-contain"
            src={`${
              info.row.original.vehicle != null
                ? info.row.original.vehicle.vehicleImageUrl
                : null
            }`}
          />
        ) : (
          <CameraIcon className="w-14 text-gray-600" />
        )}
        {info.row.original.vehicle?.vehiclePremium && (
          <StarIcon className="w-8 h-8 text-amber-500 absolute -top-2 -right-2" />
        )}
      </span>
    ),
  }),
  colHelper.accessor("contact.fullname", {
    header: "Name",
    sortingFn: "auto",
  }),
  colHelper.accessor("contact.telephone1", {
    header: "Telephone",
    enableSorting: false,
  }),
  colHelper.accessor("vehicle.postcode", {
    header: "Postcode",
    enableSorting: false,
    cell: (info) => (
      <div>
        {!info.row.original.vehicle!.isDropOff
          ? info.getValue()
          : info.row.original.vehicle!.isDropOff && (
              <span className="px-2 py-1 rounded-full font-semibold bg-blue-100 text-blue-500 whitespace-nowrap">
                Drop Off
              </span>
            )}
      </div>
    ),
  }),
  colHelper.accessor("vehicle.vrm", {
    header: "VRM",
    enableSorting: false,
    cell: (info) =>
      info.row.original.vehicle?.correctedVrm !== null &&
      info.row.original.vehicle?.correctedVrm.toUpperCase().trim() !==
        info.row.original.vehicle?.vrm ? (
        <div className="text-red-600">
          <p>{info.getValue()}</p>
          <p>
            Corrected to: <br />
            <span className="font-bold">
              {info.row.original.vehicle?.correctedVrm.toUpperCase()}
            </span>
          </p>
        </div>
      ) : (
        <span>{info.getValue()}</span>
      ),
  }),
  colHelper.accessor("status", {
    header: "Status",
    enableSorting: false,
    cell: (info) => <span>{LeadStatus[info.getValue()]}</span>,
  }),
  colHelper.accessor("callCount", {
    header: "Call List",
    enableSorting: false,
    cell: (info) => <LeadsTableCallCount lead={info.row.original} />,
  }),
  colHelper.accessor("vehicle.scheduledCollectionOn", {
    header: "Collection",
    sortingFn: "auto",
    cell: (info) =>
      info.row.original.vehicle!.isDropOff ? (
        <div onClick={(e) => e.stopPropagation()}>
          <BookDropOffModal />
        </div>
      ) : (
        <span>
          {info.getValue() !== null
            ? date.format(new Date(info.getValue()!), "DD/MM/YYYY")
            : "Not Set"}
        </span>
      ),
  }),
  colHelper.accessor("vehicle.enginesWorldwideEngineOffer", {
    header: "Export Value",
    sortingFn: (rowA: any, rowB: any, columnId: any) => {
      //Sort by value of both engine export and front end
      return (
        parseFloat(rowA.original.vehicle?.enginesWorldwideEngineOffer ?? "0") +
        parseFloat(rowA.original.vehicle?.enginesWorldwideVfeOffer ?? "0") -
        parseFloat(rowB.original.vehicle?.enginesWorldwideEngineOffer ?? "0") -
        parseFloat(rowB.original.vehicle?.enginesWorldwideVfeOffer ?? "0")
      );
    },
    cell: (info) => (
      <span>
        {PriceFormat(
          parseFloat(
            info.row.original.vehicle?.enginesWorldwideEngineOffer ?? "0"
          ) +
            parseFloat(
              info.row.original.vehicle?.enginesWorldwideVfeOffer ?? "0"
            )
        )}
      </span>
    ),
  }),
  colHelper.accessor("id", {
    header: "Unread Messages",
    sortingFn: "auto",
    cell: (info) => <LeadUnreadMessage lead={info.row.original} />,
  }),
];

export default function LeadsTable({ data }: { data: ILead[] }) {
  const navigate = useNavigate();
  const { bulkUpdate, bulkComplete, update } = useLeads(true);
  const { sendText } = useMessages();
  const { addNotification } = useNotification();
  const { saveHandlers } = useDefaultCRUDHandlers("Leads");
  const [updateOpen, setUpdateOpen] = useState(false);
  const [textOpen, setTextOpen] = useState(false);
  const [completeOpen, setCompleteOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedStatus, setSelectedStatus] = useState("0");
  const [selectedText, setSelectedText] = useState("qualify");

  const handleRowUpdate = () => {
    var leadIds = new Array();

    table.getSelectedRowModel().flatRows.forEach((lead) => {
      leadIds.push(lead.original.id);
    });

    const body = {
      status: parseInt(selectedStatus as keyof LeadStatus),
      leads: leadIds,
    };

    bulkUpdate.mutate(body, {
      onSuccess: () => {
        table.resetRowSelection();
        saveHandlers.onSuccess();
        setUpdateOpen(false);
      },
      onError: saveHandlers.onError,
    });
  };

  const handleBulkText = () => {
    let leads = new Array();

    table.getSelectedRowModel().flatRows.forEach((lead) => {
      leads.push(lead.original);
    });

    let templateId = "";

    if (selectedText === "qualify") {
      templateId = QualifyLead.Message;
    } else if (selectedText === "collection") {
      templateId = CollectionReminder.Message;
    }

    leads.forEach((lead) => {
      const body = {
        leadId: lead.id,
        templateId: templateId,
        contactId: lead.contact.id,
        recipient: lead.contact.telephone1,
        substitutions: {
          "{{name}}": lead.contact?.fullname!,
          "{{make}}": lead.vehicle?.combinedMake!,
          "{{model}}": lead.vehicle?.combinedModel!,
          "{{telephone}}": lead.contact.telephone1!,
          "{{price}}": lead.price.toString()!,
        },
      };

      sendText.mutate(body, {
        onSuccess: () => {
          table.resetRowSelection();
          setTextOpen(false);
          addNotification({
            variant: "success",
            primaryText: "Texts sent successfully",
          });
        },
        onError: () => {
          addNotification({
            variant: "error",
            primaryText: "Error sending texts",
          });
        },
      });
    });
  };

  useEffect(() => {
    table.setPageSize(50);
  }, []);

  const table = useReactTable({
    data: data,
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleClassNames = (lead: ILead) => {
    let classNames = "";

    if (!lead.vehicle?.found) classNames = "bg-gray-200 ";
    else if (lead.vehicle.isDropOff === true) classNames = "bg-orange-100 ";
    else if (
      lead.qualified === LeadQualified.Qualified &&
      lead.status === LeadStatus["Awaiting Contact"]
    )
      classNames = "bg-green-200 ";
    else if (
      lead.qualified === LeadQualified.Stop &&
      lead.status === LeadStatus["Awaiting Contact"]
    )
      classNames = "bg-red-200 ";
    else if (
      lead.qualified === LeadQualified["No Match"] &&
      lead.status === LeadStatus["Awaiting Contact"]
    )
      classNames = "bg-yellow-200 ";

    // if (lead.vehicle?.vehiclePremium) {
    //   classNames += "border-4 border-amber-500 "
    // }

    return classNames;
  };

  return (
    <>
      <Modal width="max-w-2xl" open={updateOpen} setOpen={setUpdateOpen}>
        <h1 className="text-xl">Bulk Update</h1>
        <div className="mt-4">
          <p>Change all selected leads to which status?</p>
          <div>
            <select
              onChange={(e) => setSelectedStatus(e.target.value)}
              className="mt-2 border border-gray-400 rounded-md text-sm"
            >
              {Object.values(LeadStatus)
                .filter((ct) => isNaN(Number(ct)))
                .map((status, i) => (
                  <option key={status} value={i}>
                    {status}
                  </option>
                ))}
            </select>
          </div>
          <button
            className="mt-5 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-700"
            onClick={handleRowUpdate}
          >
            Update
          </button>
        </div>
      </Modal>
      <Modal width="max-w-2xl" open={textOpen} setOpen={setTextOpen}>
        <h1 className="text-xl">Bulk Text</h1>
        <div className="mt-4">
          <p>Send which text to all of these leads?</p>
          <div>
            <select
              onChange={(e) => setSelectedText(e.target.value)}
              className="mt-2 border border-gray-400 rounded-md text-sm"
            >
              <option key={0} value={"qualify"}>
                Qualifying Text
              </option>
              <option key={1} value={"collection"}>
                Collection Reminder
              </option>
            </select>
          </div>
          <button
            className="mt-5 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-700"
            onClick={handleBulkText}
          >
            Send
          </button>
        </div>
      </Modal>
      <BulkCompleteLeads
        leads={data}
        table={table}
        open={completeOpen}
        setOpen={setCompleteOpen}
      />

      <div className="flex justify-between">
        <div>
          <button
            className={classNames(
              table.getSelectedRowModel().flatRows.length === 0
                ? "bg-gray-200"
                : "bg-primary-600 hover:bg-primary-700",
              "mt-4 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
            )}
            type="button"
            disabled={table.getSelectedRowModel().flatRows.length === 0}
            onClick={() => setUpdateOpen(true)}
          >
            Bulk Update
          </button>
          <button
            className={classNames(
              table.getSelectedRowModel().flatRows.length === 0
                ? "bg-gray-200"
                : "bg-primary-600 hover:bg-primary-700",
              "ml-4 mt-4 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
            )}
            type="button"
            disabled={table.getSelectedRowModel().flatRows.length === 0}
            onClick={() => setTextOpen(true)}
          >
            Bulk Text
          </button>
          <button
            className={classNames(
              table.getSelectedRowModel().flatRows.length === 0
                ? "bg-gray-200"
                : "bg-primary-600 hover:bg-primary-700",
              "ml-4 mt-4 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
            )}
            type="button"
            disabled={table.getSelectedRowModel().flatRows.length === 0}
            onClick={() => setCompleteOpen(true)}
          >
            Bulk Complete
          </button>
        </div>

        <button
          className="bg-primary-600 hover:bg-primary-700 mt-4 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
          type="button"
          onClick={() => navigate("/leads/create")}
        >
          Create Lead
        </button>
      </div>
      <div className="mt-4 bg-white overflow-auto">
        <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-primary-50 text-gray-800">
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " ▲",
                          desc: " ▼",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              let rowCells = row.getVisibleCells();
              return (
                <Fragment key={i}>
                  <tr
                    key={row.id}
                    onClick={() => openInNewTab(`leads/${row.original.id}`)}
                    className={classNames(
                      handleClassNames(row.original),
                      "hover:bg-gray-100 hover:cursor-pointer"
                    )}
                  >
                    {rowCells.map((cell) => (
                      <td key={cell.id} className="px-3 py-3 border text-sm">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <tr>
                      {/* 2nd row is a custom 1 cell row */}
                      <td colSpan={row.getVisibleCells().length}>
                        <LeadsTableSubComponent lead={row.original} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
        <Pagination table={table} />
      </div>
    </>
  );
}
