import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { ITopUnlistedCategoriesResponse } from "../models/Part";
import useDefaultCRUDHandlers from "../hooks/useDefaultCRUDHandlers";

export default function usePartMutations() {
  const { get, post, put, patch, del, postDocs } = useApiHelper();
  const queryClient = useQueryClient();
  const { deleteHandlers } = useDefaultCRUDHandlers("Part");
  const staleTime = 1000 * 60; //1 minutes

  const markDamaged = useMutation(
    (partData: any) => patch(`/part/${partData.id}`, partData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["job"]);
        queryClient.invalidateQueries(["parts"]);
      },
    }
  );

  const deletePart = useMutation(
    (partId: string) => del(`/part/${partId}`, { deletedReason: 0 }),
    {
      onMutate: async (deletedId: string) => {
        // Cancel any outgoing refetches so they don't overwrite our optimistic update.
        await queryClient.cancelQueries(["pagedTable"]);

        // Get all queries with keys starting with "pagedTable".
        const previousQueries = queryClient.getQueriesData(["pagedTable"]);

        // Optimistically update all relevant pagedTable queries.
        previousQueries.forEach(([queryKey, oldData]) => {
          queryClient.setQueryData(queryKey, (currentData: any) => {
            if (!currentData) return currentData;
            return {
              ...currentData,
              data: {
                ...currentData.data,
                rows: currentData.data.rows.filter((item: any) => item.id !== deletedId),
              },
            };
          });
        });

        // Return context in case we need to roll back.
        return { previousQueries };
      },
      onError: (err, deletedId, context) => {
        // Roll back to the previous state if mutation fails.
        context?.previousQueries.forEach(([queryKey, oldData]) => {
          queryClient.setQueryData(queryKey, oldData);
        });
        deleteHandlers.onError();
      },
      onSuccess: () => {
        // Optionally invalidate queries to refetch fresh data.
        queryClient.invalidateQueries(["job"]);
        queryClient.invalidateQueries(["parts"]);
        queryClient.invalidateQueries(["pagedTable"]);
      },
    }
  );

  const patchPart = useMutation(
    (partData: any) => patch(`/part/${partData.id}`, partData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["job"]);
        queryClient.invalidateQueries(["parts"]);
        queryClient.invalidateQueries(["pagedTable"]);
      },
    }
  );

  const autoReorderImages = useMutation(
    (ids: any) => post(`/part/images/autoOrder`, ids),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["job"]);
        queryClient.invalidateQueries(["parts"]);
        queryClient.invalidateQueries(["pagedTable"]);
      },
    }
  );

  const topUnlisted = useMutation(
    () => get<ITopUnlistedCategoriesResponse>(`/part/stats/top/unlisted`),
    {
      onSuccess: (data, _, __) => {
        return data;
      },
    }
  );

  const createOffer = useMutation(
    (partId: string) => post(`/part/offers/default/${partId}`, null),
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["parts", partId]);
        queryClient.invalidateQueries(["parts", "multiple"]);
      },
    }
  );


  return {
    markDamaged,
    deletePart,
    patchPart,
    autoReorderImages,
    topUnlisted,
    createOffer
  };
}
