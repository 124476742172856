import { createRef, useEffect, useState } from "react";

import date from "date-and-time";

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import { ArrowTopRightOnSquareIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import LoadingWheel from "../../components/shared/LoadingWheel";
import useLeads from "../../data/useLeads";
import useTrucks from "../../data/useTrucks";
import useVehiclesMutations from "../../data/useVehiclesMutations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Modal from "../../layouts/Modal";
import { ILead, LeadStatus } from "../../models/Lead";
import { IVehicle, ScheduledCollectionSlot } from "../../models/Vehicle";
import Lead from "../../pages/Leads/Lead";
import classNames from "../../utils/classNames";
import stringToColour from "../../utils/stringToColour";


interface IEvent {
  title: string;
  backgroundColor: string;
  borderColor: string;
  date: string;
  id: string;
  className: string;
  extendedProps: ExtendedProps;
}

interface ExtendedProps {
  truckId: string | null;
}


export default function MonthView(
  { selectedDate, setSelectedDate, setLeadOnMap, vrm, editable = true, waiting = true }:
    { selectedDate: Date, setSelectedDate: any, setLeadOnMap: any, vrm?: string, editable?: boolean, waiting?: boolean }) {
  const { leads: allLeads, update: updateLead, distances } = useLeads();
  const { trucks } = useTrucks();
  const { update: updateVehicle } = useVehiclesMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Lead");
  const [events, setEvents] = useState<IEvent[] | undefined>(undefined);
  const [greens, setGreens] = useState<string[] | undefined>([]);
  const [ambers, setAmbers] = useState<string[] | undefined>([]);
  const [reds, setReds] = useState<string[] | undefined>([]);
  const [leads, setLeads] = useState<ILead[] | undefined>(undefined);
  const [unscheduledLeads, setUnscheduledLeads] = useState<ILead[] | undefined>(undefined);
  const [selectedLead, setSelectedLead] = useState<string>("");
  const [selectedDistanceLead, setSelectedDistanceLead] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDraggable, setIsDraggable] = useState(false);
  const [truckColour, setTruckColour] = useState(true);
  const [currentFilter, setCurrentFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const calendarRef = createRef<FullCalendar>();

  const filters = ["Postcode A-Z", "VRM A-Z", "Oldest to Newest", "Newest to Oldest"]


  const handleEventChange = async (id: string, newDate: Date, unscheduled: boolean = false) => {
    var foundLead = allLeads.data?.data.leads?.find((lead) => lead.id === id);

    if (foundLead === undefined) {
      saveHandlers.onError();
      return;
    }

    const vehicle: IVehicle = {
      ...foundLead?.vehicle!,
      scheduledCollectionOn: date.format(new Date(newDate), "YYYY-MM-DDTHH:mm:ss"),
      collectionStart: null,
      collectionEnd: null
    }
    var updatedLead: ILead = {
      ...foundLead,
      vehicle: vehicle,
      driverId: null,
    }

    var updated = leads?.map((lead) => {
      return lead.id === updatedLead?.id ? updatedLead : lead;
    })

    setLeads(updated);

    if (unscheduled) {
      var updatedUnscheduledLeads: ILead[] | undefined = unscheduledLeads?.filter((lead) =>
        lead.id !== updatedLead?.id
      );

      setUnscheduledLeads(updatedUnscheduledLeads);

      var updatedScheduledLeads = leads?.concat(updatedLead);
      setLeads(updatedScheduledLeads);
    }

    updateVehicle.mutate(vehicle, {
      onSuccess: () => {
        updateLead.mutate(updatedLead, saveHandlers);
      },
      onError: () => {
        saveHandlers.onError();
      }
    });
  }

  const handleEventDrop = (info: any) => {
    const id = info.event.id;
    const date = info.event.start;


  }

  const handleDistance = (id: string) => {

    if (selectedDistanceLead === id) {
      setSelectedDistanceLead("");
      setLeadOnMap(undefined);
      setGreens([]);
      setAmbers([]);
      setReds([]);
      return;
    }

    setLeadOnMap(allLeads.data?.data.leads?.find((lead) => lead.id === id));

    distances.mutate(id, {
      onSuccess: (data: any) => {
        setGreens(data.data.green);
        setAmbers(data.data.amber);
        setReds(data.data.red);
        setSelectedDistanceLead(id);
      },
      onError: () => {
        console.log("Error");
        saveHandlers.onError();
      }
    })
  }

  // const handleDateClick = (newDate: Date) => {
  //   setSelectedDate(newDate);
  // }

  const getPostcodeAreas = () => {
    type PostcodeCounts = Record<string, number>;

    const postcodeCounts: PostcodeCounts = {};

    unscheduledLeads?.forEach((lead) => {
      const postcodeArea = lead.vehicle!.postcode!.slice(0, 2); // Get the first two characters of the postcode
      if (postcodeCounts[postcodeArea]) {
        postcodeCounts[postcodeArea] += 1; // Increment the count if we've already seen this postcode area
      } else {
        postcodeCounts[postcodeArea] = 1; // Otherwise, start the count at 1
      }
    });

    const postcodeStrings = Object.entries(postcodeCounts)
      .sort(([postcodeA], [postcodeB]) => postcodeA.localeCompare(postcodeB))
      .map(([postcodeArea, count]) => `<strong>${postcodeArea}: ${count}</strong>`);

    const outputString = `${postcodeStrings.join(' | ')}`;

    return outputString;
  }

  const getTodaysEvents = (todaysDate: Date) => {
    //filter the events to only include the current day
    return allLeads.data?.data.leads?.filter(lead => {
      var start = date.format(new Date(lead?.vehicle?.collectionStart!), "YYYY-MM-DD");
      var end = date.format(new Date(lead?.vehicle?.collectionEnd!), "YYYY-MM-DD");
      var now = date.format(new Date(todaysDate), "YYYY-MM-DD");
      return start === now || end === now;
    });
  }

  useEffect(() => {
    if (calendarRef.current?.getApi().getDate().toDateString() !== selectedDate.toDateString()) {
      calendarRef.current?.getApi().select(date.format(new Date(selectedDate), "YYYY-MM-DD"));
    }
  }, [selectedDate])

  useEffect(() => {
    //If this runs more than once then dragging on will create multiple events
    if (waiting && !isDraggable) {
      var containerEl = document.getElementById('external-events');
      if (containerEl) {
        new Draggable(containerEl!, {
          itemSelector: '.fc-event',
          eventData: (eventEl) => {
            return {
              title: eventEl.innerText,
              id: eventEl.id,
            };
          },
        });
        setIsDraggable(true);
      }
    }
  }, [unscheduledLeads])

  useEffect(() => {
    if (!allLeads.isLoading) {
      let scheduledLeads = allLeads.data?.data.leads.filter((lead) =>
        lead.vehicle?.scheduledCollectionOn !== null && lead.vehicle?.collectedOn === null
      )

      let unscheduledLeads = allLeads.data?.data.leads.filter((lead) =>
        lead.vehicle?.scheduledCollectionOn === null && lead.vehicle?.collectedOn === null && lead.status === LeadStatus["Awaiting Contact"]
      )

      unscheduledLeads = unscheduledLeads?.filter((lead) =>
        lead.contact?.fullname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.vehicle?.vrm?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.vehicle?.postcode?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      unscheduledLeads = sortBy(unscheduledLeads);

      // console.log(getTodaysEvents())

      let colouredEvents = colourEvents(scheduledLeads);

      setEvents(colouredEvents);
      setUnscheduledLeads(unscheduledLeads);
      setLeads(scheduledLeads);
      setIsLoading(false);
    }
  }, [allLeads.isLoading, allLeads.data, selectedDistanceLead, currentFilter, searchTerm, truckColour]);

  const sortBy = (leads: ILead[] | undefined) => {
    if (currentFilter === "Postcode A-Z") {
      return leads?.sort((a, b) => {
        return (a.vehicle?.postcode! > b.vehicle?.postcode! ? 1 : -1);
      });
    } else if (currentFilter === "VRM A-Z") {
      return leads?.sort((a, b) => {
        return (a.vehicle?.vrm! > b.vehicle?.vrm! ? 1 : -1);
      });
    } else if (currentFilter === "Oldest to Newest") {
      return leads?.sort((a, b) => {
        return (new Date(a.created!).getTime() >= new Date(b.created!).getTime() ? 1 : -1);
      });
    } else if (currentFilter === "Newest to Oldest") {
      return leads?.sort((a, b) => {
        return (new Date(a.created!).getTime() <= new Date(b.created!).getTime() ? 1 : -1);
      });
    } else {
      return leads;
    }
  }

  const colourEvents = (leads: ILead[] | undefined) => {
    return leads?.map<IEvent>((lead) => {

      let background = "";
      let border = "";

      if (selectedDistanceLead) {
        background = selectedDistanceLead ? "grey" : "";
        border = selectedDistanceLead ? "grey" : "";

        if (greens?.includes(lead.id)) {
          background = "green";
          border = "green";
        } else if (ambers?.includes(lead.id)) {
          background = "orange";
          border = "orange";
        } else if (reds?.includes(lead.id)) {
          background = "red";
          border = "red";
        }
      } else {
        // console.log(lead)
        if (lead.tripId && truckColour) {
          // Will change colour of event to match the truck
          background = stringToColour(lead.truckId);
          border = stringToColour(lead.truckId);
        }
      }


      let title = lead.vehicle?.hasAlert ? "⚠️" : "";
      title = title + (lead.vehicle?.isVan ? "🚚" : "");
      title = title + (lead.vehicle?.vrm ? `${lead.vehicle.vrm}, ${lead.vehicle.postcode ?? ""} - ${ScheduledCollectionSlot[lead.vehicle.scheduledCollectionSlot]} ` : "No VRM");

      return {
        title: title,
        backgroundColor: lead.vehicle?.vrm === vrm ? "green" : background,
        borderColor: lead.vehicle?.vrm === vrm ? "green" : border,
        date: lead.vehicle?.scheduledCollectionOn ? date.format(new Date(lead.vehicle?.scheduledCollectionOn), "YYYY-MM-DD") : "No Date",
        id: lead.id,
        className: "cursor-pointer",
        extendedProps: {
          truckId: lead.truckId,
        },
      }
    });

  }

  return allLeads.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <Modal open={open} setOpen={setOpen} width="max-w-6xl">
        <Lead modalLeadId={selectedLead} />
      </Modal>

      <div className="p-2">
        {waiting &&
          <div className="">

            <div className="w-full px-4 flex items-center justify-between">
              <div className="">
                <select aria-label="Contact type filter"
                  onChange={(e) => setCurrentFilter(e.target.value)}
                  className="text-sm">
                  {filters.map((filter) => (
                    <option
                      className="text-sm"
                      key={filter}>
                      {String(filter)}
                    </option>
                  ))}
                </select>
              </div>

              <p className="text-center font-semibold">Unscheduled Leads</p>

              <div className="focus-within:z-10">
                <div className="absolute pt-2 pl-3 flex items-center pointer-events-none">
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-10 sm:text-sm"
                  placeholder="VRM, Postcode or Name"
                />
              </div>
            </div>

            <div className="px-4 my-2 flex gap-x-2">
              {!trucks.isLoading && trucks.data && (
                trucks.data.data.map((truck) => (
                  <div className="flex items-center gap-x-1">
                    {truck.name}
                    <div className="w-5 h-5" style={{ backgroundColor: stringToColour(truck.id) }}>
                    </div>
                  </div>
                ))
              )}
            </div>

            <div className="flex justify-center items-center">
              <p className="mr-6"><span dangerouslySetInnerHTML={{ __html: getPostcodeAreas() }}></span></p>
            </div>

            <div
              id="external-events"
              className="mb-2 max-h-[15vh] overflow-y-auto"
            >
              <div className="grid lg:grid-cols-5 grid-cols-3">
                {unscheduledLeads?.map(lead => (
                  <div
                    className={classNames(lead.id == selectedDistanceLead ? "bg-blue-200" : "bg-red-200", "fc-event col-span-1 flex justify-between items-center mx-1 text-sm p-1 rounded-md mb-2 cursor-default")}
                    title={lead.vehicle?.vrm}
                    id={lead.id}
                    key={lead.id}
                    onClick={() => {
                      handleDistance(lead.id);
                    }}
                  >
                    <span>
                      {lead.vehicle?.hasAlert && "⚠️"}
                      {lead.vehicle?.isVan && "🚚"}
                      {lead.vehicle?.vrm ? `${lead.vehicle.vrm}, ${lead.vehicle.postcode ?? ""} - ${ScheduledCollectionSlot[lead.vehicle.scheduledCollectionSlot]} ` : "No VRM"}
                    </span>
                    <button
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedLead(lead.id);
                        setOpen(true);
                      }}
                    >
                      <ArrowTopRightOnSquareIcon className="h-5 w-5" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
        <div className="">
          <FullCalendar
            ref={calendarRef}
            viewClassNames={"calendar"}
            plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,dayGridWeek,listWeek",
            }}
            events={events}
            eventOrder={(a: any, b: any) => {
              return a.extendedProps?.truckId! < b.extendedProps?.truckId! ? -1 : 1;
            }}
            eventClick={(info) => {
              setSelectedLead(info.event.id);
              setOpen(true);
            }}
            dateClick={(info) => {
              setSelectedDate(info.date);
            }}
            select={(info) => {
              if (new Date(info.start).valueOf() + 86400000 !== new Date(info.end).valueOf()) {
                info.view.calendar.unselect();
              }
            }}
            dayPopoverFormat={{ weekday: "long", month: "long", day: "numeric" }}
            eventStartEditable={editable}
            eventChange={(info) => {
              handleEventChange(info.event.id, info.event.start!)
            }}
            selectable={true}
            dayMaxEventRows={15}
            droppable={true}
            // eventReceive={(info) => handleEventChange(info.event.id, info.event.start!, true)}
            eventReceive={(info) => handleEventDrop(info)}
            eventsSet={(allEvents) => {
              //Duplicate checking
              allEvents.forEach((event) => {
                var duplicates = allEvents.filter((e) => e.id === event.id);
                if (duplicates.length > 1) {
                  for (let i = 1; i < duplicates.length; i++) {
                    duplicates[i].remove();
                  }
                }
              });
            }}
            fixedWeekCount={false}
          />
        </div>
      </div>

    </>
  )

}