import { IPagedData } from "../components/shared/Tables/PagedTable";
import { FormatType, IEbayOffer, IPartAspect, ListingDuration } from "./EbayOffer";
import { IWarehouseLocation } from "./Location";
import { INote } from "./Note";
import { IVehicle } from "./Vehicle";
import { BaseEntity, DefaultResponseWithData } from "./system";

export interface Category {
  category: IPartCategory,
  parts: IPart[],
  isMech: boolean
}

export interface IPartResponse extends DefaultResponseWithData<IPartCategoryResponse> { }
export interface ICategoryPartResponse extends DefaultResponseWithData<IPart[]> { }
export interface ISingleCategoryPartResponse extends DefaultResponseWithData<IPart> { }

export interface ICategoryResponse extends DefaultResponseWithData<IPartCategory[]> { }
export interface ISingleCategoryResponse extends DefaultResponseWithData<IPartCategory> { }

export interface IPartCollectionResponse extends DefaultResponseWithData<IPartDataCollection> { }

export interface IStockStatsResponse extends DefaultResponseWithData<IStockStats> { }
export interface ITopUnlistedCategoriesResponse extends DefaultResponseWithData<ITopUnlistedCategories[]> { }

export interface ISinglePartAssemblyResponse extends DefaultResponseWithData<IPartAssembly> { }

export interface IPartDataCollection extends IPagedData<IPart> {
}

export interface IPartRefundSentRequest {
  refundDetail: RefundDetail
}

export interface RefundDetail {
  itemizedRefundDetail: ItemizedRefundDetail[]
  totalAmount: TotalAmount
}

export interface ItemizedRefundDetail {
  refundAmount: RefundAmount
  refundFeeType: string
  restockingFeePercentage: string
}

export interface RefundAmount {
  currency: string
  value: number
}

export interface TotalAmount {
  currency: string
  value: number
}


export interface IStockStats {
  inStock: number
  toBeDissasembled: number
  partsWithoutImages: number
  qcIssues: number
  partsWithoutMPN: number
  averagePartPrice: number
  averagePartYear: number
  averageStockAge: number
  averageDaysToSell: number
  partsConversionRate: number
}


export interface IPartCategoryResponse {
  body: IPart[];
  mech: IPart[];
}

export enum PartImageRequirementType {
  Label,
  Top,
  Bottom,
  Left,
  Right,
  Front,
  Back,
  Misc,
  "Security Seal",
  MPN,
}

export interface IPartRequiredImage {
  label: string
  exampleUrl: string
  type: PartImageRequirementType
  deleted: any
  id: string
  created: string
  updated: any
  required: boolean
  canHaveMultiple: boolean
}

export interface IPartImageRequirementGroup {
  name: any
  partCategories: IPartCategory[]
  partRequiredImages: IPartRequiredImage[]
  id: string
  created: string
  updated: string
}

export interface IPartAssemblyResponse extends DefaultResponseWithData<IPartAssembly[]> { }

export interface IPartAssembly extends BaseEntity {
  name: string;
  partAssemblyPartCategories: IPartAssemblyPartCategories[] | undefined;
}

export interface IPartAssemblyPartCategories extends BaseEntity {
  partAssembly: IPartAssembly | undefined;
  partAssemblyId: string | undefined;
  partCategory: IPartCategory | undefined;
  partCategoryId: string | undefined;
}


export interface IPartAssemblyOnly extends BaseEntity {
  name: string;
}

export interface IPartCategory extends BaseEntity {
  name: string;
  partOnly: boolean;
  mechOnly: boolean;
  postage: number;
  whCost: number;
  whTime: number;
  disCost: number;
  disTime: number;
  pinnacleName: string;
  frontierName: string;
  ucid: string;
  skuPrefix: string;
  side: number;
  position: number;
  averagePrice: number;
  averageMargin: number;
  partMappings: IPartMapping[];
  partCategoryDefaults: IPartCategoryDefault[];
  partAssemblyPartCategories: IPartAssemblyPartCategories[] | undefined;
  partImageRequirementGroupId: string;
  partImageRequirementGroup: IPartImageRequirementGroup | undefined;
}

export interface ITopUnlistedCategories {
  category: IPartCategory;
  count: number;
}

export interface IPartImage extends BaseEntity {
  url: string;
  thumbnailUrl: string;
  isThumbnail: boolean;
  useOnEbay: boolean;
  order: number | null;
  partDataId: string | null;
  imageType:  | null;

  deleted: string | null;
}

export enum IPartImageType {
  Label,
  Top,
  Bottom,
  Left,
  Right,
  Front,
  Back,
  Misc,
  SecuritySeal,
  MPN
}

export interface IPartQcRequest{
  statusMessage: string;
  partStatus: PartStatus;
  partId: string;
}

export interface IPartCancelRequest {
  cancelReason: string;
  partId: string;
}

export interface IPartImagePatchRequest {
  imageId: string;
  isThumbnail?: boolean | null;
  useOnEbay?: boolean | null;
  order?: number | null;
  imageType?: IPartImageType | null;
}

export interface IPartMapping extends BaseEntity {
  // type: MappingType;
  regex: string;
  priority: number;
}

export enum MappingType {
  Generic
}

export interface IPartCategoryDefaultResponse extends DefaultResponseWithData<IPartCategoryDefault> { }

export interface IPartCategoryDefault extends BaseEntity {
  partCategoryId: string | null;
  format: FormatType;
  duration: ListingDuration;
  buyItNowPrice: number | null;
  auctionStartPrice: number | null;
  auctionReservePrice: number | null;
  enableBestOffer: boolean | null;
  autoAcceptPrice: number | null;
  fulfillmentPolicyId: string;
  paymentPolicyId: string;
  returnPolicyId: string;
  locationKey: string;
  width: number | null;
  height: number | null;
  length: number | null;
  weight: number | null;
  ebayCategoryId: string | null;
  ebayStoreCategoryId: string | null;
  titleStructure: string | null;

  aspectAssignees: IAspectAssignee[];
}

export enum Assignee {
  None,
  Imager,
  Dismantler
}

export interface IAspectAssignee extends BaseEntity {
  partCategoryDefaultId: string | null;
  aspectName: string | null;
  assignee: Assignee
  imageUrl: string | null;
  isImageRequired: boolean;
}

export interface IPart extends BaseEntity {
  taskId: number;
  dataId: string | null;
  promotionId: string | null;
  stockNumber: string | null;
  tagNumber: string | null;
  title: string | null;
  titleSanitised: string | null;
  titleLink: string | null;
  ebayThumbnail: string | null;
  cdnThumbnail: string | null;
  price: number | null;
  suggestedPrice: number | null;
  enteredPrice: number | null;
  margin: number | null;
  ebayItemNumber: string | null;
  vehicleNumber: string | null;
  isVin: boolean;
  isInternal: boolean;
  isDamaged: boolean;
  side: PartSide;
  position: PartPosition;
  grade: string | null;
  partNumber: string | null;
  oePartNumber: string | null;
  partsLookupRequestId: string | null;
  exactLincNumber: string | null;
  listedDate: string | null;
  soldDate: string | null;
  processedOn: string | null;

  removedOn: string | null;
  removedBy: string | null;
  passedQcOn: string | null;
  passedQcBy: string | null;

  lastStockChecked: string | null;

  orderId: string | null;
  pickedOn: string | null;
  pickedBy: string | null;
  shippedOn: string | null;
  shippedBy: string | null;
  shippingLabelDocumentId: string | null;
  trackingNumber: string | null;
  length: number | null;
  width: number | null;
  height: number | null;
  weight: number | null;

  fullName: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  county: string | null;
  stateOrProvince: string | null;
  postcode: string | null;


  partStatus: PartStatus;
  statusMessage: string | null;

  images: IPartImage[];
  offers: IEbayOffer[];
  aspects: IPartAspect[];

  categoryId: string | null;
  category: IPartCategory | null;
  vehicleId: string | null;
  vehicle: IVehicle | null;
  warehouseLocationId: string | null;
  warehouseLocation: IWarehouseLocation | null;

  notes: INote[];
  internalNotes: INote[];
}

export enum PartSide {
  None,
  Left,
  Right
}

export enum PartPosition {
  None,
  Front,
  Rear
}

export enum PartStatus {
  "On The Car",
  "Pending Dismantle",
  Dismantled,
  Damaged,
  QC,
  Missing,
  Sold,
  Deleted
}


export enum AutoDapCategory {
  "Spoilers/Wipers" = 10,
  "Body" = 11,
  "Electric/Transmitter/Databox/Sensor" = 12,
  "Air Conditioning/Heating" = 13,
  "Brakes" = 14,
  "Steering Wheel/Axle/Lever/Pedal" = 15,
  "Hydraulic/Traction/Suspension System" = 16,
  "Vehicle Front Axle, Springs" = 17,
  "Vehicle Rear Axle, Springs" = 18,
  "Engine & Fuel System" = 19,
  "Gear Box/Drive Axle/Middle Axle" = 20,
  "Exhaust/Cleaning" = 21,
  "Instruments/Electric Switches" = 22,
  "Sensors/Lock/Alarm" = 23,
  "Wheels/Tires Accessories" = 24,
  "Front Details" = 25,
  "Doors" = 26,
  "Windows/Glasses" = 27,
  "Rear Details" = 28,
  "Seats & Interior" = 29,
  "All" = 0
}
