import React from "react";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { NotifierContextProvider } from "react-headless-notifier";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import ReactDOM from "react-dom/client";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import DashboardLayout from "./layouts/DashboardLayout";
import { PageNameProvider } from "./contexts/PageTitleContext";

import "./index.css";

import ErrorBoundry from "./pages/Error/ErrorBoundry";
import Dashboard from "./pages/Dashboard/Dashboard";
import VehicleList from "./pages/Vehicles/VehicleList";
import Vehicle from "./pages/Vehicles/Vehicle";
import Jobs from "./pages/Jobs/Jobs";
import ContactList from "./pages/Contacts/ContactList";
import Contact from "./pages/Contacts/Contact";
import Settings from "./pages/Settings/Settings";
import GeneralSettings from "./pages/Settings/General";
import MailSettings from "./pages/Settings/Mail";
import Leads from "./pages/Leads/LeadList";
import Lead from "./pages/Leads/Lead";

import Calendar from "./pages/Calendar/Calendar";
import CreateLead from "./pages/Leads/CreateLead";
import CustomerWebForm from "./pages/Public Pages/CustomerWebForm";
import Payments from "./pages/Payments/Payments";
import AwaitingInventory from "./pages/AwaitingInventory/AwaitingInventory";
import RequireAuth from "./pages/Login/RequireAuth";
import FindLead from "./pages/Driver/FindLead";
import DriverLead from "./pages/Driver/DriverLead";
import TripManager from "./pages/Calendar/TripManager";
import Schedule from "./pages/Schedule/Schedule";
import Job from "./pages/Jobs/ViewJob";
import PartsChecker from "./pages/PartsChecker/PartsChecker";
import PartsVehicleLookup from "./pages/AwaitingInventory/PartsVehicleLookup";
import PartsEditor from "./pages/PartsEditor/PartsEditor";
import AdminMenu from "./pages/Admin/AdminMenu";
import DriverTracking from "./pages/DriverTracking/DriverTracking";
import VehiclesAwaitingArrival from "./pages/VehiclesAwaitingArrival/VehiclesAwaitingArrival";
import EbayListings from "./pages/EbayListings/EbayListings";
import VehiclesCollected from "./pages/VehiclesCollected/VehiclesCollected";
import ViewListing from "./pages/EbayListings/ViewListing";
import EbayOrders from "./pages/EbayOrders/EbayOrders";
import Stock from "./pages/Stock/Stock";
import ViewStock from "./pages/Stock/ViewStock";
import EbayMessages from "./pages/EbayMessages/EbayMessages";
import EbayReturns from "./pages/EbayReturns/EbayReturns";
import EbayUnlisted from "./pages/EbayListings/EbayUnlisted";
import PartCategoryRequiredImagesManager from "./pages/PartCategoryRequiredImages/PartCategoryRequireImagesManager";
import BulkList from "./pages/EbayListings/BulkList";
import CategoryEditor from "./pages/CategoryEditor/CategoryEditor";
import EditCategory from "./pages/CategoryEditor/EditCategory";
import { AuthProvider } from "./contexts/AuthContext";
import Search from "./pages/Search/Search";
import { Test } from "./pages/Test/Test";
import IFrame from "./pages/Widget/IFrame";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const widgetPath = (url : string) => {
  return `https://app.recyclingsoftware.co.uk/${url}?embed=true`;
  // return `http://localhost:3000/${url}?embed=true`;
}

const router = createBrowserRouter(
  [
    {
      path: "/customer-info",
      element: <CustomerWebForm />,
      errorElement: <ErrorBoundry />,
    },
    {
      path: "/",
      element: <DashboardLayout />,
      errorElement: <ErrorBoundry />,
      children: [
        {
          element: (
            <RequireAuth
              allowedRoles={{ isAdmin: true, isStaff: false, isDriver: false }}
            />
          ),
          children: [{ index: true, element: <Dashboard /> }],
        },
        {
          //Admin only routes
          element: (
            <RequireAuth
              allowedRoles={{ isAdmin: true, isStaff: false, isDriver: false }}
            />
          ),
          children: [
            {
              path: "admin",
              element: <AdminMenu />,
              children: [
                {
                  index: true,
                  element: <AdminMenu />,
                },
                {
                  path: ":section",
                  element: <AdminMenu />,
                },
              ],
            },
            {
              path: "parts-editor",
              children: [
                {
                  index: true,
                  element: <PartsEditor />,
                },
                {
                  path: ":categoryId",
                  element: <PartsEditor />,
                },
              ],
            },
            {
              path: "ebay-messages",
              children: [
                {
                  index: true,
                  element: <EbayMessages />,
                },
              ],
            },
            {
              path: "category-editor",
              children: [
                {
                  index: true,
                  element: <CategoryEditor />,
                },
                {
                  path: ":categoryId",
                  element: <EditCategory />,
                },
              ],
            },
            {
              path: "category-images",
              children: [
                {
                  index: true,
                  element: <PartCategoryRequiredImagesManager />,
                },
              ],
            },
            {
              path: "test",
              children: [
                {
                  index: true,
                  element: <Test />,
                },
              ]
            }
          ],
        },
        {
          //Staff routes
          element: (
            <RequireAuth
              allowedRoles={{ isAdmin: false, isStaff: true, isDriver: false }}
            />
          ),
          children: [
            {
              path: "leads",
              children: [
                {
                  index: true,
                  element: <Leads />,
                },
                {
                  path: ":leadId",
                  element: <Lead />,
                  children: [
                    {
                      path: ":tab",
                      element: <Lead />,
                    },
                  ],
                },
                {
                  path: "create",
                  element: <CreateLead />,
                },
              ],
            },
            {
              path: "vehicles-collected",
              element: <VehiclesCollected />,
            },
            {
              path: "calendar",
              element: <Calendar />,
            },
            {
              path: "trip-manager",
              element: <TripManager />,
            },
            {
              path: "payments",
              element: <Payments />,
            },
            {
              path: "schedule",
              element: <Schedule />,
            },
            {
              path: "driver-tracking",
              element: <DriverTracking />,
            },
            {
              path: "vehicles",
              children: [
                {
                  index: true,
                  element: <VehicleList />,
                },
                {
                  path: ":vehicleId",
                  element: <Vehicle />,
                },
              ],
            },
            {
              path: "parts-manager",
              children: [
                {
                  index: true,
                  element: <AwaitingInventory />,
                },
                {
                  path: "vehicle-lookup",
                  element: <PartsVehicleLookup />,
                },
              ],
            },
            {
              path: "awaiting-arrival",
              element: <VehiclesAwaitingArrival />,
            },
            {
              path: "parts-checker",
              element: <PartsChecker />,
            },
            {
              path: "jobs",
              children: [
                {
                  index: true,
                  element: <Jobs />,
                },
                {
                  path: ":jobId",
                  element: <Job />,
                },
              ],
            },
            {
              path: "stock",
              children: [
                {
                  index: true,
                  element: <Stock />,
                },
                {
                  path: ":partId",
                  element: <ViewStock />,
                },
              ],
            },
            {
              path: "ebay-listings",
              children: [
                {
                  index: true,
                  element: <EbayListings />,
                },
                {
                  path: ":partId",
                  element: <ViewListing />,
                },
                {
                  path: "bulk-list/:batchId",
                  element: <BulkList />,
                },
              ],
            },
            {
              path: "ebay-unlisted",
              children: [
                {
                  index: true,
                  element: <EbayUnlisted />,
                },
                {
                  path: ":partId",
                  element: <ViewListing />,
                },
              ],
            },
            {
              path: "ebay-orders",
              children: [
                {
                  index: true,
                  element: <EbayOrders />,
                },
                // {
                //   path: ":orderId",
                //   element: <ViewOrder />,
                // }
              ],
            },
            {
              path: "ebay-returns",
              children: [
                {
                  index: true,
                  element: <EbayReturns />,
                },
                // {
                //   path: ":orderId",
                //   element: <ViewOrder />,
                // }
              ],
            },
            {
              path: "contacts",
              children: [
                {
                  index: true,
                  element: <ContactList />,
                },
                {
                  path: ":contactId",
                  element: <Contact />,
                },
              ],
            },
            {
              path: "settings",
              element: <Settings />,
              children: [
                {
                  index: true,
                  element: <GeneralSettings />,
                },
                {
                  path: "mail-importer",
                  element: <MailSettings />,
                },
              ],
            },
            {
              path: "search",
              element: <Search />,
            },
            // Widget routes
            {
              path: "widget-dashboard",
              element:  <IFrame src={widgetPath("")} />,
            },
            {
              path: "widget-postcodes",
              element:  <IFrame src={widgetPath("scrap-price")} />,
            },
            {
              path: "widget-config",
              element:  <IFrame src={widgetPath("price-settings")} />,
            }
          ],
        },
        {
          //Driver routes
          element: (
            <RequireAuth
              allowedRoles={{ isAdmin: false, isStaff: false, isDriver: true }}
            />
          ),
          children: [
            {
              path: "find-lead",
              element: <FindLead />,
            },
            {
              path: "/driver/lead/:leadId",
              element: <DriverLead />,
            },
          ],
        },
      ],
    },
  ],
  {
    basename: process.env.PUBLIC_URL,
  }
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
    },
  },
});

root.render(
  <AuthProvider>
    <PageNameProvider>
      <QueryClientProvider client={queryClient}>
        <NotifierContextProvider
          config={{
            max: 5,
            position: "topRight",
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <RouterProvider router={router} />
            <ReactQueryDevtools initialIsOpen={false} />
          </DndProvider>
        </NotifierContextProvider>
      </QueryClientProvider>
    </PageNameProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
