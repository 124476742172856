import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";

import date from "date-and-time";
import { useNavigate } from "react-router-dom";

import { CameraIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { StarIcon } from '@heroicons/react/24/solid';
import { createColumnHelper, flexRender, getCoreRowModel, getExpandedRowModel, getPaginationRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import useVehiclesMutations from "../../data/useVehiclesMutations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import Modal from "../../layouts/Modal";
import { ILead } from "../../models/Lead";
import { PartsStatusValues } from "../../models/Vehicle";
import classNames from "../../utils/classNames";
import Pagination from "../shared/Tables/Pagination";
import AwaitingInventoryEbayPrice from "./AwaitingInventoryEbayPrice";
import AwaitingInventoryPrice from "./AwaitingInventoryPrice";
import AwaitingInventoryStatusDropdown from "./AwaitingInventoryStatusDropdown";
import AwaitingInventoryVfePrice from "./AwaitingInventoryVfePrice";
import YardStatusDropdown from "./YardStatusDropdown";
import PriceFormat from "../../utils/priceFormat";
import EnginesWorldWideOffers from "../vehiclesAwaitingArrival/EnginesWorldWideOffers";
import openInNewTab from "../../utils/openInNewTab";
import VehicleLocationDropdown from "../vehicles/VehicleLocationDropdown";
import VehicleIcons from "../shared/VehicleIcons/VehicleIcons";

import { TableType } from "../../models/TableDisplayColumn";
import useTableDisplayColumns from "../../data/useTableDisplayColumns";

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + 'p-2 cursor-pointer'}
      {...rest}
    />
  )
}

const colHelper = createColumnHelper<ILead>();

const cols = [
  {
    id: 'select',
    header: ({ table }: any) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }: any) => (
      <div className="flex justify-center items-center flex-1 p-1" onClick={(e) => e.stopPropagation()}>
        <IndeterminateCheckbox
          {...{
            id: row.original.id,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
        <button
          onClick={(e) => { e.stopPropagation(); row.toggleExpanded(); }}
          style={{ transform: `rotate(${!row.getIsExpanded() ? "0" : "-270"}deg)` }}
          className="ml-3 p-1 transition duration-500 cursor-pointer border border-gray-400 rounded-full hover:bg-gray-200"
        >
          {/* {row.getIsExpanded() ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4"/>} */}
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    ),
  },
  colHelper.display({
    id: "image",
    cell: (info) => (
      <span className="flex justify-center relative" >
        {info.row.original.vehicle?.vehicleImageUrl ? (
          <>
            <img
              className="w-32 h-20 object-contain"
              src={`${info.row.original.vehicle != null ? info.row.original.vehicle.vehicleImageUrl : null}`}
            />
            {info.row.original.vehicle?.vehiclePremium && (
              <StarIcon className="w-8 h-8 text-amber-500 absolute -top-2 -right-2" />
            )}

          </>
        ) : (
          <CameraIcon className="w-14 text-gray-600" />
        )}
      </span>
    ),
  }),
  colHelper.accessor("vehicle.combinedMake", {
    id: "combinedMake",
    header: "Make & Model",
    enableSorting: false,
    cell: (info) => <div>
      <p>{info.row.original.vehicle?.combinedMake}</p>
      <p>{info.row.original.vehicle?.combinedModel}</p>
    </div>,
  }),
  colHelper.accessor("vehicle.vrm", {
    id: "vrm",
    header: "VRM",
    enableSorting: false,
  }),
  colHelper.accessor("vehicle.engineModelCode", {
    id: "engineModelCode",
    header: "Engine Code",
    enableSorting: false,
  }),
  colHelper.accessor("vehicle.bodyStyleDescription", {
    id: "bodyStyleDescription",
    header: "Mileage & Body Type",
    enableSorting: false,
    cell: (info) => <div>
      <p>{info.row.original.vehicle?.odometerInMiles}</p>
      <p>{info.row.original.vehicle?.bodyStyleDescription}</p>
    </div>,
  }),
  // colHelper.accessor("vehicle.vehicleStarting", {
  //   header: "Starting",
  //   enableSorting: false,
  //   cell: (info) => <span className="flex justify-center text-xl">{info.getValue() ? "✔" : "✘"}</span>,
  // }),
  colHelper.accessor("vehicle", {
    id: "vehicle",
    header: "Vehicle",
    enableSorting: false,
    cell: (info) => <VehicleIcons vehicle={info.row.original.vehicle!} />,
  }),
  colHelper.accessor("vehicle.valuationTradePoor", {
    id: "valuationTradePoor",
    header: "Salvage",
    enableSorting: false,
    cell: (info) => <span>{PriceFormat(info.row.original.vehicle?.valuationTradePoor)}</span>,
  }),
  colHelper.accessor("vehicle.ebayEnginePrice", {
    id: "ebayEnginePrice",
    header: "Ebay Engine £",
    cell: (info) => <AwaitingInventoryEbayPrice lead={info.row.original} />,
  }),
  colHelper.accessor("vehicle.enginesWorldwideEngineOffer", {
    id: "enginesWorldwideEngineOffer",
    header: "Export Offers £",
    cell: (info) => <div>
      <p>Engine Offer</p>
      <AwaitingInventoryPrice vehicle={info.row.original.vehicle!} />
      <p className="mt-2 ">VFE Offer</p>
      <AwaitingInventoryVfePrice vehicle={info.row.original.vehicle!} />
    </div>
  }),
  colHelper.accessor("vehicle.enginesWorldwide", {
    id: "enginesWorldwide",
    header: "Export Status",
    sortingFn: "auto",
    cell: (info) => <EnginesWorldWideOffers offers={info.getValue()} />
  }),
  colHelper.accessor("vehicle.scheduledCollectionOn", {
    id: "scheduledCollectionOn",
    header: "Collection",
    sortingFn: "auto",
    cell: (collection) => <span>{collection.getValue() !== null ? date.format(new Date(collection.getValue()!), "DD/MM/YYYY") : "Not Set"}</span>,
  }),
  colHelper.accessor("vehicle.yardStatus", {
    id: "yardStatus",
    header: "Status",
    enableSorting: false,
    cell: (info) => <div id={info.row.original.id}>
      <p>Parts Status</p>
      <AwaitingInventoryStatusDropdown vehicle={info.row.original.vehicle!} classes="h-8 w-40 text-sm" />
      <p className="mt-2">Location</p>
      <VehicleLocationDropdown vehicle={info.row.original.vehicle!} classes="h-8 w-40 text-sm" />
      <p className="mt-2 ">Yard Status</p>
      <YardStatusDropdown vehicle={info.row.original.vehicle!} classes="h-8 w-40 text-sm" />
    </div>
  }),
];


export default function AwaitingInventoryTable({ leads }: { leads: ILead[] | undefined }) {
  const navigate = useNavigate();
  const { bulkUpdate } = useVehiclesMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Leads");
  const [updateOpen, setUpdateOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("0");

  const [sorting, setSorting] = useState<SortingState>([{ id: "scheduledCollectionOn", desc: true }]);

  const handleRowUpdate = () => {
    var vehicleIds = new Array();

    table.getSelectedRowModel().flatRows.forEach(lead => {
      vehicleIds.push(lead.original.vehicle?.id);
    });

    const body = {
      engineExportStatus: parseInt(selectedStatus as keyof PartsStatusValues),
      vehicles: vehicleIds
    };

    bulkUpdate.mutate(body, {
      onSuccess: () => {
        table.resetRowSelection();
        saveHandlers.onSuccess();
        setUpdateOpen(false);
      },
      onError: saveHandlers.onError
    });
  };

  const {displayColumns} = useTableDisplayColumns(TableType.AwaitingInventory);
  const [filteredColumns, setFilteredColumns] = useState<any[]>(cols);
  
  useEffect(() => {
    if(displayColumns.isLoading) return;
    if(displayColumns.data == undefined) return;
    const dbCols = displayColumns.data.data;
    const selectedColumns = dbCols.map(col => col.columnId);
    setFilteredColumns(cols.filter(col => typeof col.id == "string" && selectedColumns.includes(col.id)));
  }, [displayColumns.isLoading])


  const table = useReactTable({
    data: leads!,
    columns: filteredColumns,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  useEffect(() => {
    table.setPageSize(50);
  }, [])

  return (
    <>
      <Modal width="max-w-2xl" open={updateOpen} setOpen={setUpdateOpen} >
        <h1 className="text-xl">Bulk Update</h1>
        <div className="mt-4">
          <p>Change all selected engines to which status?</p>
          <div>
            <select
              onChange={(e) => setSelectedStatus(e.target.value)}
              className="mt-2 border border-gray-400 rounded-md text-sm"
            >
              {Object.values(PartsStatusValues).filter((ct) => isNaN(Number(ct))).map((status, i) => (
                <option key={status} value={i}>
                  {status}
                </option>
              ))}
            </select>
          </div>
          <button
            className="mt-5 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-700"
            onClick={handleRowUpdate}
          >
            Update
          </button>
        </div>
      </Modal>
      <div className="flex justify-between">
        <div className="space-x-4">
          <button
            className={classNames(table.getSelectedRowModel().flatRows.length === 0 ? "bg-gray-200" : "bg-primary-600 hover:bg-primary-700", "mt-4 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm")}
            type="button"
            disabled={table.getSelectedRowModel().flatRows.length === 0}
            onClick={() => setUpdateOpen(true)}
          >
            Bulk Update
          </button>
          <button
            className={"bg-primary-600 hover:bg-primary-700 mt-4 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"}
            type="button"
            onClick={() => navigate("/parts-manager/vehicle-lookup")}
          >
            Vehicle Lookup
          </button>
        </div>

        <div className="flex justify-center items-center">
          {table.getPreFilteredRowModel().rows.length} Cars Shown
        </div>

      </div>
      <div className="mt-4 bg-white overflow-auto">
        <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-primary-50 text-gray-800">
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 px-2 text-sm font-semibold lg:table-cell text-left"
                  >
                    {header.isPlaceholder
                      ? null
                      : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' ▲',
                            desc: ' ▼',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>

                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              let rowCells = row.getVisibleCells();
              return (
                <Fragment key={i}>
                  <tr
                    key={row.id}
                    onClick={() => openInNewTab(`leads/${row.original.id}/parts`)}
                    className="hover:bg-gray-100 hover:cursor-pointer"
                  >
                    {rowCells.map((cell) => (
                      <td key={cell.id} className="px-2 py-2 border text-sm">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <tr>
                      {/* 2nd row is a custom 1 cell row */}
                      <td colSpan={row.getVisibleCells().length} className="bg-gray-100 pl-28 p-4">
                        <div className="flex gap-x-10">

                          {/* <div className="flex flex-col text-center">
                            <span className="font-bold">Starting?</span>
                            <span>{row.original.vehicle?.vehicleStarting ? "Yes" : "No"}</span>
                          </div> */}

                          <div className="flex flex-col">
                            <span className="font-bold">Vehicle Notes:</span>
                            <span>{row.original.vehicle?.notes}</span>
                          </div>

                          <div className="flex flex-col">
                            <span className="font-bold">Office Notes:</span>
                            <span>{row.original.officeNotes}</span>
                          </div>

                          <div className="flex flex-col">
                            <span className="font-bold">Driver Notes:</span>
                            <span>{row.original.driverNotes}</span>
                          </div>

                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>

        <Pagination table={table} />

      </div>
    </>
  );
}