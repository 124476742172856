import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useCategories from "../../data/useCategories";
import { IPartCategory, IPartImageRequirementGroup } from "../../models/Part";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useDebounce } from "@uidotdev/usehooks";
import PartCategoryRequiredImagesEditor from "./PartCategoryRequiredImagesEditor";
import usePartImageRequirement from "../../data/usePartImageRequirement";
import { useForm } from "react-hook-form";
import SelectInput from "../../components/shared/Inputs/SelectInput";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import LoadingWheel from "../../components/shared/LoadingWheel";

export interface IBulkGroupPickerProps {
  selectedCategoies: IPartCategory[];
}

function BulkGroupPicker(props: IBulkGroupPickerProps) {
  const { register, handleSubmit, reset, setValue, watch } = useForm<IPartImageRequirementGroup>({});
  const { allGroups, bulkUseGroup } = usePartImageRequirement();
  const [groupOptions, setGroupOptions] = useState<{ id: string, name: string }[]>([]);

  useEffect(() => {
    if (!allGroups.isLoading && allGroups.data != null) {
      setGroupOptions(allGroups.data.map(x => ({ id: x.id, name: x.name })));
    }
  }, [allGroups.isLoading, allGroups.data]);

  const onSubmit = (data: IPartImageRequirementGroup) => {
    if (data.id == "") return;
    bulkUseGroup.mutate({ categories: props.selectedCategoies, group: data });
  }

  return <div className="my-4 border-t border-b border-slate-400 py-4">
    <p className="text-lg font-semibold pb-2">Change Requirement Group For Selected</p>
    <form className="flex flex-col gap-y-4" onSubmit={handleSubmit(onSubmit)}>
      <SelectInput label="Image Type" register={register} registerName="id" setValue={setValue} watch={watch} options={groupOptions} />
      <ButtonInput label={"Use Group On Selected"} isSubmit={true} onClick={undefined} classes={"mt-2"} />
    </form>
  </div>
}

export default function PartCategoryRequiredImagesManager() {
  const { setInfo } = usePageNameContext();
  const { categories: allCategories } = useCategories();
  const { allGroups } = usePartImageRequirement();
  const [filterOption, setFilterOption] = useState("0");
  const [selectedCategory, setSelectedCategory] = useState<IPartCategory | undefined>(undefined);
  const [rows, setRows] = useState<IPartCategory[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [categoryName, setCategoyName] = useState<string>("");
  const [openEditor, setOpenEditor] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setInfo({
      name: "Manage Required Images",
      desc: "View and manage required images when photographing parts.",
    });
  }, []);

  const filtersUpdated = useDebounce([filterOption, categoryName], 500);

  const toggleRow = (row: IPartCategory) => {
    if (selectedRows.includes(row?.id)) {
      setSelectedRows(selectedRows.filter(x => x != row.id));
    }
    else {
      setSelectedRows([...selectedRows, row.id]);
    }
  };

  const toggleAllRows = () => {
    if (selectedRows.length == rows.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(rows.map(x => x.id));
    }
  };

  useEffect(() => {
    if (allCategories.isLoading || allGroups.isLoading) return;

    let rows = allCategories.data?.data ?? [];

    if (categoryName != "") {
      rows = rows.filter(x => x.name.toLowerCase().includes(categoryName.toLowerCase()));
    }

    rows.forEach(x => {
      x.partImageRequirementGroup = allGroups.data?.find(y => y.id == x.partImageRequirementGroupId);
    });

    rows = rows.sort((a, b) => a.name.localeCompare(b.name));

    if (filterOption == "1") {
      rows = rows.filter(x => x.partImageRequirementGroup != null);
    } else if (filterOption == "2") {
      rows = rows.filter(x => x.partImageRequirementGroup == null);
    }

    setRows(rows);
    setIsLoading(false);
  }, [filtersUpdated, allCategories.isLoading, allGroups.data, allGroups.isLoading]);

  if (isLoading) {
    return <LoadingWheel />;
  }

  return (
    <div className="mt-4">
      <PartCategoryRequiredImagesEditor open={openEditor} setOpen={setOpenEditor} category={selectedCategory} />
      <div className="flex flex-row my-2 gap-x-4">
        <div className="flex flex-col">
          <label className="text-md font-semibold">Category Name</label>
          <input type="text" className="text" value={categoryName} onChange={x => setCategoyName(x.currentTarget.value)} />
        </div>
        <div className="flex flex-col">
          <label className="text-md font-semibold">Filter By</label>
          <select onChange={x => setFilterOption(x.currentTarget.value)}>
            <option value={0}>All</option>
            <option value={1}>Has Photo Requirements</option>
            <option value={2}>Missing Photo Requirements</option>
          </select>
        </div>
      </div>
      {
        selectedRows.length > 0 ? <BulkGroupPicker selectedCategoies={rows.filter(x => selectedRows.includes(x.id))} /> : null
      }
      <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
        <thead>
          <tr className="bg-primary-50 text-gray-800">
            <th><input type="checkbox" className="p-3" onChange={() => toggleAllRows()} checked={selectedRows.length == rows.length} /></th>
            <th className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left" scope="col">Received At</th>
            <th className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left" scope="col">Photo Requirements Group</th>
            <th className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left" scope="col">Has Photo Requirements</th>
          </tr>
        </thead>
        <tbody>
          {
            rows.map((x, i) => <tr className={"hover:bg-gray-200 cursor-pointer" + (x.partImageRequirementGroup != null ? " bg-blue-100" : " bg-white")}
              onClick={() => { setSelectedCategory(x); setOpenEditor(true); }} key={i}>
              <td className="p-3 border" onClick={e => e.stopPropagation()}><input type="checkbox" className="p-3" onChange={() => { toggleRow(x) }} checked={selectedRows.includes(x.id)} /></td>
              <td className="px-3 py-3 border text-sm">{x.name}</td>
              <td className="px-3 py-3 border text-sm">{x.partImageRequirementGroup ? x.partImageRequirementGroup.name : "-"}</td>
              <td className="px-3 py-3 border text-sm">{x.partImageRequirementGroup ? <CheckIcon height={20} /> : <XMarkIcon height={20} />}</td>
            </tr>)
          }
        </tbody>
      </table>
    </div>
  )
}