export const firebaseAPIKey = process.env.REACT_APP_FIREBASE_API_KEY;
export const resourceAPIURL = process.env.REACT_APP_RESOURCE_API_URL;

export const wilmaSSODashboardUrl = process.env.REACT_APP_WILMA_SSO_DASHBOARD_URL;
export const wilmaSSOAPIUrl = process.env.REACT_APP_WILMA_SSO_API_URL;
export const wilmaSSOAppRegistration = process.env.REACT_APP_WILMA_SSO_APP_REGISTRATION;

// export const wilmaPublicCdn = "https://cdn.recyclingsoftware.co.uk/public/images/";

export const tokenStorageKey = "wilma-app-token";
