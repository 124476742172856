import { useEffect, useState } from "react";
import date from "date-and-time";
import { ILead, LeadStatus } from "../../models/Lead";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import FileUpload from "../shared/Inputs/FileUpload";
import useLeadDocuments from "../../data/useLeadDocuments";
import { DocumentType, IDocument } from "../../models/Document";
import { PhotoIcon } from "@heroicons/react/24/outline";
import Modal from "../../layouts/Modal";
import LoadingWheel from "../shared/LoadingWheel";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import useNotification from "../notifications/useNotifications";
import { useQueryClient } from "@tanstack/react-query";
import useVehicles from "../../data/useVehicles";
import { IVehicle } from "../../models/Vehicle";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import classNames from "../../utils/classNames";
import useVehiclesMutations from "../../data/useVehiclesMutations";


export default function DriverUpload({ lead }: { lead: ILead }) {
  const { update } = useVehiclesMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Lead Details");
  const queryClient = useQueryClient();
  const [collectConfirmOpen, setCollectConfirmOpen] = useState(false);
  const { leadDocuments, uploadDoc } = useLeadDocuments(lead.id);
  const { saveHandlers: fileSaveHandler } = useDefaultCRUDHandlers("Files");
  const { addNotification } = useNotification();
  const [uploadIsLoading, setUploadIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [toUpload, setToUpload] = useState([]);
  const [vehicleImages, setVehicleImages] = useState<IDocument[]>([]);
  const [identityImages, setIdentityImages] = useState<IDocument[]>([]);
  const [docType, setDocType] = useState("0");

  const docTypes = [
    {
      id: "0",
      name: "Vehicle Images"
    },
    {
      id: "1",
      name: "Identity Documents"
    }
  ];

  const handleCollected = () => {
    const updatedVehicle: IVehicle = {
      ...lead.vehicle!,
      collectedOn: date.format(new Date(), "YYYY-MM-DD")
    };

    update.mutate(updatedVehicle, {
      onSuccess: () => {
        saveHandlers.onSuccess();

        setTimeout(() => {
          queryClient.invalidateQueries(["lead", lead.id]);
        }, 1000 * 2);
      },
      onError: (error) => {
        console.log(error);
        saveHandlers.onError();
      },
      onSettled: () => {
        setCollectConfirmOpen(false);
      }
    });
  }

  const updateToUploadFiles = (files: any) => setToUpload({ ...files });

  function output(docType: IDocument[]) {
    return docType.map((doc: IDocument) => {
      const image = doc.thumbnail ? <img src={`data:image/png;base64, ${doc.thumbnail!} `} alt={doc.fileName!} className="rounded-sm object-contain w-full h-full" />
        :
        <PhotoIcon className="w-3/4 rounded-sm object-contain" />

      return (
        <div key={doc.id} className="h-full flex flex-col items-center">
          <div className="flex justify-center cursor-pointer">
            {image}
          </div>
          <div className="text-center w-full">
            <div className="font-semibold">Filename:</div>
            <p className="truncate text-sm">{doc.fileName}</p>
          </div>
        </div>
      )
    })
  }

  const handleUploadDocs = () => {

    if (Object.keys(toUpload).length < 1) {
      addNotification({
        primaryText: "No files to upload",
        variant: "warn"
      })
      return;
    }

    //attach files to http request
    setUploadIsLoading(true);
    const formData = new FormData();

    for (let i = 0; i < Object.keys(toUpload).length; i++) {
      formData.append(docType.toString(), toUpload[i]);
    }

    uploadDoc.mutate(formData, {
      onSuccess: () => {
        setToUpload([]);
        updateToUploadFiles([]);
        fileSaveHandler.onSuccess();
      },
      onError: (error) => {
        console.log(error);
        fileSaveHandler.onError();
      },
      onSettled: () => {
        setUploadIsLoading(false);
      }
    })
  }

  useEffect(() => {
    if (!leadDocuments.isLoading) {
      setVehicleImages(leadDocuments.data?.data?.filter((doc: IDocument) => doc.type === DocumentType["Vehicle"])! || []);
      setIdentityImages(leadDocuments.data?.data?.filter((doc: IDocument) => doc.type === DocumentType["Identity"])! || []);

      setIsLoading(false);
    }
  }, [lead, leadDocuments.isLoading, leadDocuments.data])


  if (uploadIsLoading) {
    return (
      <div className="mt-[15vh] flex items-center justify-center">
        <LoadingWheel />
      </div>
    )
  } else {
    return (
      <>
        <ConfirmModal open={collectConfirmOpen} setOpen={setCollectConfirmOpen}
          title="Mark as collected?" confirmButtonText="Collect"
          message={`Are you sure you want to mark this vehicle as collected? \nMake sure that you are able to collect the vehicle, the customers payment details are correct and you have pictures of the vehicle before taking collection.`}
          isLoading={false}
          onConfirm={handleCollected}
        />
        <div className="pt-2">

          <div className="w-full sm:w-1/4">
            <label>Document Type</label>
            <select onChange={(e) => setDocType(e.target.value)} value={docType} className="w-full text-sm text-gray-900">
              {docTypes.map((dt, i) => (
                <option key={i} value={dt.id}>
                  {dt.name}
                </option>
              ))}
            </select>
          </div>

          <FileUpload driver={true} accept=".jpg,.png,.jpeg,.pdf" label="Upload Vehicle Images" desc="Make sure to pick a document type before uploading!" multiple capture updateFilesCb={updateToUploadFiles} />
          <div className="flex justify-between mt-4">
            {/* <ButtonInput classes="!bg-white border border-gray-200 !text-primary-500 hover:!bg-gray-50" label="Cancel" isSubmit={false} onClick={() => setUploadOpen(false)} /> */}
            <ButtonInput classes="" label="Upload Images" isSubmit={false} onClick={() => handleUploadDocs()} />
          </div>

          <div className="mt-8 p-4 bg-white rounded shadow">
            <h3 className="mt-2 border-t pt-4 text-lg font-semibold">Vehicle Images</h3>
            {isLoading ? <LoadingWheel /> :
              <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
                {output(vehicleImages)}
              </div>
            }

            <h3 className="mt-2 border-t pt-4 text-lg font-semibold">Identity Documents</h3>
            {isLoading ? <LoadingWheel /> :
              <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-6">
                {output(identityImages)}
              </div>
            }
          </div>
        </div>

        <ButtonInput label="Mark as Collected" isSubmit={false} onClick={() => setCollectConfirmOpen(true)}
          classes={classNames(lead.status === LeadStatus.Collected ? "bg-green-600 hover:bg-green-700" : "", `mt-8`)}
        />
      </>
    )
  }

}