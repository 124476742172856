import { Fragment, useEffect, useState } from "react";
import useEbayCategories from "../../../data/useEbayCategories";
import LoadingWheel from "../../shared/LoadingWheel";
import ComboBoxInput from "../../shared/Inputs/ComboBoxInput";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { IEbayItem } from "../../../models/EbayOffer";
import { IPart } from "../../../models/Part";
import useOffers from "../../../data/useOffers";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";

interface EbayCategoryDropdownProps {
  part: IPart;
  categoryId: string | null | undefined;
}

export function EbayTableCategoryDropdown({ part, categoryId }: EbayCategoryDropdownProps) {
  const { categories } = useEbayCategories();
  const { create, update } = useOffers();
  const { saveHandlers } = useDefaultCRUDHandlers("Part Category");
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState<IEbayItem>();
  const [options, setOptions] = useState<IEbayItem[]>([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (!categories.isLoading && categories.data) {
      // if (!watch(registerName)) {
      //   setValue(registerName, categories.data!.categories[0].id);
      // }

      setIsLoading(false);
    }
  }, [categories.data, categories.isLoading])

  useEffect(() => {
    if (categories.isLoading) {
      setIsLoading(true);
    } else {
      const filteredOptions =
        query === ''
          ? categories.data?.categories
          : categories.data?.categories.filter((option) =>
            option.name
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, ''))
            || option.id?.toString().includes(query)
          );

      setOptions(filteredOptions ?? []);

      setIsLoading(false);
    }
  }, [categories.isLoading, categories.data, query, part]);

  const handleCategoryChange = (registerName: string, option: any) => {
    if (option != null && option.id != null) {

    }
    // setValue(registerName, option.id);
  };

  const handleSaveCategory = () => {
    //Need to save the offer title, not the part
    let offer = part.offers[0];

    if (!selected?.id) {
      return;
    }

    let updatedOffer = {
      ...offer,
      categoryId: selected?.id
    };

    if (offer) {
      update.mutate(updatedOffer, saveHandlers);
    } else {
      updatedOffer.partDataId = part.id;
      create.mutate(updatedOffer, saveHandlers);
    }
  };

  //Search the options based on the name or the ID


  const handleSelect = (option: IEbayItem) => {
    setSelected(option);
    // setValue(registerName, valueField != "" ? option[valueField] : option)
  }

  useEffect(() => {
    if (categories.data && categories.data.categories && categoryId) {
      const selectedCategory = categories.data.categories.find(category => category.id === categoryId);
      setSelected(selectedCategory);
    } else if (categories.data?.categories && categories.data.categories.length > 0) {
      setSelected(categories.data.categories[0]);
    }
  }, [categories.isLoading, categories.data])

  if (isLoading || categories.isLoading || !categories.data) {
    return (
      <div className="flex items-center space-x-2">
        Getting categories from eBay...
        <span className="h-5">
          <LoadingWheel />
        </span>
      </div>
    );
  } else {

    return (
      <div className="flex">

        <div className="w-1/2">
          <label>
            <span className="text-sm font-medium text-gray-500">Ebay Category</span>
          </label>
          <Combobox value={selected} onChange={handleSelect}>
            <div className="relative mt-1">
              <div className="relative w-full cursor-default overflow-hidden bg-white text-left sm:text-sm ">
                <Combobox.Input
                  className="w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 border-1 border-gray-400"
                  displayValue={(option: IEbayItem) => option?.name ?? ""}
                  onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </div>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                afterLeave={() => setQuery('')}
              >
                <Combobox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base rounded-b-md ring-1 ring-gray-400 ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.length === 0 && query !== '' ? (
                    <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                      Nothing found.
                    </div>
                  ) : (
                    options.slice(0, 100).map((option) => (
                      <Combobox.Option
                        key={option.id ?? option.name}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primary-600 text-white' : 'text-gray-900'
                          }`
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                }`}
                            >
                              {option.name ?? option.id}
                            </span>
                            {selected ? (
                              <span
                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-primary-600'
                                  }`}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Combobox.Option>
                    ))
                  )}
                </Combobox.Options>
              </Transition>
            </div>
          </Combobox>
        </div>

        <div className="ml-4 mt-7">
          <ButtonInput label="Save" isSubmit={false} onClick={() => handleSaveCategory()} classes={"py-3"} />
        </div>
      </div>
    );
  }
}
