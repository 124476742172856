import { Link, useNavigate } from "react-router-dom";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
  createColumnHelper,
  ColumnDef,
} from "@tanstack/react-table";
import { TruckIcon } from "@heroicons/react/24/outline";

import { ContactType, IContact } from "../../models/Contact";

const colHelper = createColumnHelper<IContact>();

const cols = [
  colHelper.accessor("fullname", {
    header: "Name",
  }),

  colHelper.accessor("telephone1", {
    header: "Telephone",
  }),
  colHelper.accessor("email", {
    header: "Email",
  }),
  colHelper.accessor("contactType", {
    header: "Type",
    cell: (info) => ContactType[info.getValue()],
  }),
  colHelper.display({
    id: "Vehicles",
    header: "Vehicles",
    cell: (info) =>
      info.row.original.contactType === ContactType.Customer && (
        <div className="text-center">
          <Link
            to={"/vehicles"}
            onClick={(e) => e.stopPropagation()}
            state={{ filter: { contactId: info.row.original.id } }}
            className="inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-1.5 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-700"
          >
            <TruckIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            Vehicles
          </Link>
        </div>
      ),
  }),
];

export default function ContactTable({ data }: { data: IContact[] }) {
  const navigate = useNavigate();
  const table = useReactTable({
    data: data,
    columns: cols,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="mt-4 bg-white">
      <table className="min-w-full divide-y border divide-gray-300">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="bg-primary-50 text-gray-800">
              {headerGroup.headers.map((header, i) => (
                <th
                  key={header.id}
                  scope="col"
                  className={`py-3.5 px-3 text-sm font-semibold lg:table-cell ${i + 1 === headerGroup.headers.length
                    ? "text-center"
                    : "text-left"
                    }`}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            let rowCells = row.getVisibleCells();
            return (
              <tr
                key={row.id}
                onClick={() => navigate(`${row.original.id}`)}
                className="hover:bg-gray-100 hover:cursor-pointer"
              >
                {rowCells.map((cell) => (
                  <td key={cell.id} className="px-3 py-3 border text-sm">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}