import { useEffect, useState } from "react";

import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { useQueryClient } from "@tanstack/react-query";

import BulkListRow from "../../components/ebayListings/bulkList/BulkListRow";
import useNotification from "../../components/notifications/useNotifications";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import { DateTimeInput } from "../../components/shared/Inputs/DateTimeInput";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useBatchListings from "../../data/useBatchListings";
import useEbay from "../../data/useEbay";
import usePartAspectsMutations from "../../data/usePartAspectsMutations";
import usePartDefaultCategoriesMutations from "../../data/usePartDefaultCategoriesMutations";
import usePartMutations from "../../data/usePartMutations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { usePartDescriptionGenerator } from "../../hooks/usePartDescriptionGenerator";
import Modal from "../../layouts/Modal";
import { IEbayOffer } from "../../models/EbayOffer";
import { IPart } from "../../models/Part";
import checkEbayDisabled from "../../utils/checkEbayDisabled";
import sortOffers from "../../utils/sortOffers";
import { DefaultResponseWithData } from './../../models/system';


export default function BulkList() {
  const { setInfo } = usePageNameContext();
  const { batchId } = useParams();
  const { parts: multipleParts, createOffer, update, bulkPublish } = useBatchListings(batchId);
  const { createCustomAspects } = usePartAspectsMutations();
  const { autoReorderImages } = usePartMutations();
  const { useDefaultPartCategory, useBulkDefaultPartCategory } = usePartDefaultCategoriesMutations();
  const { policies } = useEbay();
  // const { addDefaultCustomAspects } = useDefaultCustomAspects({ part });
  const { saveHandlers } = useDefaultCRUDHandlers("Part");
  const { saveHandlers: defaultsSaveHandlers } = useDefaultCRUDHandlers("Defaults Applied");
  const { addNotification } = useNotification();
  const queryClient = useQueryClient();
  const generateDescription = usePartDescriptionGenerator();
  const { handleSubmit, register, getValues, setValue, watch } = useForm({
    defaultValues: {
      listingStarted: DateTime.now().plus({ minutes: 30 }).toFormat("yyyy-MM-dd'T'HH:mm"),
    }
  });
  const [parts, setParts] = useState<IPart[] | undefined>(undefined);
  const [publishedParts, setPublishedParts] = useState<IPart[] | undefined>(undefined);
  const [soldParts, setSoldParts] = useState<IPart[] | undefined>(undefined);
  const [readyOfferIds, setReadyOfferIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingText, setLoadingText] = useState<boolean>(true);
  const [loadingIndex, setLoadingIndex] = useState<number>(0);
  const [readyCount, setReadyCount] = useState<number>(0);
  const [results, setResults] = useState<DefaultResponseWithData<IEbayOffer>[] | undefined>(undefined);
  const [resultsOpen, setResultsOpen] = useState<boolean>(false);

  const loadingArray = [
    "Creating Offers...",
    "Applying Defaults...",
    "Building Titles...",
    "Generating Descriptions...",
    "Adding Item Aspects...",
    "Finding Suggested Prices...",
    "Looking for Part Numbers...",
    "Getting your Listings Ready...",
    "Error Checking...",
    "This can take a few minutes...",
    "Won't be long now...",
    "Still quicker than doing it yourself!",
    "Loading", "Loading.", "Loading..", "Loading...", "Loading", "Loading.", "Loading..", "Loading...", "Loading", "Loading.", "Loading..", "Loading..."
  ];

  const handleBulkList = () => {

    const listingStarted = getValues("listingStarted");

    //Check the listed started date is in the future
    if (DateTime.fromISO(listingStarted) < DateTime.now()) {
      addNotification({
        primaryText: "Listing Date Error",
        secondaryText: "The listing date must be in the future",
        variant: "error"
      });
      setIsLoading(false);
      return;
    };

    setIsLoading(true);

    const body = {
      listingStarted: listingStarted,
      offerIds: readyOfferIds?.map((id) => id)
    };
    // console.log(readyOfferIds)
    console.log(body);

    bulkPublish.mutate(body, {
      onSuccess: (data) => {
        console.log(data);
        setResults(data as DefaultResponseWithData<IEbayOffer>[]);
        setResultsOpen(true);
      },
      onError: (data) => {
        console.log("error");
        addNotification({
          primaryText: "Bulk List Failed",
          variant: "error",
        });
      },
      onSettled: () => {
        setIsLoading(false);
      }
    });

  };

  const applyDefaultsToAll = () => {
    setIsLoading(true);

    let partIds = parts?.map((part) => part.id);

    const body = {
      partIds: partIds
    };

    useBulkDefaultPartCategory.mutate(body as any, {
      ...defaultsSaveHandlers,
      onSettled: () => setIsLoading(false)
    });

  };

  const handleAutoReorderImages = () => {
    const ids = parts?.map((part) => part.id);

    autoReorderImages.mutate(ids, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        queryClient.invalidateQueries(["parts", "multiple"]);
      },
      onError: () => {
        saveHandlers.onError();
      }
    });

  }

  useEffect(() => {
    let timer: string | number | NodeJS.Timer | undefined;

    if (isLoading) {
      timer = setInterval(() => {
        setLoadingIndex((prevIndex) => (prevIndex + 1) % loadingArray.length);
      }, 3000); // Change every 3 seconds
    } else {
      clearInterval(timer);
      setLoadingIndex(0);
    }

    return () => {
      clearInterval(timer);
    };
  }, [isLoading]);

  useEffect(() => {
    setInfo({ name: "Bulk List", desc: "Check the status and list multiple parts at once" });
  }, [])

  useEffect(() => {
    handleSetParts();
  }, [batchId, multipleParts.isLoading, multipleParts.data, policies.data, policies.isLoading])

  const handleSetParts = () => {

    if (!multipleParts.isLoading && multipleParts.data && policies.data && !policies.isLoading) {
      let partsData = multipleParts.data.data
        .filter((part) => part.images && part.images?.length > 0) //Don't want to do anything with parts without images
        .sort((a, b) => (a.vehicle?.vrm || '').localeCompare(b.vehicle?.vrm || ''));
      let currReadyCount = 0;
      let currReadyPartIds: string[] = [];

      // console.log(partsData)
      if (multipleParts.data?.data?.length === 0) {
        //If no data then don't break everything
        setIsLoading(false);
        return;
      }

      let invalidate = false;

      //check each part for errors
      //Flag for refetching the parts data if any of the parts needed to create an offer
      partsData.forEach((part: IPart) => {

        //check for an offer - if none create one with default values
        if (part.offers === undefined || part.offers?.length === 0) {
          createOffer.mutate(part.id, saveHandlers);
          invalidate = true;
        }

        //check if has an ebay category Id - if not then display error and ebay category selector for that part

        //run generate asepcts on part
        if (part.aspects === undefined || part.aspects?.length <= 5) {
          createCustomAspects.mutate(part.id);
          invalidate = true;
        }

        //sort offers by published date
        part.offers.sort(sortOffers);

        //then error check the part
        let offer = part.offers[0] as IEbayOffer;

        // console.log(offer);

        if (offer && offer.description === null) {
          offer.description = generateDescription(part);
          update.mutate(offer);
          invalidate = true;
        };

        let result = checkEbayDisabled({ part, offer, ignoreListingTime: true });

        if (!result.isDisabled && offer?.publishedOn === null) {
          currReadyCount++;
          currReadyPartIds.push(offer.id);
        }
      });

      if (invalidate) {
        queryClient.invalidateQueries(["parts", "multiple"]); //Do it once, rather than 3 times per part
        return;
      }

      let parts = partsData.filter((part: IPart) => !part.offers[0]?.publishedOn && !part.soldDate);
      let published = partsData.filter((part: IPart) => part.offers[0]?.publishedOn && !part.soldDate);
      let sold = partsData.filter((part: IPart) => part.soldDate);

      setReadyCount(currReadyCount);
      setParts(parts);
      setPublishedParts(published);
      setSoldParts(sold);
      setReadyOfferIds(currReadyPartIds);
      setIsLoading(false);
      setLoadingText(false);
    };

  };



  if (isLoading) {
    return (
      <div className="mt-[15vh]">
        <LoadingWheel />
        {loadingText && <p className="text-center text-lg">{loadingArray[loadingIndex]}</p>}
      </div>
    )
  }

  if (!parts) {
    return <div>Something went wrong!</div>
  }

  return (
    <>
      <Modal open={resultsOpen} setOpen={setResultsOpen} width="max-w-6xl" >
        <div className="divide-y">
          <div>
            <h1 className="text-lg font-semibold">Bulk List Results</h1>
            <p>Check the results of each offer being published.</p>
            <p>{results?.filter((res) => res.success).length} Successful, {results?.filter((res) => !res.success).length} Failed</p>
          </div>

          {results?.map((result, i) => (
            <div key={i} className="">
              <p className="font-bold">Status: {result.success ? "Succeeded!" : "Failed"}</p>
              <p>Message: {result.message}</p>
            </div>
          ))}
        </div>
      </Modal>
      <div className="mt-6 space-y-4">
        <div className="flex justify-between">
          <div>
            <h1 className="text-lg font-semibold">Bulk List {parts.length} parts</h1>
            <p>Check the status of the parts below and list them on eBay.</p>
          </div>

          <div className="flex items-start gap-x-4">
            <ButtonInput label={"Apply Defaults to All Parts"} isSubmit={false} onClick={() => applyDefaultsToAll()} classes={undefined} />
            <ButtonInput label={"Auto Reorder Pictures for All Parts"} isSubmit={false} onClick={() => handleAutoReorderImages()} classes={undefined} />
          </div>

        </div>

        <form>
          <p>Select a listing date for all parts:</p>
          <DateTimeInput label={"Listing Date"} register={register} registerName={"listingStarted"} />
        </form>

        <hr />

        <div className="flex items-center gap-x-4">
          <ButtonInput label={`List ${readyCount} Parts`} isSubmit={false} onClick={() => handleBulkList()} classes={undefined} />
          <p className="text-lg">{readyCount} / {parts.length} Parts Ready</p>
        </div>

        <hr />

        <div className="flex flex-col divide-y gap-y-2">
          {parts.map((part, i) => (
            <BulkListRow part={part} key={part.id} policies={policies.data?.data} />
          ))}
        </div>

        <hr />

        <div className="flex items-center gap-x-4 pb-8">
          <ButtonInput label={`List ${readyCount} Parts`} isSubmit={false} onClick={() => handleBulkList()} classes={undefined} />
          <p className="text-lg">{readyCount} / {parts.length} Parts Ready</p>
        </div>

        {publishedParts && publishedParts.length > 0 && (
          <>
            <hr />

            <h1 className="text-lg font-semibold">Published Parts</h1>
            <div className="flex flex-col divide-y gap-y-2">
              {publishedParts?.map((part, i) => (
                <BulkListRow part={part} key={part.id} policies={policies.data?.data} />
              ))}
            </div>
          </>
        )}

        {soldParts && soldParts.length > 0 && (
          <>
            <hr />

            <h1 className="text-lg font-semibold">Sold Parts</h1>
            <div className="flex flex-col divide-y gap-y-2">
              {soldParts?.map((part, i) => (
                <BulkListRow part={part} key={part.id} policies={policies.data?.data} />
              ))}
            </div>
          </>
        )}

      </div>
    </>
  )
}