export interface DashboardTileProps {
  span?: 1 | 2 | 3 | 4 | 5;
  classes?: string;
  children: React.ReactNode;
}

export default function DashboardTile({
  span = 1,
  classes = "",
  children,
}: DashboardTileProps) {
  let spanClass = "";

  switch (span) {
    case 1:
      spanClass = "col-span-1";
      break;
    case 2:
      spanClass = "col-span-2";
      break;
    case 3:
      spanClass = "col-span-3";
      break;
    case 4:
      spanClass = "col-span-4";
      break;
    case 5:
      spanClass = "col-span-5";
      break;
    default:
      console.warn(
        "Invalid span value supplied to DashboardTile, valid values are 1, 2, 3, 4 or 5"
      );
  }

  return (
    <div className={`bg-white shadow rounded p-2 ${spanClass} ${classes}`}>{children}</div>
  );
}
