import { useEffect, useState } from "react";

import useSingleLead from "../../../data/useSingleLead";
import { ILead, QuoteType } from "../../../models/Lead";
import Card from "../../shared/Card/Card";
import LoadingWheel from "../../shared/LoadingWheel";
import { BodyCondition, EngineCondition } from "../../../models/Vehicle";


const SingleDetail = ({ title, value, textColour = "text-gray-900" }: { title: string; value: string | null | undefined; textColour?: string; }) => {
  return (
    <div className="py-2 grid grid-cols-2 gap-4">
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className={`${textColour} text-sm`}>{value}</dd>
    </div>
  );
};

export default function WidgetQuoteDetails({ leadId }: { leadId: string }) {
  const { lead: singleLead } = useSingleLead(leadId);
  const [lead, setLead] = useState<ILead | undefined>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!singleLead.isLoading && singleLead.data) {
      setLead(singleLead.data.data);
      setIsLoading(false);
    }
  }, [singleLead.isLoading, singleLead.data]);

  if (isLoading || lead == undefined) return <Card bodyClassName="p-4" title="Quote Details"><LoadingWheel /></Card>;

  return (
    <Card bodyClassName="p-4 divide-y divide-gray-200" title="Quote Details">
      <SingleDetail title="Quote Type" value={QuoteType[lead.quoteType!]} />
      <SingleDetail title="Road Side Payment" value={lead.roadSidePayment ? "Yes" : "No"} />
      <SingleDetail title="Fast Payment" value={lead.fastPayment ? "Yes" : "No"} />
      <SingleDetail title="Access To Vehicle" value={lead.accessToVehicle ? "Yes" : "No"} />
      <SingleDetail title="V5C" value={lead.v5c ? "Yes" : "No"} />
      <SingleDetail title="Collection" value={lead.isCollection ? "Yes" : "No"} />
      <SingleDetail title={"Engine Condition"} value={EngineCondition[lead?.vehicle?.engineCondition!]} />
      <SingleDetail title={"Body Condition"} value={BodyCondition[lead?.vehicle?.bodyCondition!]} />
      <SingleDetail title={"Alloy Wheels"} value={lead?.vehicle?.alloyWheels ? "Yes" : "No"} />
    </Card>
  );
};