import { useCallback, useMemo } from "react";
import useWilmaUsers from "../data/useWilmaUsers";

export const useUserLookup = () => {
  const { wilmaUsers } = useWilmaUsers();

  const findUser = useCallback(
    (userId: string) => wilmaUsers.data?.data.find((wu) => wu.id === userId),
    [wilmaUsers.data?.data]
  );

  const admins = useMemo(
    () =>
      wilmaUsers.data?.data.filter(
        (wu) => wu.userClaims.find((c) => c.type === "isAdmin")?.value === "true"
      ),
    [wilmaUsers.data?.data]
  );

  const staff = useMemo(
    () =>
      wilmaUsers.data?.data.filter(
        (wu) => wu.userClaims.find((c) => c.type === "isStaff")?.value === "true"
      ),
    [wilmaUsers.data?.data]
  );

  const drivers = useMemo(
    () =>
      wilmaUsers.data?.data.filter(
        (wu) => wu.userClaims.find((c) => c.type === "isDriver")?.value === "true"
      ),
    [wilmaUsers.data?.data]
  );

  const mechanics = useMemo(
    () =>
      wilmaUsers.data?.data.filter(
        (wu) => wu.userClaims.find((c) => c.type === "isMechanic")?.value === "true"
      ),
    [wilmaUsers.data?.data]
  );

  return {
    isLoading: wilmaUsers.isLoading,
    users: wilmaUsers.data?.data,
    findUser,
    admins,
    staff,
    drivers,
    mechanics,
  };
};
