import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";

export default function Exception() {
  return (
    <div className="mt-24 min-h-1/2 bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-bold tracking-tight text-primary-600 sm:text-5xl">
            Error
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                We've run into a problem
              </h1>
              <p className="mt-1 text-base text-gray-500">
                Looks like we've run into a problem, please try that again. If
                the issue persists, contact support.
              </p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <ButtonInput label="Refresh" isSubmit={false} onClick={() => window.location.reload()} classes={undefined} />
              <a
                href="/"
                className="inline-flex items-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
              >
                Go back home
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
