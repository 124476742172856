import date from "date-and-time";
import { Dialog } from "@headlessui/react";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";

import useLeads from "../../../../data/useLeads";
import CSVToJSONConverter from "./CSVToJSONImporter";

export interface ExportContactsProps {
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onImport: React.MouseEventHandler<HTMLButtonElement>;
}

export default function ImportLeads({ onCancel, onImport, }: ExportContactsProps) {
  const { leads } = useLeads();

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-primary-100 sm:mx-0 sm:h-10 sm:w-10">
          <DocumentArrowDownIcon
            className="h-6 w-6 text-primary-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Dialog.Title
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Import Leads from CSV
          </Dialog.Title>
        </div>
      </div>
      <div>
        <div className="my-4 space-y-2">
          <p>This will import all the leads from the CSV file into the system.</p>
          <p>Last import: {leads.data?.data.lastImported !== null ? (date.format(new Date(leads.data?.data.lastImported!), "DD/MM/YY HH:mm")) : "Never"}</p>
        </div>

        <div className="">
          <CSVToJSONConverter />
        </div>

      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </>
  );
}
