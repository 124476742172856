import { Fragment, useEffect, useRef, useState } from "react";

import { ArrowPathIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { flexRender, getCoreRowModel, getExpandedRowModel, getPaginationRowModel, getSortedRowModel, RowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { useDebounce } from "@uidotdev/usehooks";

import useApiHelper from "../../../hooks/useApiHelper";
import { PageMode } from "../../../models/PagedTable";
import { DefaultResponseWithData } from "../../../models/system";
import { ButtonInput } from "../Inputs/ButtonInput";
import LoadingWheel from "../LoadingWheel";


export interface IPagedResponse<T> extends DefaultResponseWithData<IPagedData<T>> { }

export interface IPagedData<T> {
  rows: T[];
  total: number;
  totalPages: number;
  page: number;
  pageSize: number;
}

export interface IPagedTableActionButton<T> {
  label: string;
  onClick: (rows: RowModel<T>) => void;
  disabledOnNoRows?: boolean;
  isLoading?: boolean;
}

export interface IPagedModeToggle {
  label: string;
  pageMode?: PageMode;
  urlQuery?: string;
}

export interface IPagedTableProps<T> {
  url: string;
  columns: any;
  pageMode?: PageMode;
  queryLabel?: string;
  modeToggles?: IPagedModeToggle[];
  customUrlQuery?: string;
  actionButtons?: IPagedTableActionButton<T>[];
  expandedRow?: (row: T) => JSX.Element;
  onRowClick?: (row: T) => void;
  showTotal?: boolean;
}

export default function PagedTable<T>(tableConfig: IPagedTableProps<T>) {
  const { post } = useApiHelper();
  const [query, setQuery] = useState<string>("");
  const [toggles, setToggles] = useState<boolean[]>(new Array(tableConfig.modeToggles ? tableConfig.modeToggles?.length : 0).fill(false));
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pageIndex, setPageIndex] = useState(1);


  const getPageMode = () => {
    let pageModeToggles = tableConfig.modeToggles?.filter((x, i) => x.pageMode && toggles[i]);

    return pageModeToggles == null || pageModeToggles?.length == 0 ? tableConfig.pageMode : pageModeToggles[0].pageMode;
  }

  const getUrl = () => {
    let queryStr = tableConfig.modeToggles?.filter(x => x.urlQuery != undefined).map(x => x.urlQuery).join("&");

    return `${tableConfig.url}/${pageIndex}?pageMode=${getPageMode()}&query=${query}&${queryStr}${(tableConfig.customUrlQuery != null ? "&" + tableConfig.customUrlQuery : "")}`;
  }

  const getSort = () => {
    let sort: any = {};

    if (sorting.length != 0) {
      let matchingCol = tableConfig.columns.filter((x: any) => x.id == sorting[0]?.id)[0];
      let key = matchingCol ? matchingCol.accessorKey : sorting[0].id;

      sort = { ...sorting[0], id: key };
    }

    return sort;
  }

  const debouncedQuery = useDebounce(query, 500);
  const pageParams = useDebounce([debouncedQuery, pageIndex, toggles, sorting], 1000);

  const { data, isLoading, isFetching } = useQuery(
    ["pagedTable", getPageMode(), ...pageParams],
    () => post<any>(getUrl(), getSort()) as Promise<IPagedResponse<T>>,
    {
      keepPreviousData: true,
      refetchInterval: 1000 * 60 * 2 // 2 minutes
    }
  );

  const table = useReactTable({
    data: data?.data.rows ?? [],
    columns: tableConfig.columns,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });


  useEffect(() => {
    if (data && !isLoading) {
      table.setPageSize(data.data.pageSize);
    }
  }, [data, isLoading]);

  const changeQuery = (newQuery: string) => {
    setQuery(newQuery);
    setPageIndex(1);
  };

  const changePage = (increment: number) => {
    setPageIndex(pageIndex + increment);
  }

  //We should really re-write this into something less terrible to look at
  const handleToggle = (index: number) => {
    let newToggles = [...toggles];
    let newState = !newToggles[index];

    if (tableConfig.modeToggles![index].pageMode) {
      newToggles = newToggles.map((x, i) => tableConfig.modeToggles![i].pageMode ? false : x);
    }

    newToggles[index] = newState;

    setToggles(newToggles);
  }

  // useEffect(() => {
  //   let rows = table.getRowModel();
  // }, [table.getSelectedRowModel()]);

  const lastUrlRef = useRef<string | null>(null);

  // Update the ref whenever new data arrives
  useEffect(() => {
    if (data && !isLoading && !isFetching) {
      lastUrlRef.current = getUrl();
    }
  }, [data, isLoading, isFetching]);

  const currentUrl = getUrl();

  // Show the loading wheel only if loading or fetching a new/different call
  const shouldShowLoading = isLoading || (isFetching && lastUrlRef.current !== currentUrl);

  return (
    <div>
      <div className="mt-4 flex justify-between">

        <div className="flex gap-x-5">

          {
            tableConfig.queryLabel ? <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                onChange={(e) => changeQuery(e.target.value)}
                className="pl-10 sm:text-sm lg:min-w-[250px] sm:min-w-[unset] max-w-full lg:max-w-[calc(100% - 3rem)]"
                placeholder={tableConfig.queryLabel!}
                style={{ width: `calc(${tableConfig.queryLabel!.length}ch - 3rem)` }}
              />
            </div> : null
          }

          {toggles?.map((toggle, i) =>
            <div className="flex items-center" key={i}>
              <input onChange={() => handleToggle(i)} checked={toggle} type="checkbox" className="mr-2 h-5 w-5" />
              <label >{tableConfig.modeToggles![i].label}</label>
            </div>
          )}

        </div>

        {tableConfig.showTotal &&
          <div className="flex items-center gap-2">
            <div className="text-sm">Total Rows: {data?.data.total}</div>
          </div>
        }

      </div>
      <div className="flex gap-4 mt-3">
        {tableConfig.actionButtons?.map((button, i) =>
          button.isLoading ?
            <ButtonInput key={i} disabled={true} isSubmit={false} onClick={() => button.onClick(table.getSelectedRowModel())} classes={"!bg-primary-600 text-white"}>
              <ArrowPathIcon className="animate-spin h-5 w-5" />
            </ButtonInput>
            :
            <ButtonInput key={i} label={button.label} disabled={button.disabledOnNoRows ? table.getSelectedRowModel().rows.length === 0 : false} isSubmit={false} onClick={() => button.onClick(table.getSelectedRowModel())} classes={""} />
        )}
      </div>
      <div className="mt-4 bg-white overflow-auto">
        <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-primary-50 text-gray-800">
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                  >
                    {header.isPlaceholder
                      ? null
                      : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' ▲',
                            desc: ' ▼',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>

                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {shouldShowLoading ? (
              <tr>
                <td colSpan={12}>
                  <div className="flex justify-center">
                    <LoadingWheel />
                  </div>
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map((row, i) => {
                let rowCells = row.getVisibleCells();
                return (
                  <Fragment key={i}>
                    <tr
                      key={row.id}
                      onClick={() => tableConfig.onRowClick ? tableConfig.onRowClick(row.original) : null}
                      className="hover:bg-gray-100 hover:cursor-pointer"
                    >
                      {rowCells.map((cell) => (
                        <td key={cell.id} className="px-3 py-3 border text-sm">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                    {row.getIsExpanded() && (
                      <tr>
                        {/* 2nd row is a custom 1 cell row */}
                        <td colSpan={row.getVisibleCells().length}>
                          {tableConfig.expandedRow ? tableConfig.expandedRow(row.original) : null}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })
            )}
          </tbody>
        </table>
        <div className="flex justify-between items-center gap-2 py-4">

          <div className="pl-4">
            {table.getPreFilteredRowModel().rows.length} Rows
          </div>

          <div className="flex gap-2">
            <ButtonInput label={"<"} isSubmit={false} onClick={() => changePage(-1)}
              disabled={!(pageIndex > 1)} classes="text-xl"
            />

            <ButtonInput label={">"} isSubmit={false} onClick={() => changePage(1)}
              disabled={!(pageIndex < data?.data?.totalPages!)} classes="text-xl"
            />

            <span className="flex items-center gap-1">
              <div>Page</div>
              <strong>
                {data?.data.page} of{' '}
                {data?.data.totalPages}
              </strong>
            </span>
          </div>

          <div className="flex gap-2 pr-4">

            <span className="flex items-center gap-1">
              Go to page:
              <input
                type="number"
                defaultValue={pageIndex}
                onChange={e => {
                  let page = e.target.value ? Number(e.target.value) - 1 : 0;
                  page = page < 1 || page > data?.data.totalPages! ? 1 : page;
                  setPageIndex(page);
                }}
                className="border p-1 rounded w-16"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}