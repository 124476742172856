import { useEffect, useState } from "react";

import { DateTime } from "luxon";
import { useParams } from "react-router-dom";

import { faPencil, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RadioGroup } from '@headlessui/react';

import EditListing from "../../components/ebayListings/EditListing";
import EditListingImages from "../../components/ebayListings/EditListingImages";
import PromotionPicker from "../../components/ebayListings/PromotionPicker";
import { VehicleCategoryParts } from "../../components/ebayListings/VehicleCategoryParts";
import Card from "../../components/shared/Card/Card";
import EbayPartMessages from "../../components/shared/EbayPartMessages/EbayPartMessages";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useEbayPromotion from "../../data/useEbayPromotion";
import useSinglePart from "../../data/useSinglePart";
import { useLabelGenerator } from "../../hooks/useLabelGenerator";
import { FormatType, IEbayOffer, ListingDuration } from "../../models/EbayOffer";
import { IPart } from "../../models/Part";
import classNames from "../../utils/classNames";
import formatDateTime from "../../utils/formatDateTime";
import openInNewTab from "../../utils/openInNewTab";
import PriceFormat from "../../utils/priceFormat";
import NotesOutput from "../../components/shared/Notes/NotesOutput";
import { ResourceType } from "../../models/Note";
import sortOffers from "../../utils/sortOffers";

function ItemInfo({ title, value, html = false, cols = "col-span-1" }: { title: string, value: string, html?: boolean, cols?: string }) {

  return (
    <div className={`${cols} flex flex-col`}>
      <p className="text-sm text-gray-500">{title}</p>
      {html ? (
        <p dangerouslySetInnerHTML={{ __html: value }} className="text-lg"></p>
      ) : (
        <p className="text-lg">{value}</p>
      )}
    </div>
  )
}

export function ListingStatus({ offer, part, width = "w-full" }: { offer: IEbayOffer | undefined, part: IPart, width?: string }) {
  let style = "px-4 text-xs mx-auto text-center rounded-full h-10 flex justify-center items-center";
  let colour = "bg-gray-200 text-gray-500";
  let status = "Not Published";

  if (part.soldDate) {
    colour = "bg-green-200 text-green-500";
    status = "Sold";
  }

  if (!offer) {
    return (
      <div className={`${colour} ${style} ${width}`}>
        <p className="">{status}</p>
      </div>
    )
  }

  if (offer.publishedOn) {
    colour = "bg-yellow-200 text-yellow-500";
    status = "Published";
  }

  //It's only active if the listing has been published and and the start date is before now
  if (offer.publishedOn && offer.listingStarted && new Date(offer.listingStarted) < new Date()) {
    colour = "bg-blue-200 text-blue-500";
    status = "Active";
  }

  if (offer.listingEnded) {
    colour = "bg-red-200 text-red-500";
    status = "Not Sold";
  }

  return (
    <div className={`${colour} ${style} ${width}`}>
      <p className="">{status}</p>
    </div>
  )

}

function SingleListing({ offer, part, setShowPromotionPicker }: { offer: IEbayOffer, part: IPart, setShowPromotionPicker: React.Dispatch<React.SetStateAction<boolean>> }) {
  const { promotion } = useEbayPromotion(part.promotionId ? part.promotionId : "");

  const p = promotion as any;

  return (
    <div className="p-5">
      <div className="grid grid-cols-2 gap-y-2">
        <ItemInfo title="Title" value={offer.title} />

        <div className="w-full flex justify-center items-center">
          <ListingStatus offer={offer} part={part} width="w-3/4" />
        </div>

        {/* <ItemInfo title="Description" value={offer.description} html /> */}
        <ItemInfo title="Listing Start"
          value={offer.listingStarted ? formatDateTime(offer.listingStarted, DateTime.DATETIME_MED) : "Not listed"} />
        <ItemInfo title="Type" value={FormatType[offer.format]} />
        <ItemInfo title="Price"
          value={PriceFormat(offer.format == FormatType.Auction ? offer.auctionStartPrice : offer.buyItNowPrice)} />
        <ItemInfo title="Duration" value={ListingDuration[offer.duration]} />

        <div className={`flex flex-col gap-y-1 col-span-2`}>
          <p className="text-sm text-gray-500">Promotion</p>
          <div className="flex gap-2 items-center">
            <p className="text-lg">{part.promotionId && p.data?.data?.response ? p.data?.data?.response?.name : "None"}</p>
            {
              part.promotionId == null && offer.publishedOn &&
              <FontAwesomeIcon className="rounded-full p-1 cursor-pointer hover:ring-2 hover:ring-blue-500" icon={faPencil}
                onClick={() => setShowPromotionPicker(true)}
              />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

function PartInformation({ part }: { part: IPart }) {
  const { generatePartLabel } = useLabelGenerator();
  const handlePrint = async (part: IPart) => {
    const html = await generatePartLabel(part);

    var iframe = document?.getElementById("frame") as HTMLIFrameElement;
    var pri = iframe.contentWindow;
    pri?.document.open();
    pri?.document.write(html);
    pri?.document.close();
    pri?.focus();

    //QR code doesn't show up without a timeout
    setTimeout(async () => {
      pri?.print();
    }, 1);
  };

  return (
    <>
      <Card title="Part Information" bodyClassName="p-5 space-y-2">

        <FontAwesomeIcon className="rounded-full p-1 float-right cursor-pointer hover:ring-2 hover:ring-blue-500" icon={faPrint}
          onClick={x => handlePrint(part)}
        />
        <ItemInfo title="Title" value={part.title ?? ""} />
        <ItemInfo title="Tag Number" value={part.tagNumber ?? ""} />
        {part.partNumber && <ItemInfo title="Part Number" value={part.partNumber} />}
        <ItemInfo title="Vehicle"
          value={`${part.vehicle?.combinedMake} ${part.vehicle?.combinedModel} - ${part.vehicle?.vrm}`} />
        {part.ebayItemNumber && <ItemInfo title="eBay Item Number" value={part.ebayItemNumber ?? ""} />}
        <ItemInfo title="Sold Date" value={part.soldDate ? formatDateTime(part.soldDate, DateTime.DATETIME_MED) : "Not Sold"} />
        {part.price && <ItemInfo title="Sold Price" value={PriceFormat(part.price)} />}

        <div className="pt-4 flex justify-between">
          <ButtonInput label="Go to Stock Page" isSubmit={false} onClick={() => openInNewTab(`/stock/${part.id}`)} classes={undefined} />
        </div>
      </Card>
      <iframe id="frame" className="h-0 w-0 absolute"></iframe>
    </>
  )
}

export default function ViewListing() {
  const { setInfo } = usePageNameContext();
  const { partId } = useParams();
  const { singlePart } = useSinglePart(partId!);
  const [part, setPart] = useState<IPart | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [viewType, setViewType] = useState(0);
  const viewTypes = [
    { value: 0, label: "Information" },
    { value: 1, label: "Images" },
  ];
  const [showPromotionPicker, setShowPromotionPicker] = useState<boolean>(false);

  useEffect(() => {
    setInfo({
      name: "Edit eBay Listing",
      desc: "View and manage offers for this part here.",
    });
  }, []);

  useEffect(() => {
    if (!singlePart.isLoading && singlePart.data?.data) {

      let part = singlePart.data?.data;

      if (part?.offers && part?.offers.length > 0) {
        part.offers.sort(sortOffers);
      }

      if (part.images && part.images.length > 0) {
        part.images.sort((a, b) => {
          if (a.order === null) {
            return 1;
          }

          if (b.order === null) {
            return -1;
          }

          return a.order - b.order;
        });
      }

      setPart(part);
      setIsLoading(false);
    }
  }, [partId, singlePart, isLoading]);

  if (isLoading) {
    return (
      <LoadingWheel />
    );
  }

  return (
    <div>
      <PromotionPicker open={showPromotionPicker} setOpen={setShowPromotionPicker} part={part} />

      <div className="mt-4 flex flex-col md:grid md:grid-cols-3 gap-6">

        {/* Left Column */}
        <div className="col-span-2 flex flex-col">
          {/* Tabs */}
          <RadioGroup value={viewType} onChange={setViewType} className="my-4">
            <RadioGroup.Label className="sr-only">
              Choose which view to display
            </RadioGroup.Label>
            <div className="grid grid-cols-2 lg:grid-cols-2 gap-3">
              {viewTypes.map((type) => (
                <RadioGroup.Option
                  key={`${type.value}`}
                  value={type.value}
                  className={({ active, checked }) =>
                    classNames(
                      active ? "ring-2 ring-offset-2 ring-primary-500" : "",
                      checked
                        ? "bg-primary-600 border-transparent text-white hover:bg-primary-700"
                        : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                      "cursor-pointer focus:outline-none border rounded-md py-1.5 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                    )
                  }
                >
                  <RadioGroup.Label as="span">{type.label}</RadioGroup.Label>
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>

          <>
            {{
              0: <EditListing part={part!} />,
              1: <EditListingImages part={part!} />,
            }[viewType]}
          </>

        </div>

        {/* Right Column */}
        <div className="col-span-1 gap-6 flex flex-col">
          <Card title="Listings" bodyClassName="flex flex-col divide-y">
            {part?.offers.map((offer) => (
              <SingleListing key={offer.id} offer={offer} part={part} setShowPromotionPicker={setShowPromotionPicker} />
            ))}
          </Card>

          <PartInformation part={part!} />
          <Card title="Part Notes" bodyClassName="px-2 pb-4">
            <div>
              <NotesOutput resourceId={part!.id} resourceType={ResourceType.Part} shouldScroll={false} notes={part!.notes ? part!.notes : []} />
            </div>
          </Card>
          <VehicleCategoryParts vehicleId={part!.vehicleId!} categoryId={part!.categoryId!} />
          <EbayPartMessages part={part!} />
        </div>

      </div>


    </div>
  )
}