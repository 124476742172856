import { useMutation, useQuery } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";

export default function useVehicleImages() {
  const { get, getFile } = useApiHelper();

    const downloadDoc = useMutation(
      (docId: any) => getFile(`/vehicle/documents/${docId}`),
      {
        onSuccess: (data: any) => {
          return data;
        },
      }
    );

  return {
    downloadDoc
  };
}
