import { useCallback, useEffect, useRef, useState } from "react";

import { toPng } from "html-to-image";
import { useForm } from "react-hook-form";

import usePartDefaultCategories from "../../data/usePartDefaultCategories";
import useShipping from "../../data/useShipping";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { useLabelGenerator } from "../../hooks/useLabelGenerator";
import Modal from "../../layouts/Modal";
import { ResourceType } from "../../models/Note";
import { IPart } from "../../models/Part";
import { IShippingLabelCreateFormFields, IShippingQuotesRequest } from "../../models/Shipping";
import Card from "../shared/Card/Card";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import LoadingWheel from "../shared/LoadingWheel";
import NotesOutput from "../shared/Notes/NotesOutput";
import PinProtect, { PinProtectState } from "../shared/PinProtect";
import { ManageShippingModal } from "./shipping/ManageShippingModal";


export default function StockPrintLabels({ part }: { part: IPart }) {
  const { defaultPartCategory } = usePartDefaultCategories({ part: part });
  const { saveHandlers } = useDefaultCRUDHandlers("Shipping");
  const { generatePartLabel } = useLabelGenerator();
  const { shippingInfo, customShipping } =
    useShipping(part);
  const [customLoading, setCustomLoading] = useState(false);
  const [shippingModalOpen, setShippingModalOpen] = useState(false);

  const [customShippingOpen, setCustomShippingOpen] = useState(false);
  const [customTrackingNum, setCustomTrackingNum] = useState("");
  const [customTrackingCarrier, setCustomTrackingCarrier] = useState("FedEx");

  const [pinModalOpen, setPinModalOpen] = useState(false);
  const [pinState, setPinState] = useState<PinProtectState>();

  const [labelHtml, setLabelHtml] = useState("");

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    generatePartLabel(part).then((html) => {
      setLabelHtml(html);
    });
  }, [part]);

  const { register, getValues, setValue, handleSubmit, watch } =
    useForm<IShippingLabelCreateFormFields>({
      defaultValues: {
        weight: part.weight,
        height: part.height,
        length: part.length,
        width: part.width,
        addressLine1: part.addressLine1 ?? shippingInfo.data?.data.contactAddress?.addressLine1,
        addressLine2: part.addressLine2 ?? shippingInfo.data?.data.contactAddress?.addressLine2,
        city: part.city ?? shippingInfo.data?.data.contactAddress?.city,
        county: part.county ?? shippingInfo.data?.data.contactAddress?.county,
        postcode: part.postcode ?? shippingInfo.data?.data.contactAddress?.postalCode,
        stateOrProvince: part.stateOrProvince ?? shippingInfo.data?.data.contactAddress?.stateOrProvince,
        fullName: part.fullName ?? shippingInfo.data?.data.fullName,
      },
    });

  const printPartLabel = async () => {
    const html = await generatePartLabel(part);

    var iframe = document?.getElementById("frame") as HTMLIFrameElement;
    var pri = iframe.contentWindow;
    pri?.document.open();
    pri?.document.write(html);
    pri?.document.close();
    pri?.focus();

    //QR code doesn't show up without a timeout
    setTimeout(async () => {
      pri?.print();
    }, 1);
  };

  const printPartLabelImage = useCallback(() => {
    if (ref.current === null) {
      return;
    }

    toPng(ref.current)
      .then((dataUrl) => {
        // console.log(dataUrl)

        var iframe = document?.getElementById("frame") as HTMLIFrameElement;
        var pri = iframe.contentWindow;
        pri?.document.open();
        pri?.document.write(`<img src=${dataUrl} />`);
        pri?.document.close();
        pri?.focus();

        //QR code doesn't show up without a timeout
        setTimeout(async () => {
          pri?.print();
        }, 1);
      })
      .catch((error) => {
        console.error("Something went wrong!", error);
      });
  }, [ref]);

  const getShippingRequest = () => {
    let data = getValues();
    return {
      ...data,
      partDataId: part.id,
    } as any as IShippingQuotesRequest;
  };

  const loadValues = useCallback(() => {
    let shipping = shippingInfo?.data?.data;

    if (!shipping) {
      return;
    }

    let addressLine2 = part.addressLine2 ?? shipping.contactAddress?.addressLine2;
    //regex to remove ebay tracking numbers
    addressLine2 = addressLine2?.replace(
      /(^|[^a-zA-Z0-9])ebay[a-zA-Z0-9]{7}($|[^a-zA-Z0-9])/g,
      ""
    );

    setValue("addressLine1", part.addressLine1 ?? shipping.contactAddress?.addressLine1);
    setValue("addressLine2", addressLine2);
    setValue("city", part.city ?? shipping.contactAddress?.city);
    setValue("county", part.county ?? shipping.contactAddress?.county);
    setValue("postcode", part.postcode ?? shipping.contactAddress?.postalCode);
    setValue("stateOrProvince", part.stateOrProvince ?? shipping.contactAddress?.stateOrProvince);
    setValue("fullName", part.fullName ?? shipping.fullName);

    if (defaultPartCategory.data?.data) {
      setValue("weight", defaultPartCategory.data.data.weight ?? 0);
      setValue("height", defaultPartCategory.data.data.height ?? 0);
      setValue("length", defaultPartCategory.data.data.length ?? 0);
      setValue("width", defaultPartCategory.data.data.width ?? 0);
    }
  }, [shippingInfo.data, defaultPartCategory.data, part, setValue]);

  useEffect(() => {
    if (!shippingInfo.isLoading && shippingInfo.data?.data.contactAddress?.postalCode && !defaultPartCategory.isLoading) {
      loadValues();
    }
  }, [shippingInfo.isLoading, defaultPartCategory.isLoading, loadValues]);


  const sendCustomShipping = () => {
    setCustomLoading(true);

    setPinModalOpen(true);
    setPinState({
      onPinSuccess: () => {
        customShipping.mutate(
          {
            partDataId: part.id,
            trackingNumber: customTrackingNum,
            carrier: customTrackingCarrier,
          },
          {
            onSuccess: () => {
              saveHandlers.onSuccess();
              setCustomShippingOpen(false);
            },
            onError: (res: any) => {
              saveHandlers.onError();
            },
            onSettled: () => {
              setCustomLoading(false);
            },
          }
        );
      },
      onPinFailed: () => { },
      pinLabel: "Submit Tracking Information",
    });
  };

  return (
    <>
      <PinProtect
        open={pinModalOpen}
        setOpen={setPinModalOpen}
        pinState={pinState}
      />
      <Modal
        open={customShippingOpen}
        setOpen={setCustomShippingOpen}
        width="max-w-4xl mx-4"
      >
        <div>
          <div className="mb-4">
            <p className="text-lg font-bold mb-2">Custom Tracking Details</p>

            {customLoading ? (
              <LoadingWheel />
            ) : (
              <>
                <div className="flex flex-col gap-4 mb-2">
                  <div>
                    <label className="text-lg font-bold">
                      Tracking Number:{" "}
                    </label>
                    <input
                      type="text"
                      value={customTrackingNum}
                      onChange={(x) => setCustomTrackingNum(x.target.value)}
                    />
                  </div>
                  <div>
                    <label className="text-lg font-bold">
                      Shipping Carrier:{" "}
                    </label>
                    <select
                      value={customTrackingCarrier}
                      onChange={(x) => setCustomTrackingCarrier(x.target.value)}
                    >
                      <option value={"FedEx"}>Fedex</option>
                      <option value={"RoyalMail"}>Royal Mail</option>
                      <option value={"Other"}>Other</option>
                    </select>
                  </div>
                </div>
                <div className="float-right">
                  <ButtonInput
                    label="Submit Tracking Infomation"
                    isSubmit={false}
                    onClick={() => sendCustomShipping()}
                    classes={"mr-2"}
                    disabled={customTrackingNum.length < 5}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={shippingModalOpen}
        setOpen={setShippingModalOpen}
        width="max-w-4xl mx-4"
      >
        <ManageShippingModal part={part} setOpen={setShippingModalOpen} />
      </Modal>

      <Card title="Print Labels" bodyClassName="p-4" containerClassName="mt-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <ButtonInput
              label="Print Part Label"
              isSubmit={false}
              onClick={() => printPartLabel()}
              classes={undefined}
            />
          </div>
          <div>
            <ButtonInput
              label="Print Part Label Image"
              isSubmit={false}
              onClick={() => printPartLabelImage()}
              classes={undefined}
            />
          </div>
          <div>
            <ButtonInput
              label="Manage Shipping Label"
              isSubmit={false}
              disabled={part.orderId == null}
              onClick={() => setShippingModalOpen(true)}
              classes={"mb-2"}
            />
          </div>
          <div>
            <ButtonInput
              label="Set Tracking Info"
              isSubmit={false}
              onClick={() => setCustomShippingOpen(true)}
              //disabled when the part has been shipped
              disabled={part.shippedOn !== null}
              classes={"mb-2"}
            />
          </div>
        </div>
      </Card>
      <Card title="Part Label" bodyClassName="p-4" containerClassName="mt-4">
        <div className="overflow-x-auto">
          {labelHtml && (
            <div ref={ref} dangerouslySetInnerHTML={{ __html: labelHtml }} />
          )}
        </div>
      </Card>
      <Card title="Part Condition Notes" subtitle="This will go onto the listing" bodyClassName="px-2 pb-4">
        <div>
          <NotesOutput
            resourceId={part.id}
            resourceType={ResourceType.Part}
            shouldScroll={false}
            notes={part.notes ? part.notes : []}
          />
        </div>
      </Card>
      <Card title="Internal Part Notes" subtitle="This will not go onto the listing" bodyClassName="px-2 pb-4">
        <div>
          <NotesOutput
            resourceId={part.id}
            resourceType={ResourceType.InternalPart}
            shouldScroll={false}
            notes={part.internalNotes ? part.internalNotes : []}
          />
        </div>
      </Card>
      <iframe id="frame" className="h-0 w-0 absolute"></iframe>
    </>
  );
}
