import date from "date-and-time";

import Card from "../shared/Card/Card";

import useLeadMessages from "../../data/useLeadMessages";

import { ILead } from "../../models/Lead";

import classNames from "../../utils/classNames";
import { IMessage, MessageStatus } from "../../models/Message";
import LoadingWheel from "../shared/LoadingWheel";
import { createRef, useEffect, useRef, useState } from "react";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

function SMSBubble({ message }: { message: IMessage }) {
  return (
    <div>
      <div
        className={classNames(
          "rounded-lg p-2 px-3 whitespace-pre-line",
          message.status === MessageStatus.Received ||
            message.status === MessageStatus.Read
            ? "bg-gray-200 text-black"
            : "bg-primary-500 text-white"
        )}
      >
        {message.body}
      </div>
      <div className="pt-1 px-2 text-xs text-gray-400">
        {date.format(new Date(message.timestamp), "DD/MM/YY HH:mm")} -{" "}
        {MessageStatus[message.status]}
      </div>
    </div>
  );
}

export default function LeadSMS({ lead }: { lead: ILead }) {
  const { leadMessages, sendMessage, markAsRead } = useLeadMessages(lead.contactId!);
  const { saveHandlers } = useDefaultCRUDHandlers("Text");
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [messageText, setMessageText] = useState("");

  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 500);

    if (!leadMessages.isLoading && leadMessages.data) {
      //if any message is unread, mark it as read
      let unreadMessages = leadMessages.data.data.filter(
        (m) => m.status === MessageStatus.Received
      );

      if (unreadMessages.length > 0) {
        const messageIds = unreadMessages.map((m) => m.id);

        let data = {
          messages: messageIds
        };
        console.log(data);
        markAsRead.mutate(data);
      }

      setMessages(leadMessages.data.data);
    }
  }, [leadMessages.isLoading, leadMessages.data])

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 500);
  }, [setMessages])

  const handleSend = () => {
    let data = {
      contactId: lead.contactId,
      leadId: lead.id,
      recipient: lead.contact?.telephone1,
      body: messageText,
    };

    let newMessage: IMessage = {
      id: "0",
      userId: lead.contactId!,
      recipient: lead.contact?.telephone1!,
      body: messageText,
      status: MessageStatus.Waiting,
      timestamp: new Date().toISOString(),
      sid: "",
      from: "",
      created: new Date().toISOString(),
      updated: null
    };

    sendMessage.mutate(data, {
      onSuccess: () => {
        setMessageText("");
        setMessages([...messages, newMessage]);
        setTimeout(() => {
          scrollToBottom();
        }, 500);
        saveHandlers.onSuccess();
      },
      onError: saveHandlers.onError
    });
  };

  return (
    <Card title="SMS Conversation">
      {leadMessages.isLoading ? (
        <LoadingWheel />
      ) : (
        <div className="divide-y">
          <div className="max-h-[32rem] p-3 grid grid-cols-2 gap-x-16 gap-y-2 overflow-y-auto">
            {messages.map((message, i) => {
              let padLeft = true;

              if (message.status === MessageStatus.Received) {
                padLeft = false;
              }

              if (message.status === MessageStatus.Read) {
                padLeft = false;
              }

              return (
                <>
                  {padLeft && <div />}
                  <SMSBubble key={i} message={message} />
                  {!padLeft && <div />}
                </>
              );
            })}
            <div ref={messagesEndRef} />
          </div>
          <div className="h-32 p-3 grid grid-cols-8 gap-x-4">
            <div className="col-span-7">
              <textarea
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                className="w-full h-full resize-none"
                placeholder="Enter message text here..."
              />
            </div>
            <div className="flex items-end">
              <button
                onClick={handleSend}
                className="w-full rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-700"
                type="button"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )}
    </Card>
  );
}
