import React, { createContext, useContext, useState } from "react";

interface PageNameContextInterface {
  pageInfo: {
    name: string;
    desc: string;
    fill: boolean;
  };
  setInfo: React.Dispatch<
    React.SetStateAction<{
      name: string;
      desc: string;
      fill: boolean;
    }>
  >;
}

interface PageNameProviderInterface {
  children: React.ReactNode;
}

const PageNameContext = createContext<PageNameContextInterface>({
  pageInfo: {
    name: "Dashboard",
    desc: "A quick glance at the current status of your business",
    fill: false,
  },
  setInfo: () => { },
});

export function PageNameProvider({ children }: PageNameProviderInterface) {
  const [pageInfo, setPageInfo] = useState({
    name: "Dashboard",
    desc: "A quick glance at the current status of your business",
    fill: false,
  });

  return (
    <PageNameContext.Provider
      value={{ pageInfo: pageInfo, setInfo: setPageInfo }}
    >
      {children}
    </PageNameContext.Provider>
  );
}

export function usePageNameContext() {
  const pageNameCtx = useContext(PageNameContext);

  if (!pageNameCtx) {
    throw new Error(
      "usePageNameContext must be used within a PageNameProvider"
    );
  }

  const _setInfo = (info: { name: string; desc: string, fill?: boolean }) => {
    pageNameCtx.setInfo({ ...info, fill: info.fill ?? false });
    document.title = `Wilma | ${info.name}`
  }

  return { ...pageNameCtx, setInfo: _setInfo };
}
