import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { CameraIcon, PencilIcon } from "@heroicons/react/24/outline";

import useOffers from "../../../data/useOffers";
import usePartDefaultCategories from "../../../data/usePartDefaultCategories";
import usePartMutations from "../../../data/usePartMutations";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { FormatType, IEbayAllPolicies, IEbayOffer } from "../../../models/EbayOffer";
import { IPart } from "../../../models/Part";
import checkEbayDisabled from "../../../utils/checkEbayDisabled";
import openInNewTab from "../../../utils/openInNewTab";
import PriceFormat from "../../../utils/priceFormat";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import { CheckboxInput } from "../../shared/Inputs/CheckboxInput";
import { NumberInput } from "../../shared/Inputs/NumberInput";
import SelectInput from "../../shared/Inputs/SelectInput";
import LoadingWheel from "../../shared/LoadingWheel";
import { EbayCategoryDropdown } from "../EbayCategoryDropdown";
import { EbayListingFormValues } from "../EditListing";
import EbayTableAspects from "../unlistedTable/EbayTableAspects";
import UnlistedNpmUpdate from "../unlistedTable/UnlistedMpnUpdate";
import SuggestedPrice from "./SuggestedPrice";
import UpdateColourCode from "./UpdateColourCode";
import { DateTime } from "luxon";


export default function BulkListRow({ part, policies }: { part: IPart, policies: IEbayAllPolicies | undefined }) {
  const { defaultPartCategory, useDefaultPartCategory } = usePartDefaultCategories({ part: part });
  const { createOffer } = usePartMutations();
  const { update } = useOffers();
  const { saveHandlers } = useDefaultCRUDHandlers("Part");
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleSubmit, register, getValues, setValue, watch, reset } = useForm<EbayListingFormValues>({
    defaultValues: {
      ...part.offers[0] as EbayListingFormValues,
    }
  });

  const handleCreateOffer = () => {
    createOffer.mutate(part.id, saveHandlers);
  };


  const handleApplyDefaults = () => {
    setIsLoading(true);

    useDefaultPartCategory.mutate(undefined, {
      ...saveHandlers,
      onSettled: () => setIsLoading(false)
    });
  };

  const onSubmit = (data: any) => {
    // console.log("row");

    const updatedOffer = {
      ...part.offers[0] as EbayListingFormValues,
      ...data,
    };

    if (DateTime.fromISO(updatedOffer.listingStarted) < DateTime.now()) {
      updatedOffer.listingStarted = DateTime.now().plus({ minutes: 30 }).toISO();
    };

    update.mutate(updatedOffer, {
      ...saveHandlers,
      onSuccess: () => {
        saveHandlers.onSuccess();
        setIsEditing(false);
      }
    });
  };

  let offer = part.offers[0] as IEbayOffer;


  useEffect(() => {
    reset(part.offers[0] as EbayListingFormValues);

    if (offer?.format === FormatType.Auction && !offer?.auctionStartPrice) {
      setIsEditing(true);
    } else if (offer?.format === FormatType.FixedPrice && !offer?.buyItNowPrice) {
      setIsEditing(true);
    }
  }, [part])

  if (isLoading) {
    return <LoadingWheel />;
  }

  const enableBestOfferValue = watch("enableBestOffer");

  let result = checkEbayDisabled({ part, offer, ignoreListingTime: true });

  const thumbnail = part.images?.find((image) => image.isThumbnail === true);

  return (
    <form className="flex gap-x-2 w-full" onSubmit={handleSubmit(onSubmit)} >
      <span className="flex justify-center relative" >
        {part.images?.length > 0 && part.images.some((image) => image.isThumbnail === true) ? (
          <img
            className="w-32 h-20 object-contain"
            src={thumbnail?.thumbnailUrl ?? thumbnail?.url}
          />
        ) : (
          <CameraIcon className="w-14 text-gray-600" />
        )}
      </span>

      <div key={part.id} className="pt-2 grid grid-cols-5 w-full">
        {/* Col 1 */}
        <div className="col-span-2 space-y-2">
          <div className="flex gap-x-2">{part.title ? <p className="font-semibold">{part.title}</p> : <p className="text-red-500">No title</p>}
            {!part.offers[0]?.publishedOn && !part.soldDate && (
              <ButtonInput isSubmit={false} onClick={() => setIsEditing(!isEditing)} classes={"!bg-gray-100 !shadow-none !p-0 hover:!bg-gray-300"} >
                <PencilIcon className="h-4 w-4 text-gray-700" />
              </ButtonInput>
            )}
          </div>
          <div className="[&>div>span]:text-gray-600">
            <div><span>Reg:</span> {part.vehicle?.vrm}</div>
            <div><span>Make/Model:</span> {part.vehicle?.combinedMake} {part.vehicle?.combinedModel}</div>
            <div><span>VIN:</span> {part.vehicle?.combinedVIN}</div>
            <div><span>Tag Number:</span> {part.tagNumber}</div>
            <div><span>OE Part Number:</span> {part.oePartNumber}</div>
            {isEditing ? (
              <>
                <UnlistedNpmUpdate part={part} onTable={false} />
                <UpdateColourCode part={part} />
              </>
            ) : (
              <>
                <div><span>MPN:</span> {part.partNumber}</div>
                <div><span>Colour Code:</span> {part.vehicle?.vinvisColourCode}</div>
              </>
            )}

          </div>

          {result.isDisabled && <p className="text-red-500">Error: {result.error}</p>}
          {!result.isDisabled && !part.offers[0]?.publishedOn && !part.soldDate && <p className="text-green-500">Ready to list</p>}

          {isEditing && (
            <div className="flex items-start flex-col">
              <ButtonInput
                label={"Apply Defaults"}
                isSubmit={false}
                onClick={() => handleApplyDefaults()}
                classes={""}
                disabled={!defaultPartCategory.data?.data}
              />
              {
                !defaultPartCategory.data?.data &&
                <span className="text-gray-600 text-sm">No defaults found</span>
              }
            </div>
          )}
        </div>

        {/* Col 2 */}

        {/* No offer */}
        {part.offers.length === 0 ? (
          <div className="col-span-2 flex items-center">
            <ButtonInput label="Create Initial Offer" isSubmit={false} onClick={() => handleCreateOffer()} classes={undefined} />
          </div>
        ) : (
          // Offer exists
          <div className="flex flex-col col-span-2">
            {offer?.categoryId ? (
              <EbayTableAspects part={part} button={isEditing} />
            ) : (
              <>
                <p className="text-red-500">Please select an eBay category</p>
                {isEditing && (
                  <EbayCategoryDropdown
                    categoryId={offer?.categoryId}
                    register={register}
                    registerName={"categoryId"}
                    setValue={setValue}
                    watch={watch}
                  />
                )}
              </>
            )}

            {isEditing ? (
              offer?.format === FormatType.Auction ? (
                <div>
                  <div className="flex grow gap-x-4 items-start">
                    <NumberInput
                      label={"Starting Price (£)"}
                      register={register}
                      registerName={"auctionStartPrice"}
                      placeholder={part.suggestedPrice && part.suggestedPrice !== 0 ? `Suggested: ${PriceFormat(part.suggestedPrice)}` : ``}
                    />
                    <div className="mt-6">
                      <SuggestedPrice part={part} />
                    </div>
                  </div>
                  <NumberInput
                    label={"Reserve Price (£)"}
                    register={register}
                    registerName={"auctionReservePrice"}
                  />
                </div>
              ) : (
                <div>

                  <div className="flex grow gap-x-4 items-start">
                    <NumberInput
                      label={`Buy It Now Price (£)`}
                      register={register}
                      registerName={"buyItNowPrice"}
                      placeholder={part.suggestedPrice && part.suggestedPrice !== 0 ? `Suggested: ${PriceFormat(part.suggestedPrice)}` : ``}
                    />
                    <div className="flex grow flex-col">
                      <CheckboxInput label={"Best offer?"} register={register} registerName={"enableBestOffer"} />
                      <div className="mt-3">
                        <SuggestedPrice part={part} />
                      </div>
                    </div>
                  </div>


                  {enableBestOfferValue && (
                    <NumberInput
                      label={"Best Offer Auto Accept Price (£)"}
                      register={register}
                      registerName={"autoAcceptPrice"}
                    />
                  )}
                </div>
              )
            ) : (
              offer?.format === FormatType.Auction ? (
                <div>
                  <p>Price: {PriceFormat(offer?.auctionStartPrice)}
                    <SuggestedPrice part={part} />
                  </p>
                  <p>Reserve: {PriceFormat(offer?.auctionReservePrice)}</p>
                </div>
              ) : (
                <div>
                  <p>Price: {PriceFormat(offer?.buyItNowPrice)}
                    <SuggestedPrice part={part} />
                  </p>
                  {enableBestOfferValue && <p>Auto Accept Price: {PriceFormat(offer?.autoAcceptPrice)}</p>}
                </div>
              )
            )}

            {isEditing ? (
              <div>
                <SelectInput
                  label={"Shipping Policy"}
                  register={register}
                  registerName={"fulfillmentPolicyId"}
                  options={policies?.fulfilmentPolicies}
                  setValue={setValue}
                  watch={watch}
                />
              </div>
            ) : (
              <div>
                <p>Shipping Policy: {policies?.fulfilmentPolicies.find((policy) => policy.id === offer?.fulfillmentPolicyId)?.name ?? "None Set"}</p>
              </div>
            )}

          </div>
        )}


        {/* Col 3 */}
        <div className="flex items-center justify-end gap-x-2">
          <ButtonInput label="View Listing" isSubmit={false} onClick={() => openInNewTab(`/ebay-listings/${part.id}`)} classes={undefined} />
          {isEditing && <ButtonInput label="Save Part" isSubmit={true} onClick={() => { }} classes={undefined} />}
        </div>

      </div>
    </form>
  )
}