import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { GetCustomerBrandResponse, GetCustomerDetailsResponse, GetCustomerFeatureResponse, GetCustomerResponseBrand } from "../models/Customer";
import { useAuth } from "../contexts/AuthContext";

export default function useCustomerDetails() {
  const { get } = useApiHelper();
  const { loggedIn, username } = useAuth();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60 * 10; // 10 minutes

  // const customer = useQuery(
  //   ["customer", "details"],
  //   () => get<GetCustomerDetailsResponse>(`/customer`),
  //   {
  //     staleTime: staleTime,
  //     refetchInterval: staleTime,
  //     refetchIntervalInBackground: true,
  //     enabled: loggedIn,
  //   }
  // );

  const features = useQuery(
    ["customer", "features"],
    () => get<GetCustomerFeatureResponse>(`/customer/features`),
    {
      staleTime: staleTime,
      refetchInterval: staleTime,
      refetchIntervalInBackground: true,
      enabled: loggedIn,
    }
  );

  const brand = useQuery(
    ["customer", "brand"],
    () => get<GetCustomerBrandResponse>(`/customer/brand`),
    {
      staleTime: staleTime,
      refetchInterval: staleTime,
      refetchIntervalInBackground: true,
      enabled: loggedIn,
      // enabled: false,
    }
  );


  return {
    // customer,
    features,
    brand,
  };
}
