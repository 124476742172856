import { useEffect, useState } from "react";

import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import { useNavigate, useNavigation, useParams } from "react-router-dom";

import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RadioGroup } from "@headlessui/react";

import EditListingImages from "../../components/ebayListings/EditListingImages";
import EbayPaymentMethod from "../../components/ebayOrders/EbayPaymentMethod";
import EbayPaymentStatus from "../../components/ebayOrders/EbayPaymentStatus";
import PartsRecall from "../../components/leads/LeadParts/PartsRecall";
import PartVehicleDetails from "../../components/leads/LeadParts/PartVehicleDetails";
import Card from "../../components/shared/Card/Card";
import EbayPartMessages from "../../components/shared/EbayPartMessages/EbayPartMessages";
import { ButtonInput } from "../../components/shared/Inputs/ButtonInput";
import SelectInput from "../../components/shared/Inputs/SelectInput";
import LoadingWheel from "../../components/shared/LoadingWheel";
import LocationPicker from "../../components/stock/LocationPicker";
import StockPrintLabels from "../../components/stock/StockPrintLabels";
import WarehouseLocationFromId from "../../components/warehouseLocations/WarehouseLocationFromId";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useSinglePart from "../../data/useSinglePart";
import Modal from "../../layouts/Modal";
import {
  IPart,
  IPartCancelRequest,
  IPartQcRequest,
  PartStatus,
} from "../../models/Part";
import classNames from "../../utils/classNames";
import formatDateTime from "../../utils/formatDateTime";
import openInNewTab from "../../utils/openInNewTab";
import sortOffers from "../../utils/sortOffers";
import { TextInput } from "../../components/shared/Inputs/TextInput";
import useLeadByVehicleId from "../../data/useLeadByVehicleId";
import useNotification from "../../components/notifications/useNotifications";
import useContactSingle from "../../data/useContactsSingle";

const StockInformation = ({ part }: { part: IPart }) => {
  const navigate = useNavigate();
  const { lead } = useLeadByVehicleId();
  const { cancel, patchPart } = useSinglePart(part.id!);
  const { contact } = useContactSingle(part.removedBy!);
  const { addNotification } = useNotification();
  const [open, setOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [qcOpen, setQcOpen] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);

  const getCancellable = () => {
    if (part.soldDate === null) return "No - Not Sold";

    if (part.shippedOn !== null) return "No - Already Shipped";

    if (
      new Date().getTime() - new Date(part.soldDate!).getTime() >
      7 * 24 * 60 * 60 * 1000
    )
      return "Unlikely - Sold Too Long Ago";

    return "Yes";
  };

  const { watch, register, setValue, getValues } = useForm<IPartCancelRequest>({
    defaultValues: {
      cancelReason: "ADDRESS_ISSUES",
      partId: part.id!,
    },
  });

  const cancelReasonOptions = [
    { id: "ADDRESS_ISSUES", name: "Address Issues" },
    { id: "BUYER_ASKED_CANCEL", name: "Buyer Requested Cancellation" },
    { id: "ORDER_UNPAID", name: "Order Unpaid" },
    {
      id: "OUT_OF_STOCK_OR_CANNOT_FULFILL",
      name: "Out of Stock or Cannot Fulfill",
    },
    { id: "UNKNOWN", name: "Unknown" },
  ];

  const doCancel = () => {
    cancel.mutate(getValues(), {
      onSuccess: (d: any) => {
        console.log(d);
        setCancelOpen(false);
      },
    });
  };

  const { register: qcRegister, getValues: getQcValues } =
    useForm<IPartQcRequest>({
      defaultValues: {
        partStatus: PartStatus.QC,
        partId: part.id!,
      },
    });

  const doSendToQc = () => {
    patchPart.mutate(getQcValues(), {
      onSuccess: (d: any) => {
        console.log(d);
        setQcOpen(false);
      },
    });
  };

  const handleGoToLead = () => {
    lead.mutate(part.vehicle!.id, {
      onSuccess: (lead) => {
        if (
          lead &&
          lead.data &&
          lead.data?.leads &&
          lead.data.leads.length > 0
        ) {
          openInNewTab(`/leads/${lead.data.leads[0].id}`);
        } else {
          addNotification({
            variant: "error",
            primaryText: "Could not find associated lead",
          });
        }
      },
      onError: () => {
        addNotification({
          variant: "error",
          primaryText: "Failed to lookup lead",
        });
      },
    });
  };

  return (
    <div className="flex flex-col gap-y-4">
      <Modal open={cancelOpen} setOpen={setCancelOpen} width="max-w-xl">
        <>
          <p className="text-xl font-semibold">Cancel Order</p>
          <div className="w-full flex flex-col gap-y-4 my-4">
            <p className="flex justify-between">
              <span>Title: </span>
              <span>{part.title}</span>
            </p>
            <p className="flex justify-between">
              <span>Tag: </span>
              <span>{part.tagNumber}</span>
            </p>
            <p className="flex justify-between">
              <span>Sold At: </span>
              <span>
                {formatDateTime(
                  part.soldDate,
                  DateTime.DATETIME_SHORT,
                  "Not Sold"
                )}
              </span>
            </p>
            <p className="flex justify-between">
              <span>Cancellation Acceptable: </span>
              <span>{getCancellable()}</span>
            </p>
            <SelectInput
              label="Reason for Cancellation"
              options={cancelReasonOptions}
              setValue={setValue}
              watch={watch}
              register={register}
              registerName={"cancelReason"}
            />
          </div>

          {confirmCancel ? (
            <div className="flex flex-row min-w-full gap-x-4">
              <ButtonInput
                label="Confirm"
                isSubmit={false}
                onClick={() => doCancel()}
                classes={"flex-grow"}
              />
              <ButtonInput
                label="Close"
                isSubmit={false}
                onClick={() => setConfirmCancel(false)}
                classes={"flex-grow"}
              />
            </div>
          ) : (
            <div className="flex flex-row min-w-full">
              <ButtonInput
                label="Cancel order"
                isSubmit={false}
                onClick={() => setConfirmCancel(true)}
                classes={"flex-grow"}
              />
            </div>
          )}
        </>
      </Modal>
      <Modal open={qcOpen} setOpen={setQcOpen} width="max-w-xl">
        <>
          <p className="text-xl font-semibold">Send to QC</p>
          <div className="w-full flex flex-col gap-y-4 my-4">
            <TextInput
              richText
              rows={5}
              label="Reason Message"
              initialValue={part.statusMessage ? part.statusMessage : ""}
              register={qcRegister}
              registerName={"statusMessage"}
            />
          </div>

          {
            <div className="flex flex-row min-w-full gap-x-4">
              <ButtonInput
                label="Confirm"
                isSubmit={false}
                onClick={() => doSendToQc()}
                classes={"flex-grow"}
              />
              <ButtonInput
                label="Close"
                isSubmit={false}
                onClick={() => setQcOpen(false)}
                classes={"flex-grow"}
              />
            </div>
          }
        </>
      </Modal>
      <LocationPicker open={open} setOpen={setOpen} part={part} />
      <Card title="Part Information" bodyClassName="p-4 space-y-4">
        <div>
          <p className="text-sm font-semibold">Part Title</p>
          <p>{part.title}</p>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="flex flex-row text-sm font-semibold">
              Location
              <FontAwesomeIcon
                className="rounded-full ml-1 cursor-pointer hover:ring-2 hover:ring-blue-500"
                icon={faPencil}
                onClick={() => setOpen(!open)}
              />
            </p>
            <WarehouseLocationFromId id={part.warehouseLocationId} />
          </div>
          <div>
            <p className="text-sm font-semibold">Tag Number</p>
            <p>{part.tagNumber ?? "Not Found"}</p>
          </div>
          <div>
            <p className="text-sm font-semibold">Published Date</p>
            <p>
              {formatDateTime(
                part.offers[0]?.publishedOn,
                DateTime.DATETIME_SHORT,
                "Not Published"
              )}
            </p>
          </div>
          <div>
            <p className="text-sm font-semibold">Listed Date</p>
            <p>
              {formatDateTime(
                part.offers[0]?.listingStarted,
                DateTime.DATETIME_SHORT,
                "No Listed Date"
              )}
            </p>
          </div>
          <div>
            <p className="text-sm font-semibold">Sold Date</p>
            <p>
              {formatDateTime(
                part.soldDate,
                DateTime.DATETIME_SHORT,
                "Not Sold"
              )}
            </p>
          </div>
          <div>
            <p className="text-sm font-semibold">Picked Date</p>
            <p>
              {formatDateTime(
                part.pickedOn,
                DateTime.DATETIME_SHORT,
                "Not Picked"
              )}
            </p>
          </div>
          <div>
            <p className="text-sm font-semibold">Shipped Date</p>
            <p>
              {formatDateTime(
                part.shippedOn,
                DateTime.DATETIME_SHORT,
                "Not Shipped"
              )}
            </p>
          </div>
          <div>
            <p className="text-sm font-semibold">Tracking Number</p>
            <p>{part.trackingNumber ?? "No Tracking Number"}</p>
          </div>
          <div>
            <p className="text-sm font-semibold">Payment Status</p>
            <p>
              <EbayPaymentStatus offers={part.offers} />
            </p>
          </div>
          <div>
            <p className="text-sm font-semibold">Payment Method</p>
            <p>
              <EbayPaymentMethod offers={part.offers} />
            </p>
          </div>
          <div>
            <p className="text-sm font-semibold">Part Status</p>
            <p>{PartStatus[part.partStatus]}</p>
          </div>
          <div>
            <p className="text-sm font-semibold">Status Message</p>
            <p>{part.statusMessage}</p>
          </div>
          <div>
            <p className="text-sm font-semibold">Dismantled Date</p>
            <p>
              {formatDateTime(
                part.removedOn,
                DateTime.DATETIME_SHORT,
                "Not Dismantled"
              )}
            </p>
          </div>
          <div>
            <p className="text-sm font-semibold">Dismantled By</p>
            <p>
              {part.removedBy ? contact.data?.data?.fullname : "Not Dismantled"}
            </p>
          </div>
        </div>

        <div className="flex flex-row pt-4 gap-x-4">
          <ButtonInput
            label="Go to Listing Page"
            isSubmit={false}
            onClick={() => openInNewTab(`/ebay-listings/${part.id}`)}
            classes={undefined}
          />
          <ButtonInput
            label="Send to QC"
            isSubmit={false}
            onClick={() => setQcOpen(true)}
            classes={undefined}
          />
          {part.orderId != null ? (
            <ButtonInput
              label="Cancel order"
              isSubmit={false}
              onClick={() => setCancelOpen(true)}
              classes={undefined}
            />
          ) : null}
          <ButtonInput
            label="View Lead for Vehicle"
            isSubmit={false}
            onClick={handleGoToLead}
            classes={undefined}
          />
        </div>
      </Card>

      {part.vehicle && (
        <PartVehicleDetails vehicle={part.vehicle!} price={null} />
      )}

      <PartsRecall />

      <Card title="Vehicle Parts" bodyClassName="p-4 space-y-4">
        <table className="min-w-full">
          <thead className="text-left">
            <tr>
              <th>Thumbnail</th>
              <th>Part</th>
              <th>Tag Number</th>
            </tr>
          </thead>
          <tbody>
            {part.vehicle?.parts
              .filter((part) => part !== null)
              .map((part, i) => (
                <tr
                  key={part.id}
                  className="cursor-pointer hover:bg-gray-100 border-b"
                  onClick={() => openInNewTab(`/stock/${part.id}`)}
                >
                  <td className="py-2">
                    {part.images.length > 0 ? (
                      <img src={part.images[0].url} className="max-w-[5rem]" />
                    ) : (
                      <p>N/A</p>
                    )}
                  </td>
                  <td>{part.title}</td>
                  <td>{part.tagNumber}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </Card>

      {/* Location, tag number, general part info, published date, listed date, sold date, picked date, shipped date, tracking info */}
    </div>
  );
};

export default function ViewStock() {
  const { setInfo } = usePageNameContext();
  const { partId } = useParams();
  const { singlePart } = useSinglePart(partId!);
  const [part, setPart] = useState<IPart | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [viewType, setViewType] = useState(0);
  const viewTypes = [
    { value: 0, label: "Information" },
    { value: 1, label: "Images" },
  ];

  useEffect(() => {
    setInfo({
      name: "Stock Details",
      desc: "View details of this part here",
    });
  }, []);

  useEffect(() => {
    if (!singlePart.isLoading && singlePart.data?.data) {
      let part = singlePart.data?.data;

      // console.log(part.vehicle?.parts);

      if (part?.offers && part?.offers.length > 0) {
        part.offers.sort(sortOffers);
      }

      if (part.images && part.images.length > 0) {
        part.images.sort((a, b) => {
          if (a.order === null) {
            return 1;
          }

          if (b.order === null) {
            return -1;
          }

          return a.order - b.order;
        });
      }

      setPart(part);
      setIsLoading(false);
    }
  }, [partId, singlePart, isLoading]);

  if (isLoading) {
    return <LoadingWheel />;
  }

  return (
    <div className="mt-4 flex flex-col md:grid md:grid-cols-3 gap-6">
      <div className="col-span-3 ">
        <div className="flex flex-row">
          {part?.images
            .filter((x) => x.useOnEbay)
            .map((image) => (
              <img
                src={image.url}
                className="max-w-[10rem] m-1 border border-slate-500 rounded-md shadow-md"
              />
            ))}
        </div>
      </div>

      {/* Left Column */}
      <div className="col-span-2 flex flex-col">
        {/* Tabs */}
        <RadioGroup value={viewType} onChange={setViewType} className="my-4">
          <RadioGroup.Label className="sr-only">
            Choose which view to display
          </RadioGroup.Label>
          <div className="grid grid-cols-2 lg:grid-cols-2 gap-3">
            {viewTypes.map((type) => (
              <RadioGroup.Option
                key={`${type.value}`}
                value={type.value}
                className={({ active, checked }) =>
                  classNames(
                    active ? "ring-2 ring-offset-2 ring-primary-500" : "",
                    checked
                      ? "bg-primary-600 border-transparent text-white hover:bg-primary-700"
                      : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                    "cursor-pointer focus:outline-none border rounded-md py-1.5 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                  )
                }
              >
                <RadioGroup.Label as="span">{type.label}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>

        <>
          {
            {
              0: <StockInformation part={part!} />,
              1: <EditListingImages part={part!} />,
            }[viewType]
          }
        </>
      </div>

      {/* Right Column */}
      <div className="col-span-1 gap-6 flex flex-col">
        <StockPrintLabels part={part!} />
        <EbayPartMessages part={part!} />
      </div>
    </div>
  );
}
