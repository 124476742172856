import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
  createColumnHelper,
  SortingState,
} from "@tanstack/react-table";

import { IWarehouseLocation } from "../../models/Location";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useLabelGenerator } from "../../hooks/useLabelGenerator";
import { IPackingBoxSize } from "../../models/PackingBoxSize";

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + 'p-3 cursor-pointer mr-0'}
      {...rest}
    />
  )
}


const colHelper = createColumnHelper<IPackingBoxSize>();

const cols = [
  {
    id: 'select',
    header: ({ table }: any) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }: any) => (
      <div className="flex grow-0" onClick={(e) => e.stopPropagation()}>
        <IndeterminateCheckbox
          {...{
            id: row.original.id,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  },
  colHelper.display({
    id: "displayName",
    header: "Name",
    sortingFn: "auto",
    cell: (info) => (
      <div className="flex grow" >
        <span className="ml-4 m-auto">{info.row.original.name}</span>
      </div>
    )
  }),
];


export default function BoxSizeTable({ boxSizes, setSelectedBox }: { boxSizes: IPackingBoxSize[], setSelectedBox: any }) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const { generateWarehouseLabel } = useLabelGenerator();


  const table = useReactTable({
    data: boxSizes,
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <div className="mt-4 bg-white overflow-auto space-y-4">
        <table className="min-w-full overflow-scroll divide-y border divide-gray-300">

          {/* Table headings - very similar to all the other tables */}
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-primary-50 text-gray-800">
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="flex first:w-12 py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                  >
                    {header.isPlaceholder
                      ? null
                      : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' ▲',
                            desc: ' ▼',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>

                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Body */}
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              let rowCells = row.getVisibleCells();
              return (
                <Fragment key={i}>
                  <tr
                    key={row.id}
                    onClick={() => setSelectedBox(row.original)}
                    className={"hover:bg-gray-100 hover:cursor-pointer"}
                  >
                    {rowCells.map((cell) => (
                      <td key={cell.id} className="px-3 py-3 border text-sm">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <></>
                    // <tr>
                    //   {/* 2nd row is a custom 1 cell row */}
                    //   <td colSpan={row.getVisibleCells().length}>
                    //     <LeadsTableSubComponent lead={row.original} />
                    //   </td>
                    // </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>

        </table>
      </div>
    </>
  );
}
