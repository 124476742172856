import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
  createColumnHelper,
  SortingState,
} from "@tanstack/react-table";
import { IPartResponse } from "../../../../models/PartLookup";
import PriceFormat from './../../../../utils/priceFormat';

const colHelper = createColumnHelper<IPartResponse>();

const cols = [
  colHelper.accessor("partType", {
    header: "Name",
    sortingFn: "auto",
  }),
  colHelper.accessor("partNumber", {
    header: "Part Number",
    sortingFn: "auto",
  }),
  colHelper.accessor("priceInformation.avgPrice", {
    header: "Average Price",
    sortingFn: "auto",
    cell: (info) => (
      info.getValue() !== 0 &&
      <span>{PriceFormat(info.getValue())}</span>
    )
  }),
  colHelper.accessor("priceInformation.confidence", {
    header: "Confidence",
    sortingFn: "auto",
  }),
  colHelper.accessor("priceInformation.minPrice", {
    header: "Min Price",
    sortingFn: "auto",
    cell: (info) => (
      info.getValue() !== 0 &&
      <span>{PriceFormat(info.getValue())}</span>
    )
  }),
  colHelper.accessor("priceInformation.maxPrice", {
    header: "Max Price",
    sortingFn: "auto",
    cell: (info) => (
      info.getValue() !== 0 &&
      <span>{PriceFormat(info.getValue())}</span>
    )
  }),
  colHelper.accessor("priceInformation.manufacturerPrice", {
    header: "Brand New Price",
    sortingFn: "auto",
    cell: (info) => (
      info.getValue() !== 0 &&
      <span>{PriceFormat(info.getValue())}</span>
    )
  }),
];


export default function PartsLookupInfoTable({ requestedParts }: { requestedParts: IPartResponse[] }) {
  const [sorting, setSorting] = useState<SortingState>([]);


  const table = useReactTable({
    data: requestedParts,
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });


  return (
    <>
      <div className="mt-4 bg-white overflow-auto space-y-4">
        <table className="min-w-full overflow-scroll divide-y border divide-gray-300">

          {/* Table headings - very similar to all the other tables */}
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-primary-50 text-gray-800">
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="flex first:w-12 py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                  >
                    {header.isPlaceholder
                      ? null
                      : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' ▲',
                            desc: ' ▼',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>

                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Body */}
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              let rowCells = row.getVisibleCells();
              return (
                <Fragment key={i}>
                  <tr
                    key={row.id}
                    // onClick={() => setLocation(row.original)}
                    className={"hover:bg-gray-100 "}
                  >
                    {rowCells.map((cell) => (
                      <td key={cell.id} className="px-3 py-3 border text-sm">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <></>
                    // <tr>
                    //   {/* 2nd row is a custom 1 cell row */}
                    //   <td colSpan={row.getVisibleCells().length}>
                    //     <LeadsTableSubComponent lead={row.original} />
                    //   </td>
                    // </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>

        </table>
      </div>
    </>
  );
}
