import { useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";

import useCategories from "../../../data/useCategories";
import useJobs from "../../../data/useJobs";
import useVehicleParts from "../../../data/useVehicleParts";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { ILead } from "../../../models/Lead";
import { Category, IPartCategory } from "../../../models/Part";
import { IVehicle } from "../../../models/Vehicle";
import openInNewTab from "../../../utils/openInNewTab";
import AwaitingInventoryStatusDropdown from "../../awaitingInventory/AwaitingInventoryStatusDropdown";
import YardStatusDropdown from "../../awaitingInventory/YardStatusDropdown";
import Card from "../../shared/Card/Card";
import ConfirmModal from "../../shared/Confirm/ConfirmModal";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import LoadingWheel from "../../shared/LoadingWheel";
import VehicleLocationDropdown from "../../vehicles/VehicleLocationDropdown";
import DriverNotes from "../LeadInformation/DriverNotes";
import OfficeNotes from "../LeadInformation/OfficeNotes";
import VehicleNotes from "../LeadInformation/VehicleNotes";
import { IJob } from "./../../../models/Job";
import PartCategory from "./PartCategory";
import PartCategoryCombo from "./PartCategoryCombo";
import PartsRecall from "./PartsRecall";
import PartsValue from "./PartsValue";
import PartVehicleDetails from "./PartVehicleDetails";
import usePartsLookup from "../../../data/usePartsLeadLookup";
import PartVehicleImages from "./LeadImagesCarousel";
import SelectedCategoryDetails from "./SelectedCategoryDetails";
import PartsLookup from "./PartsLookup/PartsLookup";


const PartsList = ({
  categories,
  handleCategorySelect,
  selected,
  isMech,
}: {
  categories: Category[];
  handleCategorySelect: any;
  selected: Category[];
  isMech: boolean;
}) => {
  return (
    <>
      {categories.length === 0 ? (
        <div className="w-full py-6 text-2xl flex bg-white shadow rounded-lg">
          <h1 className="w-full text-center">
            No {isMech ? "mechanical" : "body"} parts found that match this vehicle!
          </h1>
        </div>
      ) : (
        categories.sort((a, b) => b.category.averagePrice - a.category.averagePrice)
          .map((category) => (
            <PartCategory
              key={category.category.id}
              category={category}
              handleCategorySelect={handleCategorySelect}
              selected={selected}
              isMech={isMech}
            />
          ))
      )}
    </>
  );
};

const LeadJobs = ({ lead }: { lead: ILead }) => {
  const queryClient = useQueryClient();
  const { remove } = useJobs(true);
  const { deleteHandlers } = useDefaultCRUDHandlers("Job");
  const [deleteJob, setDeleteJob] = useState<IJob | null>(null);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const handleDeleteJob = () => {
    setDeleteLoading(true);
    if (deleteJob) {
      remove.mutate(deleteJob.id, {
        onSuccess: () => {
          queryClient.invalidateQueries(["lead", lead.id]);
          setDeleteOpen(false);
          deleteHandlers.onSuccess();
        },
        onError: deleteHandlers.onError,
        onSettled: () => {
          setDeleteLoading(false);
        },
      });
    } else {
      deleteHandlers.onError();
      setDeleteLoading(false);
    }
  };

  return (
    <>
      <ConfirmModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        title={"Delete job?"}
        message={`Are you sure you want to remove this job?\n It will no longer appear on the job calendar.`}
        confirmButtonText={"Delete"}
        onConfirm={handleDeleteJob}
        isLoading={deleteLoading}
      />
      <Card title="Lead Jobs" bodyClassName="p-4">
        <div className="flex flex-col justify-evenly gap-y-2">
          {lead.jobs?.map((job) => (
            <div
              key={job.id}
              className="flex justify-between border-b-2 last:border-b-0 pb-2"
            >
              <div className="flex flex-col gap-y-2">
                <h1 className="text-lg font-semibold">
                  {job.title.replace(/-[^-]*$/, "")} - {job.duration} mins
                </h1>
                <p>{job.description}</p>
              </div>
              <div className="flex gap-x-2 items-center">
                <ButtonInput
                  label={"View"}
                  isSubmit={false}
                  onClick={() => openInNewTab(`/jobs/${job.id}`)}
                  classes={""}
                />
                <ButtonInput
                  label={"Delete Job"}
                  isSubmit={false}
                  onClick={() => {
                    setDeleteOpen(true);
                    setDeleteJob(job);
                  }}
                  classes={"!bg-red-500 hover:!bg-red-700"}
                />
              </div>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
};


export default function LeadParts({ lead, vehicle, }: { lead: ILead | null; vehicle: IVehicle | null; }) {
  const { categories } = useCategories();
  const { lookup } = usePartsLookup(lead?.id!, lead?.partsLookupRequestId);
  const { parts } = useVehicleParts(lead ? lead.vehicle?.id! : vehicle!.id);
  const [isLoading, setIsLoading] = useState(true);
  const [addManualOpen, setAddManualOpen] = useState(false);
  const [mechCategories, setMechCategories] = useState<Category[]>();
  const [bodyCategories, setBodyCategories] = useState<Category[]>();
  const [selectedCatsMech, setSelectedCatsMech] = useState<Category[]>([]);
  const [selectedCatsBody, setSelectedCatsBody] = useState<Category[]>([]);

  const handleCategorySelect = (category: Category, add: boolean, isMech: boolean) => {
    if (isMech) {
      if (add) {
        setSelectedCatsMech([...selectedCatsMech, category]);
      } else {
        setSelectedCatsMech(
          selectedCatsMech.filter((p) => p.category.id !== category.category.id)
        );
      }
    } else {
      if (add) {
        setSelectedCatsBody([...selectedCatsBody, category]);
      } else {
        setSelectedCatsBody(
          selectedCatsBody.filter((p) => p.category.id !== category.category.id)
        );
      }
    }
  };

  const handleSelectTopCategories = (categories: Category[]) => {
    setSelectedCatsMech([]);
    setSelectedCatsBody([]);

    var mech = categories.filter((c) => c.isMech);
    var body = categories.filter((c) => !c.isMech);

    setSelectedCatsMech(mech);
    setSelectedCatsBody(body);
  };

  const handleAddCategory = (category: IPartCategory) => {
    let isMech = true;

    if (!category) return;

    if (category.mechOnly === false && category.partOnly === true) {
      isMech = false;
    };

    const newCat: Category = {
      category: category,
      parts: [],
      isMech: isMech,
    };

    handleCategorySelect(newCat, true, isMech);
    setAddManualOpen(false);
  };


  useEffect(() => {
    if (!parts.isLoading) {
      const bodyCategories: Category[] = [];
      const mechCategories: Category[] = [];

      // Process body parts
      parts.data?.data.body.forEach((part) => {
        const partCategory = part.category;
        let existingCategory = bodyCategories.find(
          (c) => c.category.name === partCategory?.name
        );
        if (existingCategory) {
          existingCategory.parts.push(part);
        } else {
          existingCategory = {
            category: partCategory!,
            parts: [part],
            isMech: false,
          };
          bodyCategories.push(existingCategory);
        }
      });

      // Process mech parts
      parts.data?.data.mech.forEach((part) => {
        const partCategory = part.category;
        let existingCategory = mechCategories.find(
          (c) => c.category.name === partCategory?.name
        );
        if (existingCategory) {
          existingCategory.parts.push(part);
        } else {
          existingCategory = {
            category: partCategory!,
            parts: [part],
            isMech: true,
          };
          mechCategories.push(existingCategory);
        }
      });

      //Do the calculation for average price now so we don't have to do it everywhere else
      bodyCategories.forEach((category) => {
        category.category.averagePrice =
          category.parts.map((part) => part.price).reduce((a, b) => a! + b!)! /
          category.parts.length;
        category.category.averageMargin =
          category.parts.map((part) => part.margin).reduce((a, b) => a! + b!)! /
          category.parts.length;
      });

      mechCategories.forEach((category) => {
        category.category.averagePrice =
          category.parts.map((part) => part.price).reduce((a, b) => a! + b!)! /
          category.parts.length;
        category.category.averageMargin =
          category.parts.map((part) => part.margin).reduce((a, b) => a! + b!)! /
          category.parts.length;
      });

      setBodyCategories(bodyCategories);
      setMechCategories(mechCategories);

      setIsLoading(false);
    }
  }, [lead, parts.isLoading, parts.data]);

  if (isLoading) return <LoadingWheel />;

  return (
    <div className="mt-6 flex flex-col gap-y-4">
      <PartCategoryCombo
        addManualOpen={addManualOpen}
        setAddManualOpen={setAddManualOpen}
        handleAddCategory={handleAddCategory}
      />
      {lead && (
        <div className="flex text-sm text-gray-700 gap-x-4">
          <div className="flex flex-col">
            <p>Parts Status</p>
            <AwaitingInventoryStatusDropdown
              vehicle={lead.vehicle!}
              classes="pr-20"
            />
          </div>
          <div className="flex flex-col">
            <p>Location</p>
            <VehicleLocationDropdown vehicle={lead.vehicle!} classes="pr-20" />
          </div>
          <div className="flex flex-col">
            <p>Yard Status</p>
            <YardStatusDropdown vehicle={lead.vehicle!} classes="pr-10" />
          </div>
        </div>
      )}

      {lead && (
        <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-x-4 gap-y-4">
          <OfficeNotes lead={lead!} />
          <DriverNotes lead={lead!} />
          <VehicleNotes lead={lead!} />
        </div>
      )}

      <PartVehicleDetails
        vehicle={lead ? lead.vehicle! : vehicle!}
        price={lead ? lead.price : null}
      />

      {lead && <PartVehicleImages lead={lead!} />}

      <PartsLookup leadId={lead?.id!} requestId={lead?.partsLookupRequestId} />

      {lead && lead.jobs?.length! > 0 && <LeadJobs lead={lead} />}

      <PartsValue
        vehicle={lead ? lead.vehicle! : vehicle!}
        leadId={lead?.id}
        categories={[...mechCategories!, ...bodyCategories!]}
        selected={[...selectedCatsMech!, ...selectedCatsBody!]}
        handleSelectTopCategories={handleSelectTopCategories}
      />

      {lead && (selectedCatsMech.length > 0 || selectedCatsBody.length > 0) && (
        <SelectedCategoryDetails
          mech={selectedCatsMech}
          body={selectedCatsBody}
          setMech={setSelectedCatsMech}
          setBody={setSelectedCatsBody}
          lead={lead}
        />
      )}

      {lead && (
        <div>
          <ButtonInput
            label={"Add Manual Category"}
            isSubmit={false}
            onClick={() => setAddManualOpen(true)}
            classes={""}
          />
        </div>
      )}

      <PartsRecall />

      <h1 className="text-lg font-semibold">Mechanical Parts</h1>
      <hr />
      <PartsList
        categories={mechCategories!}
        handleCategorySelect={handleCategorySelect}
        selected={selectedCatsMech}
        isMech={true}
      />

      <h1 className="mt-8 text-lg font-semibold">Body Parts</h1>
      <hr />
      <PartsList
        categories={bodyCategories!}
        handleCategorySelect={handleCategorySelect}
        selected={selectedCatsBody}
        isMech={false}
      />
    </div>
  );
}
