import useUser from "../../data/useUser";

export default function GeneralSettings() {

  const { user } = useUser();

  return (
    <div className="min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <div className="prose-lg">
        <h3 className="px-4 py-2 font-semibold text-primary-700">General Settings</h3>
      </div>
    </div>
  );
}
