import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useTrucks from "../../data/useTrucks";
import { ITruck } from "../../models/Truck";

import LoadingWheel from "../../components/shared/LoadingWheel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import TrucksTable from "../../components/trucks/TrucksTable";
import TruckModal from "../../components/trucks/TruckModel";

export default function Trucks() {
  const { setInfo } = usePageNameContext();
  const { trucks: allTrucks, create, update } = useTrucks(true);
  const [trucks, setTrucks] = useState<ITruck[] | undefined>(undefined);
  const [truck, setTruck] = useState<ITruck | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [createOpen, setCreateOpen] = useState(false);
  const [showInactive, setShowInactive] = useState(false);

  useEffect(() => {
    setInfo({
      name: "Trucks",
      desc: "View and manage your trucks here",
    });
  }, []);

  useEffect(() => {
    if (!allTrucks.isLoading && allTrucks.data) {
      var filter = allTrucks.data?.data.filter(
        (truck) =>
          truck.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          truck.vehicle?.vrm?.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (!showInactive) {
        filter = filter.filter((truck) => truck.active);
      }

      setTrucks(filter);
      setIsLoading(false);
    }
  }, [
    allTrucks.isLoading,
    allTrucks.data,
    searchTerm,
    showInactive,
  ]);

  const handleSetTruck = (truck: ITruck) => {
    setTruck(truck);
    setCreateOpen(true);
  };

  const handleAddTruck = () => {
    setTruck(undefined);
    setCreateOpen(true);
  };

  return allTrucks.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <TruckModal
        open={createOpen}
        setOpen={setCreateOpen}
        truck={truck}
        update={update}
        create={create}
      />
      <div className="mt-4 flex justify-between">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 sm:text-sm"
            placeholder="Name or VRM"
          />
        </div>
        <div className="flex items-center mr-8">
          <input
            id="hideFailed"
            onChange={(e) => setShowInactive(e.target.checked)}
            value="showInactive"
            checked={showInactive}
            type="checkbox"
            className="mr-2 h-5 w-5"
          />
          <label htmlFor="hideFailed">Show Inactive</label>
        </div>
        <button
          className=" bg-primary-600 hover:bg-primary-700 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
          type="button"
          onClick={handleAddTruck}
        >
          Add Truck
        </button>
      </div>
      <TrucksTable trucks={trucks!} setTruck={handleSetTruck} />
    </>
  );
}
