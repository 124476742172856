import { HTMLProps, useEffect, useRef, useState } from "react";

import { CameraIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { createColumnHelper, RowModel } from "@tanstack/react-table";

import { PageMode } from "../../models/PagedTable";
import { IPart, PartStatus } from "../../models/Part";
import { ListingStatus } from "../../pages/EbayListings/ViewListing";
import openInNewTab from "../../utils/openInNewTab";
import OrderStatus from "../ebayOrders/OrderStatus";
import PagedTable from "../shared/Tables/PagedTable";
import WarehouseLocationFromId from "../warehouseLocations/WarehouseLocationFromId";
import PartStatusDropdown from "./PartStatusDropdown";
import usePartMutations from "../../data/usePartMutations";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { useQueryClient } from "@tanstack/react-query";
import { useLabelGenerator } from "../../hooks/useLabelGenerator";
import ConfirmModal from "../shared/Confirm/ConfirmModal";

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!)

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + 'p-3 cursor-pointer'}
      {...rest}
    />
  )
}

const colHelper = createColumnHelper<IPart>();

const cols = [
  {
    id: 'select',
    header: ({ table }: any) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }: any) => (
      <div className="flex justify-center items-center flex-1 p-4" onClick={(e) => e.stopPropagation()}>
        <IndeterminateCheckbox
          {...{
            id: row.original.id,
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
        <button
          onClick={(e) => { e.stopPropagation(); row.toggleExpanded(); }}
          style={{ transform: `rotate(${!row.getIsExpanded() ? "0" : "-270"}deg)` }}
          className="ml-3 p-1 transition duration-500 cursor-pointer border border-gray-400 rounded-full hover:bg-gray-200"
        >
          {/* {row.getIsExpanded() ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4"/>} */}
          <ChevronRightIcon className="h-4 w-4" />
        </button>
      </div>
    ),
  },
  colHelper.display({
    id: "images",
    cell: (info) => (
      <span className="flex justify-center relative" >
        {info.row.original.images?.length > 0 && info.row.original.images.some((image) => image.isThumbnail === true) ? (
          <img
            className="w-32 h-20 object-contain"
            src={`${info.row.original.images.find((image) => image.isThumbnail === true)?.url != null ? info.row.original.images.find((image) => image.isThumbnail === true)?.url : null}`}
          />
        ) : (
          <CameraIcon className="w-14 text-gray-600" />
        )}
      </span>
    ),
  }),
  colHelper.accessor("tagNumber", {
    header: "Tag No.",
    sortingFn: "auto",
  }),
  colHelper.accessor("title", {
    header: "Category",
    sortingFn: "auto",
    // cell: (info) => <span>{info.getValue() !== null ? date.format(new Date(info.getValue()!), "DD/MM/YYYY") : "Not Set"}</span>,
  }),
  colHelper.accessor("vehicle.combinedMake", {
    header: "Make & Model",
    enableSorting: false,
    cell: (info) => <div>
      <p>{info.row.original.vehicle?.combinedMake}</p>
      <p>{info.row.original.vehicle?.combinedModel}</p>
      <p>{info.row.original.vehicle?.dvlaYearOfManufacture}</p>
    </div>,
  }),
  colHelper.accessor("images", {
    id: "# of images",
    header: "Images",
    enableSorting: false,
    cell: (info) => <span className="flex justify-center text-xl">{info.getValue().length > 0 ? "✔" : "✘"}</span>,
  }),
  colHelper.accessor("offers", {
    id: "# of offers",
    header: "Offers",
    enableSorting: false,
    cell: (info) => <span className="flex justify-center text-xl">{info.getValue().length > 0 ? "✔" : "✘"}</span>,
  }),
  // colHelper.accessor("partNumber", {
  //   header: "Part No.",
  //   sortingFn: "auto",
  // }),
  colHelper.accessor("warehouseLocationId", {
    header: "Warehouse",
    sortingFn: "auto",
    cell: (info) => <WarehouseLocationFromId id={info.getValue()} lastStockChecked={info.row.original.lastStockChecked} />,
  }),
  colHelper.accessor("offers", {
    id: "offer status",
    header: "Ebay Status",
    enableSorting: false,
    cell: (info) => <ListingStatus offer={info.getValue()?.at(0)} part={info.row.original} />,
  }),
  colHelper.accessor("offers", {
    id: "order status",
    header: "Order Status",
    enableSorting: false,
    cell: (info) => <OrderStatus part={info.row.original} />,
  }),
  colHelper.accessor("partStatus", {
    header: "Part Status",
    enableSorting: false,
    cell: (info) => <PartStatusDropdown part={info.row.original} />,
  }),

];




export default function StockTable() {
  const { patchPart, deletePart } = usePartMutations();
  const { generatePartLabel } = useLabelGenerator();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Part");
  const queryClient = useQueryClient();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [toDelete, setToDelete] = useState<RowModel<IPart> | undefined>(undefined);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleSendToQc = (rows: RowModel<IPart>) => {
    rows.rows.forEach((row) => {
      let part = row.original;

      if (part.partStatus === PartStatus.QC) {
        console.log("Part is already in QC");
        return;
      }

      patchPart.mutate({ id: part.id, partStatus: PartStatus.QC }, {
        onSuccess: () => {
          saveHandlers.onSuccess();
          queryClient.invalidateQueries(["pagedTable", PageMode.Stock]);
        },
        onError: () => {
          saveHandlers.onError();
        }
      });
    });
  };

  const handleMultiPrint = async (rows: RowModel<IPart>) => {
    var parts = new Array();

    rows.rows.forEach((row) => {
      parts.push(row.original);
    });

    let htmlPages = [] as string[];

    // Use Promise.all to wait for all promises to resolve
    await Promise.all(parts.map(async (part) => {
      const html = await generatePartLabel(part);
      htmlPages.push(html);
    }));

    //Stitch together with a page break inbetween each
    const allHtml = htmlPages.join("<div style='page-break-after: always;'></div>");

    var iframe = document?.getElementById("frame") as HTMLIFrameElement;
    var pri = iframe.contentWindow;
    pri?.document.open();
    pri?.document.write(allHtml);
    pri?.document.close();
    pri?.focus();

    //QR code doesn't show up without a timeout
    setTimeout(async () => {
      pri?.print();
    }, 1);

    // table.resetRowSelection();
  };


  const handleDeleteParts = async () => {
    if (!toDelete) return;

    setDeleteLoading(true);

    try {
      await Promise.all(
        toDelete.rows.map(async (row) => {
          await deletePart.mutateAsync(row.original.id);

          // Deselect the row after deletion
          row.toggleSelected(false);
        })
      );

      deleteHandlers.onSuccess();
      setToDelete(undefined);
      setConfirmOpen(false);
    } finally {
      setDeleteLoading(false);
    }
  };


  return (
    <>
      <ConfirmModal
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title={"Delete these parts?"}
        message={`Are you sure you want to delete these parts? \n This action cannot be undone.`}
        confirmButtonText={"Delete"}
        onConfirm={handleDeleteParts}
        isLoading={deleteLoading}
      />
      <PagedTable
        url="/part/listings"
        columns={cols}
        pageMode={PageMode.Stock}
        queryLabel="Search stock by Tag, Part No., Make, Model, Engine Code, Category, or Warehouse"
        onRowClick={(x: IPart) => openInNewTab(`stock/${x.id}`)}
        actionButtons={[
          { label: "Send to QC", onClick: handleSendToQc, disabledOnNoRows: true },
          { label: "Reprint Labels", onClick: handleMultiPrint, disabledOnNoRows: true },
          {
            label: "Delete Parts",
            onClick: (rows: RowModel<IPart>) => {
              setToDelete(rows);
              setConfirmOpen(true);
            },
            disabledOnNoRows: true
          },
        ]}
        showTotal={true}
      />
      <iframe id="frame" className="h-0 w-0 absolute"></iframe>
    </>
  );
}
