import { useEffect, useState } from "react";
import usePartsLookup from "../../../../data/usePartsLeadLookup";
import Card from "../../../shared/Card/Card";
import { ButtonInput } from "../../../shared/Inputs/ButtonInput";
import LoadingWheel from "../../../shared/LoadingWheel";
import PartsLookupInfoTable from "./PartsLookupInfoTable";
import usePartAssembly from "../../../../data/usePartAssembly";
import { IPartAssembly, IPartAssemblyPartCategories } from './../../../../models/Part';
import { IPartResponse } from "../../../../models/PartLookup";

interface IPartAssemblyMatchedParts {
  assembly: IPartAssembly;
  matchedParts: IPartAssemblyMatchedPart[];
}

interface IPartAssemblyMatchedPart {
  part: IPartResponse;
  matchingCategories: IPartAssemblyPartCategories[];
}

interface IMatchedAssembliesResult {
  matchedAssemblies: IPartAssemblyMatchedParts[];
  unmatchedParts: IPartResponse[];
}


export default function PartsLookup({ leadId, requestId }: { leadId: string, requestId: string | null | undefined }) {
  const { lookup, lookupMutation } = usePartsLookup(leadId, requestId);
  const { assemblies } = usePartAssembly();
  const [isLoading, setIsLoading] = useState(true);
  const [partAssemblies, setPartAssemblies] = useState<any>([]);

  const handleLookup = () => {
    setIsLoading(true);
    lookupMutation.mutate(undefined,
      {
        // onSettled: () => setIsLoading(false)
      }
    );
  }

  useEffect(() => {
    if (!requestId) {
      setIsLoading(false);
      return;
    }

    if (assemblies.isLoading || lookup.isLoading) return;
    if (!assemblies.data || !lookup.data) {
      setIsLoading(false);
      return;
    }

    console.log("starting")

    const matchedAssemblies: IPartAssemblyMatchedParts[] = [];
    const unmatchedParts: IPartResponse[] = [];

    // Create a set to track matched parts by their UCIDs
    const matchedPartUCIDs = new Set<string>();

    assemblies.data.data.forEach((assembly: IPartAssembly) => {
      // Find all matched parts for this assembly
      const matchedParts = lookup.data.data.requestedParts
        .map((part) => {
          const matchingCategories = assembly.partAssemblyPartCategories?.filter((category) => {
            const ucidList = category.partCategory?.ucid?.split(",").map((id) => id.trim()) || [];
            return ucidList.includes(part.ucid);
          });

          // If there are matching categories, track the part as matched
          if (matchingCategories && matchingCategories.length > 0) {
            matchedPartUCIDs.add(part.ucid);

            const matchedPart: IPartAssemblyMatchedPart = {
              part,
              matchingCategories,
            }

            return matchedPart;
          }

          return null; // Exclude parts without matching categories
        })
        .filter((part): part is { part: IPartResponse; matchingCategories: IPartAssemblyPartCategories[] } => part !== null); // Remove null values and ensure correct type

      if (matchedParts.length > 0) {
        matchedAssemblies.push({
          assembly,
          matchedParts,
        });
      }
    });

    // Identify unmatched parts
    lookup.data.data.requestedParts.forEach((part) => {
      if (!matchedPartUCIDs.has(part.ucid)) {
        unmatchedParts.push(part);
      }
    });

    const result: IMatchedAssembliesResult = {
      matchedAssemblies,
      unmatchedParts,
    };

    // console.log(matchedAssemblies.map((matchedPart: IPartAssemblyMatchedPart) => matchedPart.part))

    setPartAssemblies(result);
    setIsLoading(false);
  }, [assemblies.data, assemblies.isLoading, lookup.isLoading, lookup.data, requestId]);


  if (isLoading) {
    return (
      <Card title="Part Lookup Information" bodyClassName="p-4">
        <LoadingWheel />
      </Card>
    )
  }

  return (
    <Card title="Part Lookup Information" bodyClassName="p-4">

      {
        partAssemblies && partAssemblies.matchedAssemblies?.length > 0 && (
          partAssemblies.matchedAssemblies.map((matchedAssembly: any) => (
            <div key={matchedAssembly.assembly.id} className="mt-8">
              <h2 className="font-semibold">{matchedAssembly.assembly.name}</h2>
              <PartsLookupInfoTable requestedParts={matchedAssembly.matchedParts.map((matchedPart: IPartAssemblyMatchedPart) => matchedPart.part) ?? []} />
            </div>
          ))
        )
      }

      {
        partAssemblies && partAssemblies.unmatchedParts?.length > 0 && (
          <div className="mt-8">
            <h2 className="font-semibold">Non-Assembly Parts</h2>
            <PartsLookupInfoTable requestedParts={partAssemblies.unmatchedParts ?? []} />
          </div>
        )
      }

      <div className="lg:col-span-3 mt-4">
        <ButtonInput label="Lookup Part Pricing" isSubmit={false} onClick={handleLookup} classes="" />
      </div>


    </Card>
  )
}

