
import { useEffect, useState } from "react";
import { IDashboardStats } from "../../models/Dashboard";
import { DateTime } from "luxon";
import classNames from "../../utils/classNames";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { PartsStatusDropdowns, PartsStatusValues } from "../../models/Vehicle";
import stringToColour from "../../utils/stringToColour";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
);

const centerPlugin = {
  id: "center-label",
  beforeDraw: function (chart: any) {
    if (chart?.config?.options?.elements?.center) {
      // Get ctx from string
      var ctx = chart.ctx;

      // Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || 'Arial';
      var txt = centerConfig.text;
      var color = centerConfig.color || '#000';
      var maxFontSize = centerConfig.maxFontSize || 75;
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
      // Start with a base font of 30px
      ctx.font = "40px " + fontStyle;

      // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
      var minFontSize = centerConfig.minFontSize;
      var lineHeight = centerConfig.lineHeight || 25;
      var wrapText = false;

      if (minFontSize === undefined) {
        minFontSize = 20;
      }

      if (minFontSize && fontSizeToUse < minFontSize) {
        fontSizeToUse = minFontSize;
        wrapText = true;
      }

      // Set font settings to draw it correctly.
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      ctx.font = fontSizeToUse + "px " + fontStyle;
      ctx.fillStyle = color;

      if (!wrapText) {
        ctx.fillText(txt, centerX, centerY);
        return;
      }

      var words = txt.split(' ');
      var line = '';
      var lines = [];

      // Break words up into multiple lines if necessary
      for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + ' ';
        var metrics = ctx.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > elementWidth && n > 0) {
          lines.push(line);
          line = words[n] + ' ';
        } else {
          line = testLine;
        }
      }

      // Move the center up depending on line height and number of lines
      centerY -= (lines.length / 2) * lineHeight;

      for (var n = 0; n < lines.length; n++) {
        ctx.fillText(lines[n], centerX, centerY);
        centerY += lineHeight;
      }
      //Draw text in center
      ctx.fillText(line, centerX, centerY);
    }
  }
};

ChartJS.register(centerPlugin);

const tabs = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

export default function WeeklyForecastPie({ dashboardStats }: { dashboardStats: IDashboardStats }) {
  const [timeframe, setTimeframe] = useState(DateTime.now().weekdayLong);
  const [stats, setStats] = useState<{ [key: string]: number } | undefined>(undefined);
  const [plugins, setPlugins] = useState<any>([]);


  useEffect(() => {
    if (dashboardStats && dashboardStats.weeklyExportForecast && dashboardStats.weeklyExportForecast[timeframe]) {

      const stats = dashboardStats.weeklyExportForecast[timeframe];
      setStats(stats);

      setPlugins([centerPlugin]);
    }
  }, [dashboardStats, timeframe])

  // useEffect(() => {
  //   ChartJS.register(centerPlugin)

  // }, [])

  if (!stats) {
    return <div></div>
  }

  const partsStatus = Object.values(PartsStatusDropdowns).filter((ees) => isNaN(Number(ees)));

  const data = {
    labels: partsStatus,
    datasets: [{
      label: 'Parts Status',
      data: partsStatus.map((ps) => stats[ps.toString().replace("- ", "").replace("+", "And")]),
      backgroundColor: partsStatus.map((ps) =>
        stringToColour(ps)
      ),
      hoverOffset: 4
    }]
  };

  const options = {
    responsive: true,
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
    cutoutPercentage: 90,
    // aspectRatio: 1.5,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "left",
      },
      title: {
        display: false,
        text: "Weekly Forecast",
      },
    },
    elements: {
      center: {
        text: stats["VehiclesDue"],
        color: '#035196', // Default is #000000
        fontStyle: 'Poppins', // Default is Arial
        // fontWeight: 'bold', // Default is normal
        sidePadding: 20, // Default is 20 (as a percentage)
        minFontSize: 25, // Default is 20 (in px), set to false and text will not wrap.
        lineHeight: 25 // Default is 25 (in px), used for when text wraps
      }
    }
  };



  return (
    <div>
      <div className="mb-3 w-full flex justify-between">
        <div className="prose">
          <h3>Weekly Forecast</h3>
        </div>

        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a day of the week
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              defaultValue={tabs.find((tab) => tab === timeframe)}
            >
              {tabs.map((tab) => (
                <option key={tab}>{tab}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-6" aria-label="Tabs">
                {tabs.map((tab) => (
                  <button
                    key={tab}
                    onClick={() => setTimeframe(tab)}
                    className={classNames(
                      tab === timeframe
                        ? 'border-indigo-500 text-primary-600'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      'whitespace-nowrap border-b-2 py-1 px-1 text-sm font-medium'
                    )}
                    aria-current={tab === timeframe ? 'page' : undefined}
                  >
                    {tab}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>

      </div>

      <div className="relative h-80">
        {/* <div className="pointer-events-none absolute flex justify-center items-center w-full h-full ml-[4vw] text-3xl font-semibold">
          {stats["VehiclesDue"] || "N/A"}
        </div> */}

        {/* @ts-ignore */}
        <Doughnut options={options}
          data={data}
          plugins={plugins || [centerPlugin]}
        />

      </div>

    </div>
  )
}