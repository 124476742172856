import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import Modal from "../../../layouts/Modal";
import Card from "../Card/Card";
import LoadingWheel from "../LoadingWheel";

interface ConfirmProps {
  open: boolean;
  setOpen: any;
  title: string;
  message: string;
  confirmButtonText: string | null;
  onConfirm: any;
  isLoading: boolean;
}

export default function ConfirmModal({ open, setOpen, title, message, confirmButtonText = null, onConfirm, isLoading }: ConfirmProps) {

  return (
    <Modal open={open} setOpen={setOpen} width={"max-w-2xl"}>
      {isLoading ? (
        <div className="py-10">
          <LoadingWheel />
        </div>
      ) : (
        <>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationCircleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                {title}
              </h3>
              <div className="mt-2 py-4">
                <p className="text-sm text-gray-500 whitespace-pre-line">
                  {message}
                </p>
              </div>
            </div>
          </div>
          <div className="justify-between mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
            <button
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:w-auto sm:text-sm"
              onClick={() => onConfirm()}
            >
              {confirmButtonText ? confirmButtonText : "Confirm"}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-etpink-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
        </>
      )}
    </Modal>
  )
}