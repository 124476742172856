import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";

import LoadingWheel from "../../components/shared/LoadingWheel";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import useScrapPrices from "../../data/useScrapPrices";
import { IScrapPrice } from "../../models/ScrapPrice";
import ScrapPricesTable from "../../components/scrapPrices/ScrapPricesTable";
import ScrapPricesModal from "../../components/scrapPrices/ScrapPricesModal";

export default function ScrapPrice() {
  const { setInfo } = usePageNameContext();
  const { scrapPrices: allScrapPrices, create, update } = useScrapPrices();
  const [scrapPrices, setScrapPrices] = useState<IScrapPrice[] | undefined>(undefined);
  const [scrapPrice, setScrapPrice] = useState<IScrapPrice | undefined>(undefined);
  const [editScrapPrice, setEditScrapPrice] = useState<IScrapPrice | undefined>(undefined);

  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [createOpen, setCreateOpen] = useState(false);

  useEffect(() => {
    setInfo({
      name: "Scrap Prices",
      desc: "Catalogue scrap prices against outward postcodes",
    });
  }, []);

  useEffect(() => {
    if (!allScrapPrices.isLoading && allScrapPrices.data) {

      // Filtering code goes here

      setScrapPrices(allScrapPrices.data?.data);
      setIsLoading(false);
    }
  }, [allScrapPrices.isLoading, allScrapPrices.data, searchTerm]);

  const handleSetScrapPrice = (scrapPrice: IScrapPrice) => {
    setEditScrapPrice(scrapPrice);
    setCreateOpen(true);
  };

  const handleAddScrapPrice = () => {
    setEditScrapPrice(undefined);
    setCreateOpen(true);
  }

  return allScrapPrices.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <ScrapPricesModal open={createOpen} setOpen={setCreateOpen} scrapPrice={editScrapPrice} />
      <div className="mt-4 flex justify-between">

        {/* Button for adding a new location */}
        <button
          className=" bg-primary-600 hover:bg-primary-700 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
          type="button"
          onClick={() => handleAddScrapPrice()}
        >
          <PlusSmallIcon className="h-5 w-5 mr-2" />
          Add New Scrap Price
        </button>

      </div>

      {/* Table of scrap price entries */}
      <ScrapPricesTable scrapPrices={scrapPrices!} setScrapPrice={handleSetScrapPrice} />
    </>
  );
}
