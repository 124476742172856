import { useEffect, useState } from "react";

import useSingleLead from "../../../data/useSingleLead";
import { ICalculation, ICalculationData, ILead, QuoteType } from "../../../models/Lead";
import Card from "../../shared/Card/Card";
import LoadingWheel from "../../shared/LoadingWheel";
import PriceFormat from "../../../utils/priceFormat";


export default function QuoteCalculationCard({ leadId }: { leadId: string }) {
  const { lead: singleLead } = useSingleLead(leadId);
  const [lead, setLead] = useState<ILead | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [calculations, setCalculations] = useState<ICalculation[]>();
  const [dropOff, setDropOff] = useState<number>();
  const [payment, setPayment] = useState<ICalculation[]>([]);

  const CalucationStatusRow = ({ calculation }: { calculation: ICalculation }) => {
    let { name, isFixed, value, baseValue, isAddition, customCalculation } = calculation;

    let output = "";

    if (!value) return null;

    if (customCalculation) {
      output = customCalculation;
    } else if (!isFixed) {
      output = `${value}% of ${PriceFormat(baseValue)} (${PriceFormat(baseValue * value / 100)})`;
    } else {
      output = PriceFormat(value);
    }

    // Old
    // value={
    //   calculation.customCalculation
    //     ? calculation.customCalculation 
    //     : calculation.isFixed
    //       ? `${PriceFormat(calculation.value)}`
    //       : `${calculation.value}% of ${PriceFormat(calculation.baseValue)} (${PriceFormat(calculation.baseValue * calculation.value / 100)})`

    return (
      <div className="py-2 grid grid-cols-2 gap-4">
        <dt className="text-sm font-medium text-gray-500">
          {name}
        </dt>
        <dd className={`items-center text-sm ${isAddition ? '' : 'text-red-600'}`}>
          <div>
            {output}
          </div>
        </dd>
      </div>
    )
  };

  const StatusRow = ({ label, value, classes }: { label: string, value: string, classes: string }) => (
    <div className="py-2 grid grid-cols-2 gap-4">
      <dt className="text-sm font-medium text-gray-500">
        {label}
      </dt>
      <dd className={`items-center text-sm ${classes}`}>
        <div>
          {value}
        </div>
      </dd>
    </div>
  );

  const ExtractCalculationData = (json: string) => {
    let raw = JSON.parse(json);
    if (!raw) return null;
    let data: ICalculationData = {
      salvageCalculations: raw.SalvageCalculations ?? [],
      tradePoorCalculations: raw.TradePoorCalculations ?? [],
      postcodeCalculations: raw.PostcodeCalculations ?? [],
      catalyticCalculations: raw.CatalyticCalculations ?? [],
      engineCalculations: raw.EngineCalculations ?? [],
      vfeCalculations: raw.VfeCalculations ?? [],
      generalCalculations: raw.GeneralCalculations ?? [],
      genericCalculations: raw.GenericCalculations ?? [],
      additionalCalculations: raw.AdditionalCalculations ?? [],
      dropOff: raw.DropOff ?? 0,
      payment: raw.Payment ?? {}
    };
    return data;
  };

  const ExtractCalculations = (data: any[]) => {
    let calculations: ICalculation[] = [];

    for (let i = 0; i < data.length; i++) {
      calculations.push({
        name: data[i].Name,
        isFixed: data[i].IsFixed,
        value: data[i].Value,
        baseValue: data[i].BaseValue,
        isAddition: data[i].IsAddition,
        customCalculation: data[i].CustomCalculation
      });
    }
    return calculations;
  };

  useEffect(() => {
    if (!singleLead.isLoading && singleLead.data) {
      setLead(singleLead.data.data);

      let data: ICalculationData | null = ExtractCalculationData(singleLead.data.data.calculationJson!);

      if (!data) {
        setIsLoading(false);
        return;
      }

      console.log(data)
      if (singleLead.data.data.quoteType == QuoteType.Salvage) {
        console.log("Calculation Data", data);
        console.log("Calculation Data", data.salvageCalculations);
        setCalculations([
          ...ExtractCalculations(data.tradePoorCalculations),
          ...ExtractCalculations(data.salvageCalculations),
        ]);
      } else {
        setCalculations([
          ...ExtractCalculations(data.postcodeCalculations),
          ...ExtractCalculations(data.catalyticCalculations),
          ...ExtractCalculations(data.engineCalculations),
          ...ExtractCalculations(data.vfeCalculations),
          ...ExtractCalculations(data.generalCalculations),
          ...ExtractCalculations(data.genericCalculations),
          ...ExtractCalculations(data.additionalCalculations)
        ])
      }

      setDropOff(data.dropOff);
      setPayment(ExtractCalculations([data.payment]));
      setIsLoading(false);
    }
  }, [singleLead.isLoading, singleLead.data]);

  if (isLoading || lead == undefined) return <Card bodyClassName="p-4" title="Quote Calculation"><LoadingWheel /></Card>;

  return (
    <Card bodyClassName="p-4 divide-y divide-gray-200"
      title={`
                ${lead.quoteType == QuoteType.Salvage ? "Salvage" : "Scrap Export"}
                ${lead.isCollection ? "Collection" : "Drop Off"} 
                Calculation
            `}>
      {calculations?.map((calculation, index) => (
        <CalucationStatusRow
          key={index}
          calculation={calculation}
        />
      ))}
      {payment.map((calculation, index) => (
        <CalucationStatusRow
          key={index}
          calculation={calculation}
        />
      ))}
      {!lead.isCollection && dropOff &&
        <StatusRow label="Drop Off" value={PriceFormat(dropOff)} classes="text-gray-900" />
      }

      <hr />
      <StatusRow
        label="Total"
        value={PriceFormat(lead.price)}
        classes="font-medium text-gray-900"
      />
    </Card>
  );
};