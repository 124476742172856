import { useEffect, useRef, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";

import LoadingWheel from "../../components/shared/LoadingWheel";
import { MagnifyingGlassIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import LocationsTable from "../../components/locations/LocationsTable";
import useLocations from "../../data/useLocations";
import { ILocation } from "../../models/Location";
import LocationsModal from "../../components/locations/LocationsModal";

export default function Locations() {
  const { setInfo } = usePageNameContext();
  const { locations: allLocations, create, update } = useLocations();
  const [locations, setLocations] = useState<ILocation[] | undefined>(undefined);
  const [location, setLocation] = useState<ILocation | undefined>(undefined);
  const [editLocation, setEditLocation] = useState<ILocation | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [createOpen, setCreateOpen] = useState(false);
  const [showInactive, setShowInactive] = useState(false);

  useEffect(() => {
    setInfo({
      name: "Locations",
      desc: "View and manage site locations",
    });
  }, []);

  useEffect(() => {
    if (!allLocations.isLoading && allLocations.data) {

    // Filtering code goes here

      setLocations(allLocations.data?.data);
      setIsLoading(false);
    }
  }, [allLocations.isLoading, allLocations.data, searchTerm]);

  const handleSetLocation = (location: ILocation) => {
    setEditLocation(location);
    setCreateOpen(true);
  };

  const handleAddLocation = () => {
    setEditLocation(undefined);
    setCreateOpen(true);
  }

  return allLocations.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <LocationsModal open={createOpen} setOpen={setCreateOpen} location={editLocation} />
      <div className="mt-4 flex justify-between">
        
        {/* Button for adding a new location */}
        <button
          className=" bg-primary-600 hover:bg-primary-700 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
          type="button"
          onClick={handleAddLocation}
        >
          <PlusSmallIcon className="h-5 w-5 mr-2" />  
          Add New Location
        </button>

      </div>
        
      {/* Table of locations */} 
      <LocationsTable locations={locations!} setLocation={handleSetLocation} />
    </>
  );
}
