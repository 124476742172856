import { CheckIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { SubmitHandler, useForm } from "react-hook-form";

interface MailSettingsFormFields {
  email: string;
  password: string;
}

export default function MailSettings() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<MailSettingsFormFields>();

  const onSubmit: SubmitHandler<MailSettingsFormFields> = (data) => {
    console.log(data);
  };

  return (
    <div className="my-4 p-6 bg-white rounded-lg shadow">
      <div className="prose-lg w-full pb-4 border-b border-gray-200 flex items-center text-primary-800">
        <EnvelopeIcon className="w-8 h-8 mr-3" />
        <h3 className="mt-0 font-semibold">Mail Settings</h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-8 border-b border-gray-200 divide-y divide-gray-200">
          <div className="space-y-1">
            <h3 className="text-lg font-medium leading-6 text-primary-800">
              Parser
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">
              Manage settings related to the email parser
            </p>
          </div>
          <div className="mt-3">
            <dl className="divide-y divide-gray-200">
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium text-gray-500 flex items-center">
                  Credentials
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow flex flex-col space-y-1">
                    <div className="w-1/2">
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <input
                        {...register("email")}
                        type="email"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                        placeholder="you@example.com"
                      />
                    </div>
                    <div className="w-1/2">
                      <label htmlFor="password" className="sr-only">
                        Password
                      </label>
                      <input
                        {...register("password")}
                        type="password"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                        placeholder="Password"
                      />
                    </div>
                  </span>
                  <span className="mx-4 flex-shrink-0 flex items-center"></span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="flex-grow"></div>
        <div className="-mx-6 -mb-6 mt-6 bg-primary-50 p-4 px-5 border-t flex justify-end">
          <button
            type="submit"
            className="inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            <CheckIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            Save
          </button>
        </div>
      </form>
    </div>
  );
}
