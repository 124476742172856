import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPartLookupResponse } from "../models/PartLookup";

export default function usePartsLeadLookup(leadId: string, requestId: string | null | undefined) {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60 * 60 * 4; //4 hours

  const lookup = useQuery({
    queryKey: ["partslookup", "lead", leadId],
    queryFn: () => get<IPartLookupResponse>(`/partlookup/lead/${leadId}`),
    staleTime: staleTime,
    cacheTime: staleTime * 2,
    retry: 1,
    enabled: !!requestId,
  });

  const lookupMutation = useMutation(
    () => get<IPartLookupResponse>(`/partlookup/lead/${leadId}?ignoreLookupId=true`),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["partslookup", "lead", leadId], data);
        queryClient.refetchQueries(["parts", "multiple"]);
      },
    }
  );

  return {
    lookup,
    lookupMutation
  };
}
