import { useUserLookup } from "../../hooks/useUserLookup";

export default function ContactFromId({ contactId }: { contactId: string }) {
  // const { user } = useUsersSingle(contactId);
  const { findUser, isLoading } = useUserLookup();

  const user = findUser(contactId);

  if (isLoading || !user) return <></>;

  return (
    <span>{user.fullname}</span>
  )
}