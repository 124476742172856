import { EyeIcon, PencilIcon, PhotoIcon, TrashIcon } from "@heroicons/react/24/outline";
import { IPartImage, PartImageRequirementType } from "../../models/Part";
import classNames from "../../utils/classNames";
import Modal from "../../layouts/Modal";
import { SetStateAction, useState } from "react";

interface PartImageThumbProps {
  image: IPartImage;
  index: number;
  handleReorder: (id: string) => void;
  handleEdit: (id: string, requireUpload?: boolean) => void;
  handleRemove: (id: string) => void;
  reordering: boolean;
}

export function PartImageThumb({ image, index, handleReorder, handleEdit, handleRemove, reordering, }: PartImageThumbProps) {
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);

  const handleOnClick = (id: string) => {
    if (!reordering) return;

    handleReorder(id);
  };

  return (
    <>
      <Modal open={previewOpen} width={"max-w-7xl"} setOpen={setPreviewOpen} >
        <div className="w-full h-full flex justify-center items-center">
          <img src={image.url} alt="Preview" className="max-w-full max-h-full" />
        </div>
      </Modal>
      <div
        onClick={() => handleOnClick(image.id)}
        key={`part-image-${index}`}
        className={classNames(reordering ? "cursor-pointer" : "", "text-center relative w-36 h-36 snap-start")}
      >
        <div className="relative w-full h-full flex justify-center aspect-square z-0 hover:opacity-60">
          {image.url ? (
            <img
              alt="Part"
              id={image.id}
              src={image.thumbnailUrl ?? image.url}
              className="rounded-sm object-contain w-full h-full"
            />
          ) : (
            <PhotoIcon className="w-2/3 rounded-sm object-contain" />
          )}

          {/* Hover area */}
          {!reordering && (
            <div className="opacity-0 flex flex-col justify-between z-20 absolute top-0 right-0 bottom-0 left-0 p-2 rounded-[6px] text-white font-bold bg-[rgba(0,0,0,0.75)] hover:opacity-100">
              <div className="flex grow items-center justify-center">
                <EyeIcon className="h-8 w-8 cursor-pointer hover:scale-125" onClick={() => setPreviewOpen(true)} />
              </div>
              <aside className="flex justify-between">
                <PencilIcon className="h-8 w-8 cursor-pointer hover:scale-125" onClick={() => handleEdit(image.id, false)} />

                <TrashIcon className="h-8 w-8 cursor-pointer hover:scale-125" onClick={() => handleRemove(image.id)} />
              </aside>
            </div>
          )}

          {/* Image Type text */}
          <div
            className={classNames(
              reordering ? "bg-white bg-opacity-50" : "",
              "absolute w-36 h-36 text-base rounded-sm px-1"
            )}
          >
            {image.imageType !== null && (
              <p
                id={image.id}
                className={classNames(
                  reordering ? "bg-white opacity-50" : "",
                  "text-center mx-auto mt-[10%] pt-2 text-white [text-shadow:_2px_0_0_#000,_0_-2px_0_#000,_0_1px_0_#000,_-1px_0_0_#000;]"
                )}
              >
                {PartImageRequirementType[image.imageType]}
              </p>
            )}
          </div>

          {/* Order number */}
          <div
            id={image.id}
            className={classNames(
              reordering ? "bg-white bg-opacity-50" : "",
              "absolute w-36 h-36 text-base rounded-sm px-1"
            )}
          >
            {image.order !== null && (
              <p
                id={image.id}
                className={classNames(
                  reordering ? "" : "opacity-60",
                  "text-center mx-auto mt-[40%] pt-2 bg-primary-600 text-white rounded-full h-10 w-10"
                )}
              >
                {image.order + 1}
              </p>
            )}
          </div>


        </div>
      </div>
    </>
  );
}
