import { useEffect, useState } from "react";
import { usePageNameContext } from "../../contexts/PageTitleContext";

import LoadingWheel from "../../components/shared/LoadingWheel";
import { PlusSmallIcon } from "@heroicons/react/24/outline";
import useWarranties from "../../data/useWarranties";
import { IWarranty } from "../../models/Warranty";
import WarrantiesModal from "../../components/warranties/WarrantiesModal";
import WarrantiesTable from "../../components/warranties/WarrantiesTable";

export default function WarrantyManager() {
  const { setInfo } = usePageNameContext();
  const { warranties: allWarranties, create, update } = useWarranties();
  const [warranties, setWarranties] = useState<IWarranty[] | undefined>(undefined);
  const [warranty, setWarranty] = useState<IWarranty | undefined>(undefined);
  const [editWarranty, setEditWarranty] = useState<IWarranty | undefined>(undefined);

  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [createOpen, setCreateOpen] = useState(false);
  const [showInactive, setShowInactive] = useState(false);

  useEffect(() => {
    setInfo({
      name: "Warranty Manager",
      desc: "View and change warranty information for parts",
    });
  }, []);

  useEffect(() => {
    if (!allWarranties.isLoading && allWarranties.data) {

      // Filtering code goes here

      setWarranties(allWarranties.data?.data);
      setIsLoading(false);
    }
  }, [allWarranties.isLoading, allWarranties.data, searchTerm]);

  const handleSetWarranty = (warranty: IWarranty) => {
    setEditWarranty(warranty);
    setCreateOpen(true);
  };

  const handleAddWarranty = () => {
    setEditWarranty(undefined);
    setCreateOpen(true);
  }

  return allWarranties.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <WarrantiesModal open={createOpen} setOpen={setCreateOpen} warranty={editWarranty} />
      <div className="mt-4 flex justify-between">

        {/* Button for adding a new warranty */}
        <button
          className=" bg-primary-600 hover:bg-primary-700 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
          type="button"
          onClick={() => handleAddWarranty()}
        >
          <PlusSmallIcon className="h-5 w-5 mr-2" />
          Add New Warranty
        </button>

      </div>

      {/* Table of scrap price entries */}
      <WarrantiesTable warranties={warranties!} setWarranty={handleSetWarranty} />
    </>
  );
}
