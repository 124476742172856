import { useEffect, useRef, useState } from "react"

import { useDrag } from "react-dnd"

import { ArrowDownIcon, ArrowTopRightOnSquareIcon, ArrowUpIcon } from "@heroicons/react/24/outline"
import { useQueryClient } from "@tanstack/react-query"

import Modal from "../../layouts/Modal"
import { DragTypes } from "../../models/DragTypes"
import { ILead, LeadStatus } from "../../models/Lead"
import { Distances } from "../../pages/Calendar/TripManager"
import Lead from "../../pages/Leads/Lead"
import classNames from "../../utils/classNames"

interface ILeadEventProps {
  lead: ILead
  selected?: ILead | undefined
  handleSelected?: any
  distances?: Distances | undefined
  inGroup: number | undefined
  handleOrderChange: any
  searchTerm?: string
}

export default function LeadEvent({ lead, selected, handleSelected, distances, inGroup, handleOrderChange, searchTerm }: ILeadEventProps) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const prevState = usePrevious(open);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: DragTypes.EVENT,
    item: lead,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }))

  function usePrevious(value: any) {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    });

    return ref.current;
  }

  useEffect(() => {
    //After you close the lead modal, invalidate leads
    if (open === false && prevState === true) {
      queryClient.invalidateQueries(["leads"]);
    }
  }, [open])

  const handleColour = () => {
    let background = "bg-red-200"

    if (lead.vehicle?.vrm === "MA57FEU") {
      console.log(lead)
    }

    if (lead.completedOn !== null || lead.status === LeadStatus["Completed"] )  background = "bg-green-300";

    if (lead.status === LeadStatus["Collected"]) background = "bg-orange-300";
    if (lead.status === LeadStatus["Cancelled"]) background = "bg-red-500 line-through";

    if (lead.id == selected?.id) background = "bg-blue-200";

    if (distances !== undefined) {
      background = "bg-gray-300";

      if (distances?.green?.includes(lead.id)) {
        background = "bg-green-500";
      } else if (distances?.amber?.includes(lead.id)) {
        background = "bg-yellow-500";
      } else if (distances?.red?.includes(lead.id)) {
        background = "bg-red-500";
      }
    }

    if (searchTerm !== undefined && searchTerm.length > 0) {
      if (lead.vehicle?.vrm?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.vehicle?.postcode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.contact?.postcode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lead.contact?.fullname?.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        background = "bg-yellow-200";
      }
    }

    return background;
  }



  return (
    <>
      <Modal open={open} setOpen={setOpen} width="max-w-6xl">
        <Lead modalLeadId={lead.id} />
      </Modal>

      <div
        className={classNames(handleColour(), " col-span-1 flex justify-between items-center mx-1 text-sm p-1 rounded-md mb-2 cursor-default")}
        ref={drag}
        onClick={() => handleSelected(lead)}
        style={{
          opacity: isDragging ? 0.8 : 1,
        }}
      >
        <span>
          {lead.vehicle?.hasAlert && "⚠️"}
          {lead.vehicle?.isVan && "🚚"}
          {`${lead.vehicle?.vrm ?? ""}, ${lead.vehicle?.postcode ?? ""} `}
        </span>

        {/* <span>
          {lead.order}
        </span> */}

        <div className="flex">

          {(inGroup !== undefined && inGroup > 1) && (
            <>
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOrderChange(true, lead);
                }}
              >
                <ArrowUpIcon className="h-4 w-4" />
              </button>

              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOrderChange(false, lead);
                }}
              >
                <ArrowDownIcon className="h-4 w-4" />
              </button>
            </>
          )}

          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
          >
            <ArrowTopRightOnSquareIcon className="h-5 w-5" />
          </button>
        </div>
      </div>
    </>
  )
}