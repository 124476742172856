import { useEffect, useState } from "react";

import classNames from "../../utils/classNames";
import { TextInputWithValue } from "../shared/Inputs/TextInputWithValue";

interface EbayCategoryDefaultTitleProps {
  register: any;
  getValues: any;
  setValue: any;
  registerName?: string;
};

type IDefaults = {
  label: string,
  value: any,
  dataClass: string,
  tailwindClass: string
};

export default function EbayCategoryDefaultTitle({ register, getValues, setValue, registerName = "titleStructure" }: EbayCategoryDefaultTitleProps) {
  // const registerName = "titleStructure";

  const [currentTitle, setCurrentTitle] = useState<string>(getValues(registerName));
  const [rawTitle, setRawTitle] = useState<string>(getValues(registerName));
  const [titleLabel, setTitleLabel] = useState(`Listing Title ${currentTitle?.length > 0 ? `(${currentTitle?.length}/80)` : ""}`);

  useEffect(() => {
    setTitleLabel(`Listing Title ${currentTitle?.length > 0 ? `(${currentTitle?.length}/80)` : ""}`);
  }, [currentTitle]);

  const [defaults, setDefaults] = useState<IDefaults[]>([
    { label: "Category Name", value: "{{categoryName}}", dataClass: "data-yellow", tailwindClass: "bg-yellow-300" },
    { label: "Year", value: "{{year}}", dataClass: "data-green", tailwindClass: "bg-green-300" },
    { label: "Make", value: "{{make}}", dataClass: "data-blue", tailwindClass: "bg-blue-300" },
    { label: "Model", value: "{{model}}", dataClass: "data-orange", tailwindClass: "bg-orange-300" },
    { label: "MK", value: "{{mk}}", dataClass: "data-lime", tailwindClass: "bg-lime-300" },
    { label: "Fuel Type", value: "{{fuelType}}", dataClass: "data-teal", tailwindClass: "bg-teal-300" },
    { label: "Door", value: "{{door}}", dataClass: "data-cyan", tailwindClass: "bg-cyan-300" },
    { label: "Body Style", value: "{{bodyStyle}}", dataClass: "data-indigo", tailwindClass: "bg-indigo-300" },
    { label: "Engine Capacity", value: "{{engineCapacity}}", dataClass: "data-slate", tailwindClass: "bg-slate-300" },
    { label: "Engine Code", value: "{{engineCode}}", dataClass: "data-purple", tailwindClass: "bg-purple-300" },
    { label: "BHP", value: "{{bhp}}", dataClass: "data-fuchsia", tailwindClass: "bg-fuchsia-300" },
    { label: "No. Gears", value: "{{noGears}}", dataClass: "data-pink", tailwindClass: "bg-pink-300" },
    { label: "Transmission", value: "{{transmission}}", dataClass: "data-rose", tailwindClass: "bg-rose-300" },
    { label: "Mileage", value: "{{mileage}}", dataClass: "data-amber", tailwindClass: "bg-amber-300" },
  ]);

  const handleSetValue = (name: string, value: any) => {
    setCurrentTitle(value);
    setValue(name, value);
  };


  const changeTitle = (def: IDefaults) => {
    let newTitle = currentTitle;

    if (!newTitle) {
      handleSetValue(registerName, def.value.toString());
      return;
    }

    let word = def.value.toString();
    // Escape special characters in the word for the regex
    const escapedWord = word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    // Create a case-insensitive regex to match the exact phrase
    const regex = new RegExp(escapedWord, "i");

    if (regex.test(newTitle)) {
      // Remove the value, ensuring spaces are handled correctly
      newTitle = newTitle.replace(regex, "").trim().replace(/\s{2,}/g, " ");
      handleSetValue(registerName, newTitle);
    } else {
      // Add the value
      newTitle = `${newTitle} ${word}`.trim().replace(/\s{2,}/g, " ");
      handleSetValue(registerName, newTitle);
    }

  };

  useEffect(() => {
    let title = currentTitle;

    // check if title includes any of the default values
    defaults.forEach((def) => {
      if (title?.toLowerCase().includes(def.value?.toString().toLowerCase())) {
        title = title?.toUpperCase().replace(def.value?.toString().toUpperCase(), `<span ${def.dataClass}>${def.value}</span>`);
      }
    });

    setRawTitle(title);
  }, [currentTitle]);

  useEffect(() => {
    console.log(getValues(registerName));
    if (!getValues(registerName)) {
      //Default title if empty
      let title = `{{year}} {{make}} {{model}} {{categoryName}}`;
      setRawTitle(title);
      setCurrentTitle(title);
    };
  }, []);

  return (
    <>
      {/* Table of title fields */}
      <div className="col-span-2 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
        {defaults.map((def, i) => {
          let exists = false;

          if (currentTitle) {
            //Only check if the default is in the title if the title is not empty
            exists = currentTitle?.toLowerCase().includes(def.value?.toString().toLowerCase());
          }

          // this doesn't work because of Tailwind tree shake or something
          // let existsClass = exists ? `bg-${def.colour}-300` : "";
          let existsClass = exists ? def.tailwindClass : "";
          return (
            <div
              key={i}
              className={classNames(existsClass, " text-center border border-gray-300 p-2 cursor-pointer hover:bg-gray-200 select-none")}
              onClick={() => changeTitle(def)}
            >
              <span>{def.label}</span>
            </div>
          );
        })}
      </div>

      <TextInputWithValue
        label={"Listing Title (80 characters max)"}
        register={register}
        registerName={registerName}
        cols="sm:col-span-2"
        value={currentTitle}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentTitle(e.target.value)}
      />
      {/* <label className="block text-sm font-medium text-gray-700 col-span-2">{rawTitle}</label> */}
      <div
        className="block text-sm col-span-1
        [&>[data-green]]:bg-green-300
        [&>[data-lime]]:bg-lime-300
        [&>[data-teal]]:bg-teal-300
        [&>[data-cyan]]:bg-cyan-300
        [&>[data-indigo]]:bg-indigo-300
        [&>[data-slate]]:bg-slate-300
        [&>[data-purple]]:bg-purple-300
        [&>[data-fuchsia]]:bg-fuchsia-300
        [&>[data-pink]]:bg-pink-300
        [&>[data-rose]]:bg-rose-300
        [&>[data-emerald]]:bg-emerald-300
        [&>[data-amber]]:bg-amber-300
        [&>[data-blue]]:bg-blue-300
        [&>[data-orange]]:bg-orange-300
        [&>[data-yellow]]:bg-yellow-300"
        dangerouslySetInnerHTML={{ __html: rawTitle }} ></div>
      <div className="flex justify-end text-sm text-red-600 col-span-1">{!getValues(registerName) && "Title Structure Not Saved"}</div>

    </>
  )
}