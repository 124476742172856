import { useEffect, useMemo, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import ActivityGraph from "../../components/dashboard/ActivityGraph";
import ActivityTable from "../../components/dashboard/ActivityTable";
import DashboardTile from "../../components/dashboard/DashboardTile";
import DashboardTileMultiple from "../../components/dashboard/DashboardTileMultiple";
import EngineExportGraph from "../../components/dashboard/EngineExportGraph";
import LeadsBookedTable from "../../components/dashboard/LeadsBookedTable";
import PostcodePricesTable from "../../components/dashboard/PostcodePricesTable";
import VehiclesCollectedTable from "../../components/dashboard/VehiclesCollectedTable";
import WeeklyForecastPie from "../../components/dashboard/WeeklyForecasePie";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { useAuth } from "../../contexts/AuthContext";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import useCustomerDetails from "../../data/useCustomerDetails";
import useDashboardStats from "../../data/useDashboardStats";
import useUtilities from "../../data/useUtilities";
import { IDashboardStats } from "../../models/Dashboard";
import { ITellerooAccount } from "../../models/Utilities";
import PriceFormat from "../../utils/priceFormat";

export default function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const { claims, loggedIn, loading } = useAuth();
  const { setInfo } = usePageNameContext();
  const { getTellerooAccount } = useUtilities();
  const { features } = useCustomerDetails();
  const { stats } = useDashboardStats();
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardStats, setDashboardStats] = useState<IDashboardStats>();
  const [tellerooAccount, setTellerooAccount] = useState<ITellerooAccount>();

  const checkDriver = () => {
    //redirect drivers away
    if (claims?.isDriver && !claims.isStaff && !claims.isAdmin) {
      navigate("/find-lead", { state: { from: location }, replace: true });
    }
  };

  const hasParts = useMemo(() => {
    return features.data?.data?.find((f) => f.name === "Parts Tracker");
  }, [features.data]);

  useEffect(() => {
    //Change to individual loading wheels
    if (!stats.isLoading && stats.data && !features.isLoading && loggedIn && !loading) {
      checkDriver();
      setDashboardStats(stats.data.data);

      if (getTellerooAccount?.data)
        setTellerooAccount(getTellerooAccount.data.data);

      setIsLoading(false);
    }
  }, [stats.data, getTellerooAccount?.data, loggedIn, loading, hasParts]);

  useEffect(() => {
    setInfo({
      name: "Dashboard",
      desc: "A quick glance at the current status of your business",
    });
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <LoadingWheel />
      </div>
    );
  } else {
    return (
      <>
        {hasParts && (
          <>
            <div className="mt-8 grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-6">
              <DashboardTile>
                <div className="h-full rounded bg-orange-100 text-orange-600 p-2 px-4 prose-base">
                  <h1 className="text-orange-600 font-semibold">
                    {dashboardStats?.partsSoldToday}
                  </h1>
                  <p className="!m-0">Parts Sold</p>
                  <p className="!m-0 text-sm">today</p>
                </div>
              </DashboardTile>
              <DashboardTile>
                <div className="h-full rounded bg-green-100 text-green-600 p-2 px-4 prose-base">
                  <h1 className="text-green-600 font-semibold">
                    {PriceFormat(dashboardStats?.partsSoldTodayPrice)}
                  </h1>
                  <p className="!m-0">Parts Sold (£)</p>
                  <p className="!m-0 text-sm">today</p>
                </div>
              </DashboardTile>
              <DashboardTile>
                <div className="h-full rounded bg-pink-100 text-pink-600 p-2 px-4 prose-base">
                  <h1 className="text-pink-600 font-semibold">
                    {dashboardStats?.partsRemovedToday}
                  </h1>
                  <p className="!m-0">Parts Removed</p>
                  <p className="!m-0 text-sm">today</p>
                </div>
              </DashboardTile>
              <DashboardTile>
                <div className="h-full rounded bg-slate-100 text-slate-600 p-2 px-4 prose-base">
                  <h1 className="text-slate-600 font-semibold">
                    {dashboardStats?.exportEnginesRemovedToday}
                  </h1>
                  <p className="!m-0">Export Engines Removed</p>
                  <p className="!m-0 text-sm">today</p>
                </div>
              </DashboardTile>
              <DashboardTile>
                <div className="h-full rounded bg-lime-100 text-lime-600 p-2 px-4 prose-base">
                  <h1 className="text-lime-600 font-semibold">
                    {dashboardStats?.vehiclesCrushedToday}
                  </h1>
                  <p className="!m-0">Vehicles Crushed</p>
                  <p className="!m-0 text-sm">today</p>
                </div>
              </DashboardTile>
              <DashboardTile>
                <div className="h-full rounded bg-teal-100 text-teal-600 p-2 px-4 prose-base">
                  <h1 className="text-teal-600 font-semibold">
                    {dashboardStats?.vehiclesDepollutedToday}
                  </h1>
                  <p className="!m-0">Vehicles Depolluted</p>
                  <p className="!m-0 text-sm">today</p>
                </div>
              </DashboardTile>
            </div>
          </>
        )}

        <div className="mt-3 grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-6">
          <DashboardTile>
            <div className="h-full rounded bg-green-100 text-green-600 p-2 px-4 prose-base">
              <h1 className="text-green-600 font-semibold">
                {PriceFormat(
                  tellerooAccount?.balance ? tellerooAccount.balance / 100 : 0
                )}
              </h1>
              <p className="!m-0">Telleroo Balance</p>
            </div>
          </DashboardTile>
          <DashboardTile>
            <div className="h-full rounded bg-red-100 text-red-600 p-2 px-4 prose-base">
              <h1 className="text-red-600 font-semibold">
                {PriceFormat(
                  tellerooAccount?.awaitingApprovalBalance
                    ? tellerooAccount.awaitingApprovalBalance / 100
                    : 0
                )}
              </h1>
              <p className="!m-0">Awaiting Approval</p>
            </div>
          </DashboardTile>
          <DashboardTile>
            <div className="h-full rounded bg-amber-100 text-amber-600 p-2 px-4 prose-base">
              <h1 className="text-amber-600 font-semibold">
                {dashboardStats?.unscheduledLeads}
              </h1>
              <p className="!m-0">Unscheduled Leads</p>
            </div>
          </DashboardTile>
          <DashboardTile>
            <div
              onClick={() => navigate("leads?unread=1")}
              className="h-full cursor-pointer rounded bg-blue-100 text-blue-600 p-2 px-4 prose-base"
            >
              <h1 className="text-blue-600 font-semibold">
                {dashboardStats?.unreadMessages}
              </h1>
              <p className="!m-0">Unread Messages</p>
            </div>
          </DashboardTile>
          <DashboardTile>
            <div
              onClick={() => navigate("leads?reschedule=1")}
              className="h-full cursor-pointer rounded bg-fuchsia-100 text-fuchsia-600 p-2 px-4 prose-base"
            >
              <h1 className="text-fuchsia-600 font-semibold">
                {dashboardStats?.rescheduledLeads}
              </h1>
              <p className="!m-0">Leads to Reschedule</p>
            </div>
          </DashboardTile>
          {hasParts && (
            <DashboardTile>
              <div className="h-full rounded bg-teal-100 text-teal-600 p-2 px-4 prose-base">
                <h1 className="text-teal-600 font-semibold">
                  {dashboardStats?.listedParts}
                </h1>
                <p className="!m-0">Parts Listed</p>
              </div>
            </DashboardTile>
          )}
        </div>


        <div className="mt-3 grid gap-3 grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-5">
          <DashboardTile>
            <div className="h-full rounded bg-indigo-100 text-indigo-600 p-2 px-4 prose-base">
              <h1 className="text-indigo-600 font-semibold">
                {PriceFormat(dashboardStats?.totalOutgoingDueTomorrow)}
              </h1>
              <p className="!m-0">Total outgoing due tomorrow</p>
            </div>
          </DashboardTile>
          <DashboardTile>
            <div className="h-full rounded bg-orange-100 text-orange-600 p-2 px-4 prose-base">
              <h1 className="text-orange-600 font-semibold">
                {dashboardStats?.collectionsToday}
              </h1>
              <p className="!m-0">Collections today</p>
            </div>
          </DashboardTile>
          <DashboardTile>
            <div className="h-full rounded bg-zinc-100 text-zinc-600 p-2 px-4 prose-base">
              <h1 className="text-zinc-600 font-semibold">
                {dashboardStats?.bookedToday}
              </h1>
              <p className="!m-0">Booked Today</p>
            </div>
          </DashboardTile>
          <DashboardTileMultiple
            colour="lime"
            values={[
              dashboardStats?.sccConversionRate
                ? dashboardStats?.sccConversionRate?.toString() + "%"
                : "",
              dashboardStats?.ctbConversionRate
                ? dashboardStats?.ctbConversionRate?.toString() + "%"
                : "",
              dashboardStats?.mwConversionRate
                ? dashboardStats?.mwConversionRate?.toString() + "%"
                : "",
            ]}
            labels={[
              "SCC Conversion Rate",
              "CTB Conversion Rate",
              "Motorwise Conversion Rate",
            ]}
            subLabels={["", "", ""]}
          />
          <DashboardTileMultiple
            values={[
              dashboardStats?.engineExportRate
                ? dashboardStats?.engineExportRate?.toString() + "%"
                : "",
              dashboardStats?.engineExportRateWeek
                ? dashboardStats?.engineExportRateWeek?.toString() + "%"
                : "",
              dashboardStats?.engineExportRateMonth
                ? dashboardStats?.engineExportRateMonth?.toString() + "%"
                : "",
            ]}
            labels={[
              "Engine Export Rate",
              "Engine Export Rate",
              "Engine Export Rate",
            ]}
            subLabels={["", "Last 7 Days", "This Month"]}
          />

          <DashboardTile>
            <div className="h-full rounded bg-indigo-100 text-indigo-600 p-2 px-4 prose-base">
              <h1 className="text-indigo-600 font-semibold">
                {PriceFormat(
                  dashboardStats?.totalPurchasesMonth
                    ? dashboardStats?.totalPurchasesMonth
                    : 0
                )}
              </h1>
              <p className="!m-0">Total Purchases</p>
              <p className="!m-0 text-sm">this month</p>
            </div>
          </DashboardTile>
          <DashboardTile>
            <div className="h-full rounded bg-teal-100 text-teal-600 p-2 px-4 prose-base">
              <h1 className="text-teal-600 font-semibold">
                {PriceFormat(
                  dashboardStats?.averageCommissionMonth
                    ? dashboardStats?.averageCommissionMonth
                    : 0
                )}
              </h1>
              <p className="!m-0">Average Commission</p>
              <p className="!m-0 text-sm">this month</p>
            </div>
          </DashboardTile>
          <DashboardTile>
            <div className="h-full rounded bg-pink-100 text-pink-600 p-2 px-4 prose-base">
              <h2 className="text-pink-600 font-semibold !mb-10">
                {dashboardStats?.avgTimeToSchedule}
              </h2>
              <p className="!m-0">Average Time To Schedule</p>
            </div>
          </DashboardTile>
          <DashboardTile>
            <div className="h-full rounded bg-purple-100 text-purple-600 p-2 px-4 prose-base">
              <h1 className="text-purple-600 font-semibold">
                {dashboardStats?.lastSCCImportDate}
              </h1>
              <p className="!m-0">Last SCC Import Date</p>
            </div>
          </DashboardTile>

          <DashboardTileMultiple
            colour="cyan"
            values={[
              dashboardStats?.percentageStartsDrivesLast30Days
                ? dashboardStats?.percentageStartsDrivesLast30Days.toFixed(2) +
                  "%"
                : "",
              dashboardStats?.percentageStartsDrivesDriverLast30Days
                ? dashboardStats?.percentageStartsDrivesDriverLast30Days.toFixed(
                    2
                  ) + "%"
                : "",
            ]}
            labels={[
              "Last 30 Days Starts & Drives",
              "Last 30 Days Starts & Drives",
            ]}
            subLabels={["Office", "Driver"]}
          />

          {/* SPACERS */}
          <div />
          <div />
          <div />
          {/* END SPACERS */}

          {hasParts && (
            <>
              <DashboardTile span={3}>
                <div className="p-3">
                  <WeeklyForecastPie dashboardStats={dashboardStats!} />
                </div>
              </DashboardTile>

              <ActivityTable
                dashboardStats={dashboardStats!}
                dayKey="removedDay"
                weekKey="removedWeek"
                MonthKey="removedMonth"
                title="Parts Removed"
                keyCol="User"
                valueCol="Removed"
              />
            </>
          )}

          <DashboardTile span={3}>
            <div className="p-3">
              <div className="mb-3 prose">
                <h3>Activity for the last 30 days</h3>
              </div>
              <ActivityGraph dashboardStats={dashboardStats!} />
            </div>
          </DashboardTile>

          <LeadsBookedTable dashboardStats={dashboardStats!} />

          <DashboardTile span={3}>
            <div className="p-3">
              <div className="mb-3 prose">
                <h3>Engine Export Opportunities</h3>
              </div>
              <EngineExportGraph dashboardStats={dashboardStats!} />
            </div>
          </DashboardTile>

          <VehiclesCollectedTable dashboardStats={dashboardStats!} />

          {/* <DashboardTile span={3}>
          <div className="p-3">
            <div className="mb-3 prose">
              <h3>Average Postcode Area Price</h3>
            </div>
            <OutcodePriceGraph dashboardStats={dashboardStats!} />
          </div>
        </DashboardTile> */}

          <PostcodePricesTable dashboardStats={dashboardStats!} />

          {/* <DashboardTile span={3}>
          <div className="p-3">
            <div className="mb-3 prose">
              <h3>Activity for the last 7 days</h3>
            </div>
            <ActivityGraph />
          </div>
        </DashboardTile> */}
          {/* <div className="grid gap-y-3 grid-rows-3">
          <DashboardTile>
            <div className="py-3 prose-lg w-full h-full flex flex-col items-center justify-between">
              <p className="m-0">Conversion Rate</p>
              <h1 className="font-semibold m-0">73.27%</h1>
              <p className="text-sm m-0">this month</p>
            </div>
          </DashboardTile>
          <DashboardTile>
            <div className="py-3 prose-lg w-full h-full flex flex-col items-center justify-between">
              <p className="m-0">Total Purchases</p>
              <h1 className="font-semibold m-0">£26,128.63</h1>
              <p className="text-sm m-0">this month</p>
            </div>
          </DashboardTile>
          <DashboardTile>
            <div className="py-3 prose-lg w-full h-full flex flex-col items-center justify-between">
              <p className="m-0">Avg Commission Cost</p>
              <h1 className="font-semibold m-0">£27.94</h1>
              <p className="text-sm m-0">this month</p>
            </div>
          </DashboardTile>
        </div> */}
        </div>
      </>
    );
  }
}
