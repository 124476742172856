import { useEffect, useState } from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { IPartCategory } from "../../models/Part";
import useCategories from "../../data/useCategories";
import LoadingWheel from "../../components/shared/LoadingWheel";
import { usePageNameContext } from "../../contexts/PageTitleContext";
import CategoryTable from "../../components/categoryEditor/CategoryTable";
import CategoryModal from "../../components/categoryEditor/CategoryModal";

export default function CategoryEditor() {
  const { setInfo } = usePageNameContext();
  const { categories: allCategories, create, update } = useCategories();
  const [categories, setCategories] = useState<IPartCategory[] | undefined>(undefined);
  const [category, setCategory] = useState<IPartCategory | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [createOpen, setCreateOpen] = useState(false);

  useEffect(() => {
    setInfo({
      name: "Category Editor",
      desc: "View and edit your categories here",
    });
  }, []);

  useEffect(() => {
    if (!allCategories.isLoading && allCategories.data) {

      var filter = allCategories.data?.data.filter((category) =>
        category.name.toLowerCase().includes(searchTerm.toLowerCase()) //||
        // category.vehicle?.vrm?.toLowerCase().includes(searchTerm.toLowerCase())
      )

      setCategories(filter);
      setIsLoading(false);
    }
  }, [allCategories.isLoading, allCategories.data, searchTerm]);

  const handleSetCategory = (category: IPartCategory) => {
    setCategory(category);
    setCreateOpen(true);
  };

  const handleAddCategory = () => {
    setCategory(undefined);
    setCreateOpen(true);
  }

  return allCategories.isLoading || isLoading ? (
    <div className="mt-8 min-h-[50vh] my-4 p-4 bg-white rounded-lg shadow">
      <LoadingWheel />
    </div>
  ) : (
    <>
      <CategoryModal open={createOpen} setOpen={setCreateOpen} category={allCategories.data?.data.find(cat => cat.id === category?.id)} update={update} create={create} />
      <div className="mt-4 flex justify-between">
        <div className="relative flex items-stretch flex-grow focus-within:z-10">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-10 sm:text-sm"
            placeholder="Name"
          />
        </div>
        <button
          className=" bg-primary-600 hover:bg-primary-700 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
          type="button"
          onClick={handleAddCategory}
        >
          Add Category
        </button>
      </div>
      <CategoryTable categories={categories!} setCategory={handleSetCategory} />
    </>
  );
}
