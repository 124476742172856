import { NavLink } from "react-router-dom";

import { NavigationItemInterface } from "../../../types/Navigation";

import {
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useMemo, useState } from "react";
import useCustomerDetails from "../../../data/useCustomerDetails";
import classNames from './../../../utils/classNames';

export interface DesktopSidebarProps {
  navigation: NavigationItemInterface[];
}

function getNavItem(item: NavigationItemInterface) {
  if (item.disabled) {
    return <div
      className="group flex items-center px-2 py-2 text-sm text-gray-400 font-medium rounded-md select-none"
      key={item.name}
    >
      <item.icon
        className="mr-3 h-6 w-6 flex-shrink-0 text-currentColor"
        aria-hidden="true"
      />
      {item.name}
    </div>;
  }

  if (item.external) {
    return <a
      key={item.name}
      href={item.to}
      target="_blank"
      rel="noreferrer"
      className="group flex items-center px-2 py-2 text-sm font-medium rounded-md text-primary-200"
    >
      <item.icon
        className="mr-3 h-6 w-6 flex-shrink-0 text-currentColor"
        aria-hidden="true"
      />
      {item.name}
    </a>;
  }

  return <NavLink
    key={item.name}
    to={item.to}
    className={({ isActive }) =>
      classNames("group flex items-center px-2 py-2 text-sm font-medium rounded-md",
        isActive ? "bg-primary-700 text-primary-50" : "text-primary-200"
      )}
  >
    <item.icon
      className="mr-3 h-6 w-6 flex-shrink-0 text-currentColor"
      aria-hidden="true"
    />
    {item.name}
  </NavLink>;
}

export default function DesktopSidebar({ navigation }: DesktopSidebarProps) {
  const { brand } = useCustomerDetails();
  const brandLogo = brand.data?.data.logo;

  // Get unique groups, filtering out any falsy values
  const groups = useMemo((): string[] => {
    return Array.from(new Set(navigation.map((item) => item.group))).filter((group): group is string => Boolean(group));
  }, [navigation]);

  const getInitialGroupToggleState = (groups: string[], navigation: NavigationItemInterface[]) => {
    return groups.map((group) => {
      const groupItems = navigation.filter((item) => item.group === group);
      // If every item in this group is disabled, start the toggle as false
      return groupItems.every((item) => item.disabled) ? false : true;
    });
  };

  // Initialize toggle states for each group based on the number of groups
  const [groupToggle, setGroupToggle] = useState(() => getInitialGroupToggleState(groups, navigation));

  // If the groups change, reset the intial state
  useEffect(() => {
    setGroupToggle(getInitialGroupToggleState(groups, navigation));
  }, [groups, navigation]);

  // Toggle the state for a specific group
  const toggleGroup = (group: string) => {
    const groupIndex = groups.indexOf(group);
    setGroupToggle(prev =>
      prev.map((open, idx) => (idx === groupIndex ? !open : open))
    );
  };
  const renderedGroups = new Set<string>();

  return (
    <div className="hidden md:!fixed md:!inset-y-0 md:!flex md:!w-60 md:!flex-col">
      <div className="flex flex-grow flex-col overflow-y-auto bg-primary-600 pt-5">
        <div className="flex flex-shrink-0 justify-center items-center px-4">
          <img className="h-12 w-auto" src={brandLogo} alt="Company Logo" />
        </div>
        <div className="mt-5 flex flex-col mb-8">
          {navigation.map((item, index) => {
            if (item.group) {
              // Only render the group header the first time we encounter this group
              if (!renderedGroups.has(item.group)) {
                renderedGroups.add(item.group);
                const groupItems = navigation.filter(nav => nav.group === item.group);
                const groupIndex = groups.indexOf(item.group);
                return (
                  <nav key={item.group} className="space-y-1 px-2 pb-4">
                    <div
                      className="flex flex-row items-center px-2 text-md font-medium rounded-md text-currentColor cursor-pointer text-primary-200"
                      onClick={() => toggleGroup(item.group!)}
                    >
                      <span>{item.group}</span>
                      <span
                        className="w-5 h-5 ml-1 transition duration-500"
                        style={{
                          transform: groupToggle[groupIndex]
                            ? "rotate(270deg)"
                            : "rotate(90deg)",
                        }}
                      >
                        <ChevronLeftIcon />
                      </span>
                    </div>
                    {groupToggle[groupIndex] &&
                      groupItems.map((navItem) => (
                        <div key={navItem.to}>
                          {getNavItem(navItem)}
                        </div>
                      ))}
                  </nav>
                );
              }
              // Skip rendering duplicate group items
              return null;
            } else {
              // Render navigation items without a group directly in order
              return (
                <nav key={item.to} className="space-y-1 px-2 pb-4">
                  {getNavItem(item)}
                </nav>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}