import { Fragment, useEffect, useState } from "react";

import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";

export default function ComboBoxInput({ label = "", options, searchField, valueField = "", register, setValue, registerName, defaultOption = false, initialValue = "" }) {
  const [selected, setSelected] = useState("");
  const [query, setQuery] = useState('')

  //Search the options based on the name or the ID
  const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
        option[searchField]
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
        || option.id?.toString().includes(query)
      );

  const handleSelect = (option) => {
    setSelected(option);
    setValue(registerName, valueField != "" ? option[valueField] : option)
  }

  useEffect(() => {
    if (defaultOption && options.length > 0) {
      setSelected(options[0])
      setValue(registerName, options[0])
    }

    if (initialValue) {
      let initialOption = options.find(option => option[searchField] === initialValue || option.id === initialValue || option[valueField] === initialValue)
      setSelected(initialOption)

      //If the valueField is empty, the value will be the option object
      if (valueField === "") setValue(registerName, initialOption)
      else setValue(registerName, initialOption[valueField])
    }
  }, [])

  return (
    <div>
      {label &&
        <label>
          <span className="text-sm font-medium text-gray-500">{label}</span>
        </label>
      }
      <Combobox value={selected} onChange={handleSelect}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden bg-white text-left sm:text-sm ">
            <ComboboxInput
              className="w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 border-1 border-gray-400"
              displayValue={(option) => option ? option[searchField] : ""}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <ComboboxOptions className="absolute z-30 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base rounded-b-md ring-1 ring-gray-400 ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOptions.length === 0 && query !== '' ? (
                <div key={0} className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredOptions.slice(0, 100).map((option) => (
                  <ComboboxOption
                    key={option.id ?? option[searchField]}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primary-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                          {option[searchField]}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-primary-600'
                              }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ComboboxOption>
                ))
              )}
            </ComboboxOptions>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}