import { Fragment, useState } from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import Avatar from "../user/Avatar";
import { IWilmaUser } from "../../models/auth/WilmaUser";

const colHelper = createColumnHelper<IWilmaUser>();

const cols = [
  colHelper.display({
    id: "name",
    header: "Name",
    sortingFn: "auto",
    cell: (info) => (
      <span className="flex  ">
        <Avatar userId={info.row.original.id} classes="h-9 w-9" />
        <span className="ml-4 m-auto">
          {info.row.original.forename} {info.row.original.surname}
        </span>
      </span>
    ),
  }),
  colHelper.accessor("email", {
    header: "Email",
    enableSorting: false,
  }),
];

export default function StaffTable({
  staff,
  setStaff,
}: {
  staff: IWilmaUser[];
  setStaff: any;
}) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: staff,
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <>
      <div className="mt-4 bg-white overflow-auto">
        <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-primary-50 text-gray-800">
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " ▲",
                          desc: " ▼",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              let rowCells = row.getVisibleCells();
              return (
                <Fragment key={i}>
                  <tr
                    key={row.id}
                    onClick={() => setStaff(row.original)}
                    className={"hover:bg-gray-100 hover:cursor-pointer"}
                  >
                    {rowCells.map((cell) => (
                      <td key={cell.id} className="px-3 py-3 border text-sm">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <></>
                    // <tr>
                    //   {/* 2nd row is a custom 1 cell row */}
                    //   <td colSpan={row.getVisibleCells().length}>
                    //     <LeadsTableSubComponent lead={row.original} />
                    //   </td>
                    // </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
