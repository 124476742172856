import { createColumnHelper, flexRender, getCoreRowModel, getExpandedRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { IEbayMessage } from "../../models/EbayMessage";
import { Fragment, useState } from "react";
import formatDateTime from './../../utils/formatDateTime';
import { DateTime } from "luxon";
import EbayMessageChain from "./EbayMessageChain";
import classNames from "../../utils/classNames";

const colHelper = createColumnHelper<IEbayMessage>();

const cols = [
  colHelper.accessor("receiveDate", {
    header: "Received At",
    sortingFn: "auto",
    cell: (info) =>
      <div>
        <p className="whitespace-nowrap">{formatDateTime(info.getValue(), DateTime.DATETIME_SHORT)}</p>
        <p className="text-sm text-gray-600">{DateTime.fromISO(info.getValue()).toRelative()}</p>

      </div>
  }),
  colHelper.accessor("sendingUserID", {
    header: "Sender",
    enableSorting: false,
    cell: (info) => <span>{info.getValue() == "-99" ? "gpmetalsandspares" : info.getValue()}</span>,
  }),
  colHelper.accessor("itemTitle", {
    header: "Listing",
    enableSorting: false,
  }),
  colHelper.accessor("body", {
    header: "Text",
    enableSorting: false,
    cell: (info) => <span className="whitespace-pre-line">{info.getValue()}</span>,
  }),
  colHelper.accessor("read", {
    header: "Read",
    enableSorting: false,
    cell: (info) => <span className="flex items-center justify-center">{info.getValue() === "True" ? "✔" : ""}</span>,
  }),
];


export default function EbayMessageTable({ messages }: { messages: IEbayMessage[] | undefined }) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [chosenMessage, setChosenMessage] = useState<IEbayMessage | undefined>();
  const [chainOpen, setChainOpen] = useState(false);

  const table = useReactTable({
    data: messages ? messages : [],
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleSelectMessage = (message: IEbayMessage) => {
    setChosenMessage(message);
    setChainOpen(true);
  };

  return (
    <>
      <EbayMessageChain open={chainOpen} setOpen={setChainOpen} originMessage={chosenMessage} />

      <div className="mt-4 space-y-4 overflow-auto">
        <table className="min-w-full bg-white overflow-scroll divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-primary-50 text-gray-800">
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                  >
                    {header.isPlaceholder
                      ? null
                      : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' ▲',
                            desc: ' ▼',
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>

                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              let rowCells = row.getVisibleCells();
              return (
                <Fragment key={i}>
                  <tr
                    key={row.id}
                    onClick={() => handleSelectMessage(row.original)}
                    className={classNames(row.original.read === "True" ? "" : "bg-blue-50", "hover:bg-gray-100 hover:cursor-pointer")}
                  >
                    {rowCells.map((cell) => (
                      <td key={cell.id} className="px-3 py-3 border text-sm">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <></>
                    // <tr>
                    //   {/* 2nd row is a custom 1 cell row */}
                    //   <td colSpan={row.getVisibleCells().length}>
                    //     <LeadsTableSubComponent lead={row.original} />
                    //   </td>
                    // </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

    </>
  )
}