import { CheckCircleIcon } from "@heroicons/react/24/outline";

import classNames from "./../../../utils/classNames";
import { IShippingQuote } from "../../../models/Shipping";

interface ShippingQuoteTableProps {
  shippingQuotes: IShippingQuote[];
  onServiceSelect: (service: string) => void;
  selectedService?: string;
}

export function ShippingQuoteTable({
  shippingQuotes,
  onServiceSelect,
  selectedService,
}: ShippingQuoteTableProps) {
  return (
    <table className="divide-y border divide-gray-300">
      <thead>
        <tr className="bg-primary-50 text-gray-800">
          <th className="py-1.5 px-2 text-sm font-semibold lg:table-cell text-left"></th>
          <th className="py-1.5 px-2 text-sm font-semibold lg:table-cell text-left">
            Service
          </th>
          <th className="py-1.5 px-2 text-sm font-semibold lg:table-cell text-left">
            Price
          </th>
        </tr>
      </thead>
      <tbody>
        {shippingQuotes?.map((sq) => {
          let selected = sq.service === selectedService;

          return (
            <tr
              onClick={() => onServiceSelect(sq.service)}
              className={classNames(
                "cursor-pointer hover:bg-gray-50",
                selected ? "bg-gray-100" : ""
              )}
            >
              <td width="5%" className="px-3 py-1.5 border text-sm">
                <CheckCircleIcon
                  height={24}
                  className={selected ? "text-green-500" : "text-gray-400"}
                />
              </td>
              <td width="85%" className="px-3 py-1.5 border text-sm">
                {sq.name}
              </td>
              <td className="px-3 py-1.5 border text-sm">£{sq.price}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
