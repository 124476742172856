import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useApiHelper from "../hooks/useApiHelper";
import { IPartLookupResponse } from "../models/PartLookup";

export default function usePartsLookupMutations(partId: string) {
  const { get, post, put } = useApiHelper();
  const queryClient = useQueryClient();
  const staleTime = 1000 * 60 * 60 * 4; //4 hours

  const lookupMutation = useMutation(
    () => get<IPartLookupResponse>(`/partlookup/part/${partId}`),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["partslookup", "part", partId], data);
      },
    }
  );

  return {
    lookupMutation
  };
}
