import { useEffect, useRef, useState } from "react";

import { ChevronLeftIcon, ChevronRightIcon, PhotoIcon, PlayPauseIcon } from "@heroicons/react/24/outline";

import useLeadDocuments from "../../../data/useLeadDocuments";
import Modal from "../../../layouts/Modal";
import { ButtonInput } from "../Inputs/ButtonInput";
import classNames from './../../../utils/classNames';


//Images need to come in as any because we have IDocument and IPartImage

export default function ImageCarousel({ images, part = false, leadId, size = "w-64 h-64" }: { images: any, part?: boolean, leadId?: string, size?: string }) {
  const { leadDocuments, downloadDoc } = useLeadDocuments(leadId ? leadId : "");
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [preview, setPreview] = useState("");
  const [previewDoc, setPreviewDoc] = useState<any>(undefined);
  const [isVideo, setIsVideo] = useState(false);

  const handlePreview = (doc: any) => {
    //Part doesn't need to be downloaded
    setPreviewDoc(doc);

    if (part) {
      setPreview(doc.url);
      setPreviewOpen(true);
      return;
    }

    var ids = {
      leadId: leadId,
      docId: doc.id
    }

    downloadDoc.mutate(ids, {
      onSuccess: (file: any) => {
        const url = window.URL.createObjectURL(file);

        if (file.type.includes("image")) {
          setIsVideo(false);
        } else if (file.type.includes("video")) {
          setIsVideo(true);
        }

        setPreview(url);
        setPreviewOpen(true);
      },
      onError: (error: any) => {
        console.error(error);
        setPreviewDoc(undefined);
      }
    })
  };

  const handleMovePreview = (direction: string) => {
    //get current index of the preview image
    const index = images.findIndex((doc: any) => doc.id === previewDoc.id);

    console.log(index, direction)

    //if the direction is prev and the index is greater than 0, move to the previous image
    if (direction === "prev") {
      if (index > 0) {
        handlePreview(images[index - 1]);
      }
      //if the direction is next and the index is less than the length of the images array, move to the next image
    } else {
      if (index < images.length - 1) {
        handlePreview(images[index + 1]);
      }
    }
  }

  const previewDisabled = (direction: string) => {
    if (!previewOpen) return;

    const index = images.findIndex((doc: any) => doc.id === previewDoc.id);

    if (direction === "prev") {
      return index <= 0;
    }

    if (direction === "next") {
      return index >= images.length - 1;
    }

    return false;
  };

  const carousel = useRef<HTMLDivElement>(null);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction: string) => {
    if (direction === "prev") {
      return currentIndex <= 0;
    }

    if (direction === "next" && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };


  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, [maxScrollWidth.current, carousel.current, carousel.current?.scrollWidth, carousel.current?.offsetWidth]);


  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (previewOpen) {
        // Check if left arrow key is pressed
        if (event.key === "ArrowLeft") {
          handleMovePreview("prev");
        }
        // Check if right arrow key is pressed
        else if (event.key === "ArrowRight") {
          handleMovePreview("next");
        }
      }
    };

    // Add event listener
    window.addEventListener("keydown", handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [previewOpen, previewDoc]);

  const arrowCss = "hover:bg-primary-600/75 text-white w-10 h-full text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300";

  return (
    <>
      <Modal open={previewOpen} setOpen={setPreviewOpen} width="max-w-4xl">
        {/* padding bottom and right matches the padding + margins of the modal */}
        <div className="flex justify-between absolute top left w-full h-full pb-12 pr-12 ">
          <button
            onClick={() => handleMovePreview("prev")}
            className={`${arrowCss}`}
            disabled={previewDisabled("prev")}
          >
            <ChevronLeftIcon className="w-10" />
            <span className="sr-only">Prev</span>
          </button>
          <button
            onClick={() => handleMovePreview("next")}
            className={`${arrowCss}`}
            disabled={previewDisabled("next")}
          >
            <ChevronRightIcon className="w-10 " />
            <span className="sr-only">Next</span>
          </button>
        </div>
        <div className="flex justify-center">
          {isVideo ? (
            <video width="1024" height="576" controls>
              <source src={preview} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <img src={preview} alt="preview" className="w-full" />
          )}
        </div>
      </Modal>

      <div className="carousel mx-auto">
        <div className="relative overflow-hidden">
          <div className="flex justify-between absolute top left w-full h-full">
            <button
              onClick={movePrev}
              className={`${arrowCss}`}
              disabled={isDisabled("prev")}
            >
              <ChevronLeftIcon className="w-10" />
              <span className="sr-only">Prev</span>
            </button>
            <button
              onClick={moveNext}
              className={`${arrowCss}`}
              disabled={isDisabled("next")}
            >
              <ChevronRightIcon className="w-10" />
              <span className="sr-only">Next</span>
            </button>
          </div>
          <div
            ref={carousel}
            className="carousel-container relative flex gap-2 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0"
          >
            {images?.map((doc: any, i: number) => {
              return (
                <div
                  key={i}
                  className={classNames(size, "carousel-item text-center relative snap-start")}
                >
                  <div
                    className="w-full h-full flex items-center justify-center border border-slate-500 rounded-md shadow-md cursor-pointer aspect-square z-0 hover:bg-gray-200"
                    onClick={() => handlePreview(doc)}
                  >
                    {part ? (
                      //Part image is a url
                      <img src={doc.url} alt={doc.fileName!} className="object-contain w-full h-full" />
                    ) : (
                      //Lead documents are a base64 string
                      doc.thumbnail ? (
                        <img src={`data:${doc.contentType};base64, ${doc.thumbnail!} `} alt={doc.fileName!} className="object-contain w-full h-full" />
                      )
                        :
                        //Default icons
                        (doc.contentType?.includes("video") ?
                          (<PlayPauseIcon className="w-2/3 h-2/3 rounded-full text-primary-600 border-4 border-primary-600 p-3 object-contain" />) :
                          (<PhotoIcon className="w-2/3 rounded-sm object-contain" />)
                        )
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  )
}