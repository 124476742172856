import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import SendText from "./SendText";
import SendEmail from "./SendEmail";
import Card from "../shared/Card/Card";
import Modal from "../../layouts/Modal";
import { ILead } from "../../models/Lead";
import useEmails from "../../data/useEmails";
import classNames from "../../utils/classNames";
import { ContactType } from "../../models/Contact";
import useSingleLead from "../../data/useSingleLead";
import { SendEmailRequest } from "../../models/Email";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import useContactSingle from "../../data/useContactsSingle";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";

type FormValues = {
  fullname: string;
  contactType: ContactType;
  telephone1: string;
  telephone2: string;
  email: string;
  line1: string;
  line2: string;
  town: string;
  county: string;
  postcode: string;
};

export default function LeadContactDetails({ lead }: { lead: ILead }) {
  const { lead: singleLead } = useSingleLead(lead.id);
  const { update } = useContactSingle(lead.contact?.id!);
  const { sendEmail } = useEmails();
  const [emailOpen, setEmailOpen] = useState(false);
  const [textOpen, setTextOpen] = useState(false);
  const [requestOpen, setRequestOpen] = useState(false);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const { saveHandlers } = useDefaultCRUDHandlers("Contact details");
  const { register, handleSubmit, reset } =
    useForm<FormValues>({
      defaultValues: {
        fullname: lead.contact?.fullname?.trim() ?? "",
        contactType: lead.contact?.contactType,
        telephone1: lead.contact?.telephone1,
        telephone2: lead.contact?.telephone2,
        email: lead.contact?.email,
        line1: lead.contact?.line1 ? lead.contact?.line1?.trim() : "",
        line2: lead.contact?.line2,
        town: lead.contact?.town,
        county: lead.contact?.county,
        postcode: lead.contact?.postcode,
      },
    });

  const contactTypes = [...Object.values(ContactType).filter((ct) => isNaN(Number(ct)))];

  const onSubmit = (data: any) => {
    const updatedContact = {
      ...lead.contact,
      ...data,
      contactType: parseInt(data.contactType)
    };

    console.log(updatedContact);
    update.mutate(updatedContact, saveHandlers);

    reset({
      fullname: data.fullname,
      contactType: parseInt(data.contactType),
      telephone1: data.telephone1,
      telephone2: data.additionalTelephone,
      email: data.email,
      line1: data.line1,
      line2: data.line2,
      town: data.town,
      county: data.county,
      postcode: data.postcode,
    });
  }

  const handleRequestDocs = () => {
    //Send email to customer
    console.log("Requesting docs")
    setIsRequestLoading(true);


    //Create email object
    const email: SendEmailRequest = {
      contactId: lead.contact?.id!,
      leadId: lead.id,
      recipient: lead.contact?.email!,
      subject: "Please upload your documents",
      body: null,
      templateId: "3748E3DE-1AE9-4051-84F4-08DB2AD19047",
      substitutions: {
        "{{name}}": lead.contact?.fullname!,
        "{{vrm}}": lead.vehicle?.vrm!,
      },
    }

    //Send to API
    sendEmail.mutate(email, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        setRequestOpen(false);
      },
      onError: (error) => {
        console.log("Error: ", error);
        saveHandlers.onError();
      },
      onSettled: () => {
        setIsRequestLoading(false);
      }
    })
  }

  useEffect(() => {

  }, [lead, lead.contact])

  return (
    <>
      <ConfirmModal open={requestOpen} setOpen={setRequestOpen} onConfirm={handleRequestDocs} isLoading={isRequestLoading}
        title="Request documents from customer?"
        message="This will send an email to the customer requesting they upload their documents and update their payment information."
        confirmButtonText={"Request documents"}
      />
      <Modal open={emailOpen} setOpen={setEmailOpen} width="max-w-7xl max-h-[75vh]" >
        <label>Send an email to the customer</label>
        <SendEmail lead={lead} setOpen={setEmailOpen} />
      </Modal>
      <Modal open={textOpen} setOpen={setTextOpen} width="max-w-7xl" >
        <label>Send an SMS to the customer</label>
        <SendText lead={lead} setOpen={setTextOpen} />
      </Modal>
      <Card title="Contact Details" bodyClassName="p-4">

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">

          <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1 pr-4">
              <label className="text-sm font-medium text-gray-500">Name</label>
              <input {...register("fullname")} type="text" className="w-full mt-1 text-sm text-gray-900 " />
            </div>
            <div className="sm:col-span-1 pr-4">
              <label className="text-sm font-medium text-gray-500">Email Address</label>
              <input {...register("email")} type="text" className="w-full mt-1 text-sm text-gray-900" />
            </div>
          </div>

          {/* <hr /> */}

          <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1 pr-4">
              <label className="text-sm font-medium text-gray-500">Phone Number</label>
              <input {...register("telephone1")} type="text" className="w-full mt-1 text-sm text-gray-900" />
            </div>
            <div className="sm:col-span-1 pr-4">
              <label className="text-sm font-medium text-gray-500">Additional Number</label>
              <input {...register("telephone2")} type="text" className="w-full mt-1 text-sm text-gray-900" />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-8 sm:grid-cols-2">
            {/* <div className="sm:col-span-1 pr-4">
              <label className="text-sm font-medium text-gray-500">Postcode</label>
              <input {...register("postcode")} type="text" className="w-full mt-1 text-sm text-gray-900" />
            </div> */}
            <div className="sm:col-span-1 pr-4">
              <label className="text-sm font-medium text-gray-500">Contact Type</label>
              <select {...register("contactType")} className="w-full mt-1 text-sm text-gray-900">
                {contactTypes.map((ct, i) => (
                  <option key={ct} value={i}>
                    {ct}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* <div className="gap-x-4 gap-y-4 sm:gap-y-8">
            <div>
              <dt className="text-sm font-medium text-gray-500">Full Address</dt>
              <dd className="mt-1 text-sm text-gray-900">{getAddress(lead.contact)}</dd>
            </div>
          </div> */}


          <button
            className="mr-5 rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-700"
            type="submit"
          >
            Update
          </button>
        </form>


        <hr className="mt-4" />

        <div className="mt-5 flex justify-between">

          <div className="flex">
            <button
              className="mr-5 rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-700"
              onClick={() => setEmailOpen(true)}
              type="button"
            >
              Send Email
            </button>

            <button
              className="rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-primary-700"
              onClick={() => setTextOpen(true)}
              type="button"
            >
              Send SMS
            </button>
          </div>

          <div className="">

            <button
              className={classNames(singleLead?.data?.data?.documentsRequested ? "bg-green-600 hover:bg-green-700" : "bg-primary-600 hover:bg-primary-700", "rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm")}
              onClick={() => setRequestOpen(true)}
              type="button"
            >
              Request Customer Docs
            </button>
          </div>

        </div>
      </Card>
    </>
  );
}
