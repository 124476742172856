import { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";

import {
  ArchiveBoxArrowDownIcon,
  ArchiveBoxIcon,
  ArrowUturnUpIcon,
  BanknotesIcon,
  BriefcaseIcon,
  CalendarDaysIcon,
  CameraIcon,
  ChatBubbleLeftEllipsisIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  Cog6ToothIcon,
  CurrencyPoundIcon,
  DocumentMagnifyingGlassIcon,
  DocumentTextIcon,
  FolderPlusIcon,
  HomeIcon,
  InboxStackIcon,
  MagnifyingGlassPlusIcon,
  NewspaperIcon,
  TagIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";

import Topbar from "../components/shared/Navbar/Topbar";
import LoadingWheel from "../components/shared/LoadingWheel";
import { NavigationItemInterface } from "../types/Navigation";
import { usePageNameContext } from "../contexts/PageTitleContext";
import MobileSidebar from "../components/shared/Navbar/MobileSidebar";
import DesktopSidebar from "../components/shared/Navbar/DesktopSidebar";
import { useAuth } from "../contexts/AuthContext";
import useCustomerDetails from "../data/useCustomerDetails";
import { GetFullBrandResponse } from "../models/Customer";
import { wilmaSSOAppRegistration, wilmaSSODashboardUrl } from "../config";

export default function DashboardLayout() {
  const {
    pageInfo: { name, desc, fill },
  } = usePageNameContext();
  const { loading, loggedIn, claims } = useAuth();
  const { brand, features } = useCustomerDetails();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<string[] | undefined>(undefined);

  const tailwindShades = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

  // Function to create/update favicon
  const updateIcon = (rel: string, size: string | null, href: string) => {
    let selector = size ? `link[rel='${rel}'][sizes='${size}']` : `link[rel='${rel}']`;
    let link = document.querySelector(selector) as HTMLLinkElement;
    if (!link) {
      link = document.createElement("link");
      link.rel = rel;
      if (size) link.setAttribute("sizes", size);
      document.head.appendChild(link);
    }
    link.href = `${href}?v=${new Date().getTime()}`;
  };

  const handleUpdateIcons = (brand: GetFullBrandResponse) => {
    const { faviconUrl, favicon16Url, favicon32Url, appleTouchIconUrl, androidChrome192Url, androidChrome512Url } = brand;

    //favicon
    updateIcon("icon", "16x16", favicon16Url);
    updateIcon("icon", "32x32", favicon32Url);
    updateIcon("icon", null, faviconUrl);
    updateIcon("shortcut icon", null, faviconUrl);

    if (appleTouchIconUrl)
      updateIcon("apple-touch-icon", null, appleTouchIconUrl);

    if (androidChrome192Url)
      updateIcon("icon", "192x192", androidChrome192Url);

    if (androidChrome512Url)
      updateIcon("icon", "512x512", androidChrome512Url);

  }

  useEffect(() => {
    if (!brand.isLoading && !features.isLoading) {

      if (brand.data) {
        // Set the brand colours
        tailwindShades.forEach((shade) => {
          document.documentElement.style.setProperty(`--primary-${shade}`, brand.data.data.primaryTailwindColours[shade]);
        });

        handleUpdateIcons(brand.data.data);
      }

      if (!features.data) {
        console.log("no feature data")
        setIsLoading(false);
        return;
      }

      const flags = features.data.data.map((feature) => feature.name);
      setFeatureFlags(flags);
      setIsLoading(false);
    }
  }, [brand.data, features.data]);

  if (!loading && !loggedIn) {
    window.location.assign(
      `${wilmaSSODashboardUrl}?ari=${wilmaSSOAppRegistration}&returnUrl=${window.location.href}`
    );
  }

  if (isLoading || featureFlags === undefined) {
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <LoadingWheel />
      </div>
    );
  }

  const staffNavigation: NavigationItemInterface[] = [
    { name: "Dashboard", to: "/", icon: HomeIcon },

    //** Leads **/
    { name: "Leads", to: "leads", icon: FolderPlusIcon, group: "Leads", disabled: !featureFlags.includes("Lead Management") },
    { name: "Collected", to: "vehicles-collected", icon: ArchiveBoxIcon, group: "Leads", disabled: !featureFlags.includes("Lead Management") },
    //{ name: "Calendar", to: "calendar", icon: CalendarDaysIcon },
    { name: "Trip Manager", to: "trip-manager", icon: CalendarDaysIcon, group: "Leads", disabled: !featureFlags.includes("Lead Management") },
    { name: "Schedule", to: "schedule", icon: NewspaperIcon, group: "Leads", disabled: !featureFlags.includes("Lead Management") },

    //** Payments **/
    { name: "Payments", to: "payments", icon: BanknotesIcon, group: "Leads", disabled: !featureFlags.includes("Customer Payments") },

    //** Parts **/
    { name: "Awaiting Inventory", to: "parts-manager", icon: WrenchScrewdriverIcon, group: "Parts", disabled: !featureFlags.includes("Parts Tracker") },
    { name: "Awaiting Arrival", to: "awaiting-arrival", icon: ClockIcon, group: "Parts", disabled: !featureFlags.includes("Lead Management") },
    { name: "Parts Checker", to: "parts-checker", icon: ClipboardDocumentCheckIcon, group: "Parts", disabled: !featureFlags.includes("Parts Tracker") },
    { name: "Jobs", to: "jobs", icon: BriefcaseIcon, group: "Parts", disabled: !featureFlags.includes("Parts Tracker") },
    { name: "Stock", to: "stock", icon: InboxStackIcon, group: "Parts", disabled: !featureFlags.includes("Parts Tracker") },

    //** eBay **/
    { name: "eBay Unlisted", to: "ebay-unlisted", icon: DocumentTextIcon, group: "eBay", disabled: !featureFlags.includes("eBay Management") },
    { name: "eBay Listings", to: "ebay-listings", icon: CurrencyPoundIcon, group: "eBay", disabled: !featureFlags.includes("eBay Management") },
    { name: "eBay Orders", to: "ebay-orders", icon: ArchiveBoxArrowDownIcon, group: "eBay", disabled: !featureFlags.includes("eBay Management") },
    { name: "eBay Returns", to: "ebay-returns", icon: ArrowUturnUpIcon, group: "eBay", disabled: !featureFlags.includes("eBay Management") },

    //** Widget **/
    { name: "Dashboard", to: "widget-dashboard", icon: HomeIcon, group: "Widget" },
    { name: "Postcodes", to: "widget-postcodes", icon: DocumentMagnifyingGlassIcon, group: "Widget" },
    { name: "Configuration", to: "widget-config", icon: Cog6ToothIcon, group: "Widget" },
    // { name: "Vehicles", to: "vehicles", icon: TruckIcon },
    // { name: "Contacts", to: "contacts", icon: UsersIcon },
  ];

  const driverNavigation: NavigationItemInterface[] = [
    { name: "Find Lead", to: "find-lead", icon: DocumentMagnifyingGlassIcon },
  ];

  const adminNavigation: NavigationItemInterface[] = [
    { name: "Dashboard", to: "/", icon: HomeIcon },

    //** Leads **/
    { name: "Leads", to: "leads", icon: FolderPlusIcon, group: "Leads", disabled: !featureFlags.includes("Lead Management") },
    { name: "Trip Manager", to: "trip-manager", icon: CalendarDaysIcon, group: "Leads", disabled: !featureFlags.includes("Lead Management") },
    { name: "Schedule", to: "schedule", icon: NewspaperIcon, group: "Leads", disabled: !featureFlags.includes("Lead Management") },
    { name: "Awaiting Arrival", to: "awaiting-arrival", icon: ClockIcon, group: "Leads", disabled: !featureFlags.includes("Lead Management") },
    { name: "Collected", to: "vehicles-collected", icon: ArchiveBoxIcon, group: "Leads", disabled: !featureFlags.includes("Lead Management") },
    //{ name: "Calendar", to: "calendar", icon: CalendarDaysIcon },

    //** Payments **/
    { name: "Payments", to: "payments", icon: BanknotesIcon, group: "Leads", disabled: !featureFlags.includes("Customer Payments") },

    //** Parts **/
    { name: "Awaiting Inventory", to: "parts-manager", icon: WrenchScrewdriverIcon, group: "Parts", disabled: !featureFlags.includes("Parts Tracker") },
    { name: "Parts Checker", to: "parts-checker", icon: ClipboardDocumentCheckIcon, group: "Parts", disabled: !featureFlags.includes("Parts Tracker") },
    { name: "Jobs", to: "jobs", icon: BriefcaseIcon, group: "Parts", disabled: !featureFlags.includes("Parts Tracker") },
    { name: "Stock", to: "stock", icon: InboxStackIcon, group: "Parts", disabled: !featureFlags.includes("Parts Tracker") },

    //** eBay **/
    { name: "eBay Unlisted", to: "ebay-unlisted", icon: DocumentTextIcon, group: "eBay", disabled: !featureFlags.includes("eBay Management") },
    { name: "eBay Listings", to: "ebay-listings", icon: CurrencyPoundIcon, group: "eBay", disabled: !featureFlags.includes("eBay Management") },
    { name: "eBay Orders", to: "ebay-orders", icon: ArchiveBoxArrowDownIcon, group: "eBay", disabled: !featureFlags.includes("eBay Management") },
    { name: "eBay Returns", to: "ebay-returns", icon: ArrowUturnUpIcon, group: "eBay", disabled: !featureFlags.includes("eBay Management") },

    //** Widget **/
    { name: "Dashboard", to: "widget-dashboard", icon: HomeIcon, group: "Widget" },
    { name: "Postcodes", to: "widget-postcodes", icon: DocumentMagnifyingGlassIcon, group: "Widget" },
    { name: "Configuration", to: "widget-config", icon: Cog6ToothIcon, group: "Widget" },

    //** Admin **/
    { name: "Find Lead", to: "find-lead", icon: DocumentMagnifyingGlassIcon, group: "Admin" },
    { name: "Category Editor", to: "category-editor", icon: TagIcon, group: "Admin" },
    { name: "Required Images", to: "category-images", icon: CameraIcon, group: "Admin" },
    { name: "Driver Tracking", to: "driver-tracking", icon: MagnifyingGlassPlusIcon, group: "Admin", disabled: !featureFlags.includes("Driver Tracking") },
    { name: "Admin Settings", to: "admin", icon: Cog6ToothIcon, group: "Admin" },
    // { name: "Vehicles", to: "vehicles", icon: TruckIcon },
    // { name: "Contacts", to: "contacts", icon: UsersIcon },
  ];

  //Get the url and check if we're at a disabled page and redirect to home
  const url = window.location.pathname.replace("/", "");
  const splits = url.split("/");

  const page = adminNavigation.concat(staffNavigation).concat(driverNavigation).find((nav) => splits.includes(nav.to));
  if (page && page.disabled) {
    window.location.href = "/";
  };


  const getNavigation = () => {
    if (claims) {
      if (claims.isAdmin) {
        return adminNavigation;
      } else if (claims.isStaff) {
        return staffNavigation;
      } else if (claims.isDriver) {
        return driverNavigation;
      }
    }

    return [];
  };

  return loading ? (
    <div className="h-screen w-screen flex items-center justify-center">
      <LoadingWheel />
    </div>
  ) : (
    <div>
      <MobileSidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        navigation={getNavigation()}
      />
      <DesktopSidebar navigation={getNavigation()} />
      <div className="flex flex-1 flex-col md:pl-60">
        <Topbar setSidebarOpen={setSidebarOpen} />
        <main>
          <div className={`${!fill && "py-6"}`}>
            {!fill &&
              <div className="mt-2 px-4 sm:px-6 md:pl-20 prose">
                <h1 className="font-semibold text-gray-900 mb-0">{name}</h1>
                <h4 className="text-base font-medium text-gray-500 mt-1">
                  {desc}
                </h4>
              </div>
            }
            <div className={`${!fill && "px-4 sm:px-6 md:px-20"}`}>
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
