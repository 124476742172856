import { useState } from "react";

import usePartsLookupMutations from "../../../data/usePartsLookupMutations";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { IPart } from "../../../models/Part";
import PriceFormat from "../../../utils/priceFormat";

export default function SuggestedPrice({ part }: { part: IPart }) {
  const { saveHandlers } = useDefaultCRUDHandlers("Part Lookup");
  const { lookupMutation } = usePartsLookupMutations(part.id);
  const [isLoading, setIsLoading] = useState(false);

  const handleLookup = () => {
    setIsLoading(true);

    lookupMutation.mutate(undefined, {
      ...saveHandlers,
      onSuccess: (data) => {
        // console.log(data.data.requestedParts)
        part.suggestedPrice = data.data.requestedParts[0]?.priceInformation?.avgPrice;
        if (part.vehicle) part.vehicle.vinvisColourCode = data.data.manufacturerPaintColourCode;

        saveHandlers.onSuccess();
      },
      onSettled: () => setIsLoading(false)
    });
  }

  return (
    <span onClick={() => handleLookup()} className="text-sm text-blue-600 rounded-full bg-blue-200 ml-2 p-1 px-2 cursor-pointer hover:bg-blue-300">
      {isLoading ? "Loading..." : (
        <span>Suggested: {part.suggestedPrice && part.suggestedPrice !== 0 ? PriceFormat(part.suggestedPrice) : "N/A"}</span>
      )}
    </span>
  )
}