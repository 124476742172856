import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';

const CustomJoditEditor = ({ content, setContent, height, placeholder = "Start typing..." }) => {

  const editor = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentEditor, setCurrentEditor] = useState(null);
  // const [content, setContent] = useState('');

  const buttonLayout = ["bold", "italic", "underline", "strikethrough", "eraser", "|", "ul", "ol", "font", "fontsize", "paragraph", "classSpan", "indent", "outdent", "hr",
    "\n", "spellcheck", "cut", "copy", "paste", "|", "selectall", "table", "link", "symbols", "undo", "redo", "find", "|", "print", "fullsize", "source", "preview",
  ]

  const config = useMemo(() => {
    return {
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      placeholder: placeholder,
      spellCheck: true,
      minHeight: height,
      buttons: buttonLayout,
      buttonsXS: buttonLayout,
      buttonsSM: buttonLayout,
      buttonsMD: buttonLayout,
      buttonsLG: buttonLayout,
      enableDragAndDropFileToEditor: true,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false
    }
  }, []);

  // useEffect(() => {
  //   console.log("content", content)
  // }, [content])

  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      tabIndex={1} // tabIndex of textarea
      onBlur={newContent => setContent(newContent)}
      // onChange={newContent => setContent(newContent)}
    />
  );
};


export default CustomJoditEditor;