import {
  createContext,
  Dispatch,
  ReactElement,
  useContext,
  useEffect,
  useReducer,
} from "react";

import { jwtDecode } from "jwt-decode";

import { useAuthApi } from "../hooks/useAuthApi";
import useSingleSetting from "../data/useSingleSetting";
import { TokenPayload } from "../models/auth/TokenPayload";
import { tokenStorageKey, wilmaSSOAppRegistration, wilmaSSODashboardUrl } from "../config";
import { IWilmaUser } from "../models/auth/WilmaUser";

interface IEmptyAuthContext {
  token?: string;
  userId?: string;
  customerId?: string;
  name?: string;
  forename?: string;
  surname?: string;
  email?: string;
  username?: string;
  hasPin?: boolean;
  claims?: {
    isAdmin: boolean;
    isDriver: boolean;
    isMechanic: boolean;
    isStaff: boolean;
  };
  loggedIn: false;
  loading: boolean;
}

interface IPopulatedAuthContext {
  token: string;
  userId: string;
  customerId: string;
  name: string;
  forename: string;
  surname: string;
  email: string;
  username: string;
  hasPin: boolean;
  claims: {
    isAdmin: boolean;
    isDriver: boolean;
    isMechanic: boolean;
    isStaff: boolean;
  };
  loggedIn: true;
  loading: boolean;
}

type IAuthContext = IEmptyAuthContext | IPopulatedAuthContext;

const initialState: IAuthContext = {
  token: undefined,
  userId: undefined,
  customerId: undefined,
  name: undefined,
  forename: undefined,
  surname: undefined,
  email: undefined,
  username: undefined,
  hasPin: undefined,
  claims: undefined,
  loggedIn: false,
  loading: true,
};

interface AuthContextValue {
  auth: IAuthContext;
  setAuth: Dispatch<AuthContextReducerAction>;
}

export const AuthContext = createContext<AuthContextValue>({
  auth: initialState,
  setAuth: () => { },
});

interface AuthContextProviderProps {
  children: ReactElement | ReactElement[];
}

type AuthContextReducerAction =
  | {
    type: "loading";
    payload: boolean;
  }
  | {
    type: "setUser";
    payload: { token: string; user: IWilmaUser };
  }
  | {
    type: "setToken";
    payload: { token: string };
  }
  | {
    type: "logout";
  };

function authContextReducer(
  state: IAuthContext,
  action: AuthContextReducerAction
): IAuthContext {
  switch (action.type) {
    case "loading":
      let loadingState = {
        ...state,
        loading: action.payload,
      };

      return loadingState;
    case "setUser":
      const { token, user } = action.payload;
      const decodedToken = jwtDecode<TokenPayload>(token);

      let authState = {
        token: action.payload.token,
        userId: decodedToken.userId,
        customerId: decodedToken.customerId,
        name: decodedToken.name,
        forename: user.forename,
        surname: user.surname,
        email: decodedToken.email,
        username: user.username,
        hasPin: user.hasPin,
        claims: {
          isAdmin: decodedToken.isAdmin === "true",
          isDriver: decodedToken.isDriver === "true",
          isMechanic: decodedToken.isMechanic === "true",
          isStaff: decodedToken.isStaff === "true",
        },
        loggedIn: true,
      };

      return {
        ...state,
        ...authState,
      };
    case "setToken":
      return { ...state, token: action.payload.token };
    case "logout":
      console.log("%c [Auth] - Logging out...", "color: #f0c002");
      localStorage.removeItem(tokenStorageKey);
      
      return initialState;
    default:
      return state;
  }
}

export function AuthProvider({ children }: AuthContextProviderProps) {
  const { getToken, getUser } = useAuthApi();
  // const { singleSetting } = useSingleSetting("Wilma:BettyApplicationToken");
  const [auth, setAuth] = useReducer(authContextReducer, initialState);
  let refreshInterval: NodeJS.Timeout;

  const refreshToken = async () => {
    console.log("%c [Auth] - Refreshing token...", "color: #f0c002");
    try {
      const tokenResult = await getToken();

      if (tokenResult && tokenResult.success) {
        setAuth({
          type: "setToken",
          payload: { token: tokenResult.data.token },
        });
        console.log("%c [Auth] - Token refresh success!", "color: #f0c002");
      } else {
        console.error(
          "%c [Auth] - Token refresh failed - soft fail",
          "color: #f0c002"
        );
        setAuth({ type: "logout" });
      }
    } catch (e) {
      console.error(
        "%c [Auth] - Token refresh failed - hard fail",
        "color: #f0c002"
      );
      console.error(e);
      setAuth({ type: "logout" });
    }
  };

  const sessionRestore = async () => {
    console.log("%c [Auth] - Restoring Session", "color: #f0c002");
    const previouslyAuthed = localStorage.getItem(tokenStorageKey) !== null;
    try {
      const tokenResult = await getToken(previouslyAuthed);

      if (tokenResult && tokenResult.success) {
        const userResult = await getUser(tokenResult.data.token);

        if (userResult && userResult.success) {
          setAuth({
            type: "setUser",
            payload: { token: tokenResult.data.token, user: userResult.data },
          });

          console.log("%c [Auth] - Setting refresh interval", "color: #f0c002");
          refreshInterval = setInterval(() => refreshToken(), 1000 * 60 * 14);
        }
      } else {
        setAuth({ type: "logout" });
      }
    } catch (e) {
      if (previouslyAuthed) {
        setAuth({ type: "logout" });
      } else {
        setAuth({ type: "loading", payload: false });
      }
    } finally {
      setAuth({ type: "loading", payload: false });
    }
  };

  useEffect(() => {
    setAuth({ type: "loading", payload: true });

    sessionRestore();

    return () => {
      console.log("%c [Auth] - Clearing refresh interval", "color: #f0c002");
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const authCtx = useContext(AuthContext);

  if (!authCtx) {
    throw Error("useAuth() must be used within a AuthProvider");
  }

  return {
    ...authCtx.auth,
    dispatchAuthEvent: authCtx.setAuth,
  };
}
