import { useEffect, useState } from "react";

import { useDrop } from "react-dnd"

import { useQueryClient } from "@tanstack/react-query";

import useLeads from "../../data/useLeads";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { DragTypes } from "../../models/DragTypes"
import { ILead } from "../../models/Lead"
import classNames from "../../utils/classNames";
import LeadEvent from "./LeadEvent";

export default function UnbookedEvents(
  { unbookedLeads, selected, handleSelected, year, week }: { unbookedLeads: ILead[], selected?: ILead, handleSelected?: any, year: number, week: number }
) {
  const queryClient = useQueryClient();
  const { undropLead } = useLeads(true);
  const { saveHandlers } = useDefaultCRUDHandlers("Lead");

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: DragTypes.EVENT,
      drop: (e: ILead) => handleDroppedLead(e),
      collect: (monitor) => ({
        isOver: !!monitor.isOver()
      })
    }), []
  )

  const handleDroppedLead = (lead: ILead) => {
    // console.log("dropped lead", lead)

    if (lead === undefined) {
      saveHandlers.onError();
      return;
    }

    undropLead.mutate(lead.id, {
      onSuccess: () => {
        saveHandlers.onSuccess();
        handleSelected(undefined);
        setTimeout(() => {
          queryClient.invalidateQueries(["trips", year, week]);
        }, 200);
      },
      onError: saveHandlers.onError
    });
  };

  const handleColour = () => {
    let background = "";

    if (isOver) background = "bg-primary-200";

    return background;
  }

  useEffect(() => {

  }, [unbookedLeads])

  return (
    <div className={classNames(handleColour(), " w-full p-1 h-full min-h-[40px] rounded-md")}
      ref={drop}
      style={{
        opacity: isOver ? 0.8 : 1,
      }}
    >
      <div className="grid lg:grid-cols-6 md:grid-cols-4 sm:grid-cols-3 grid-cols-2">
        {unbookedLeads?.map((lead: ILead) => {
          return (
            <LeadEvent key={lead.id} lead={lead!} selected={selected} handleSelected={handleSelected} inGroup={undefined} handleOrderChange={undefined} />
          )
        })}
      </div>
    </div>
  )
}