import { IWarehouseLocation } from "../models/Location";
import { IPart } from './../models/Part';

const warehouseTemplate = '<html><head><meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,minimum-scale=1,user-scalable=no"></head><body style="margin:0;padding:0"><div style="width:10cm;height:5cm;display:grid;grid-template-columns:repeat(3,minmax(0,1fr));column-gap:.25cm"><div style="display:flex;align-items:center"><img style="width:100%" src="[[QRSRC]]"></div><div style="font-family:Arial,Helvetica,sans-serif;font-weight:700;grid-column:span 2/span 2;display:flex;justify-content:center;flex-direction:column"><div style="text-align:center">[[TITLE]]</div><div style="margin-top:.5cm;text-align:center;font-size:20px">[[SUBTITLE]]</div></div></div></body></html>';
const partTemplate = '<html><head><meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,minimum-scale=1,user-scalable=no"></head><body style="margin:0;padding:0"><div style="width:10cm;height:5cm;display:grid;grid-template-columns:repeat(3,minmax(0,1fr));column-gap:.15cm"><div style="display:flex;align-items:center"><img style="width:100%" src="[[QRSRC]]"></div><div style="font-family:Arial,Helvetica,sans-serif;font-weight:700;grid-column:span 2/span 2;display:flex;justify-content:center;flex-direction:column"><div style="text-align:center;font-size:20px">[[TAGNO]]</div><div style="margin-top:.05cm;text-align:center;font-size:16px">[[PARTNO]]</div><div style="margin-top:.2cm;text-align:center">([[VRM]]) [[MAKE]] [[MODEL]]</div><div style="margin-top:.2cm;text-align:center">[[PARTTITLE]]</div></div></div></body></html>';

const toDataURL = async (url: string) => {
  const res = await fetch(url);
  const blob = await res.blob();

  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  });
}

export function useLabelGenerator() {

  const generatePartLabel = async (part: IPart) => {
    const dataUrl = await toDataURL(`https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=${part.id}`)

    let html = partTemplate;

    html = html.replace('[[QRSRC]]', dataUrl as string);
    html = html.replace('[[PARTTITLE]]', part.category?.name ?? part.title ?? "No Title");
    html = html.replace('[[TAGNO]]', part.tagNumber ?? "No Tag No");
    html = html.replace('[[PARTNO]]', part.partNumber ?? "");
    html = html.replace('[[VRM]]', part.vehicle?.vrm ?? "");
    html = html.replace('[[MAKE]]', part.vehicle?.combinedMake ?? "");
    html = html.replace('[[MODEL]]', part.vehicle?.combinedModel ?? "");

    return html;
  };

  const generateWarehouseLabel = async (warehouse: IWarehouseLocation) => {
    const dataUrl = await toDataURL(`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${warehouse.id}`)
    const displayName = warehouse.displayName ?? "No Display Name";

    let html = warehouseTemplate;

    html = html.replace('[[QRSRC]]', dataUrl as string);
    html = html.replace('[[TITLE]]', displayName);
    html = html.replace('[[SUBTITLE]]', "");

    return html;
  };

  const generateLabel = (qrSrc: string, title: string, subtitle: string, isWarehouse: boolean = false) => {
    let html = warehouseTemplate;

    html = html.replace('[[QRSRC]]', qrSrc);
    html = html.replace('[[TITLE]]', title);
    html = html.replace('[[SUBTITLE]]', subtitle);

    return html;
  };

  return { generatePartLabel, generateWarehouseLabel };
}