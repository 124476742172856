import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";

import date from "date-and-time";
import { useNavigate } from "react-router-dom";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import usePayments from "../../data/usePayments";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { ILead, LeadStatus } from "../../models/Lead";
import classNames from "../../utils/classNames";
import PaymentBubble from "../../utils/paymentBubble";
import ContactFromId from "../contacts/ContactFromId";
import ConfirmModal from "../shared/Confirm/ConfirmModal";
import LoadingWheel from "../shared/LoadingWheel";
import openInNewTab from "../../utils/openInNewTab";
import CarCollectedImage from "./CarCollectedImage";
import { useAuth } from "../../contexts/AuthContext";

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + "p-3 cursor-pointer"}
      {...rest}
    />
  );
}

//         <button
// onClick={(e) => { e.stopPropagation(); row.toggleExpanded(); }}
// style={{ transform: `rotate(${!row.getIsExpanded() ? "0" : "-270"}deg)` }}
// className="ml-3 p-1 transition duration-500 cursor-pointer border border-gray-400 rounded-full hover:bg-gray-200"
// >
// {/* {row.getIsExpanded() ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronRightIcon className="h-4 w-4"/>} */}
// <ChevronRightIcon className="h-4 w-4" />
// </button>

const colHelper = createColumnHelper<ILead>();

const cols = [
  {
    id: "select",
    header: ({ table }: any) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }: any) => (
      <div
        className="flex justify-center items-center flex-1 p-4"
        onClick={(e) => e.stopPropagation()}
      >
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  },
  colHelper.accessor("contact.fullname", {
    header: "Name",
    sortingFn: "auto",
  }),
  colHelper.accessor("price", {
    header: "Price",
    cell: (price) => <span>£{price.getValue()}</span>,
  }),
  colHelper.accessor("commission", {
    header: "Commission",
    cell: (price) => <span>£{price.getValue()}</span>,
  }),
  colHelper.accessor("vehicle.vrm", {
    header: "VRM",
    enableSorting: false,
  }),
  colHelper.accessor("status", {
    header: "Status",
    enableSorting: false,
    cell: (info) => <span>{LeadStatus[info.getValue()]}</span>,
  }),
  colHelper.display({
    id: "image",
    header: "Car Collected",
    cell: (info) => <CarCollectedImage leadId={info.row.original.id} />,
  }),
  colHelper.accessor("paymentStatus", {
    header: "Payment Status",
    enableSorting: true,
    cell: (info) => <span>{PaymentBubble(info.getValue())}</span>,
  }),
  colHelper.accessor("paymentMessage", {
    header: "Payment Message",
    enableSorting: false,
    cell: (info) => (
      <pre className="whitespace-pre-line">{info.getValue()}</pre>
    ),
  }),
  colHelper.accessor("vehicle.collectedOn", {
    id: "vehicle.collectedOn",
    header: "Collected Date",
    sortingFn: "auto",
    cell: (collected) => (
      <span>
        {collected.getValue() !== null
          ? date.format(new Date(collected.getValue()!), "DD/MM/YYYY")
          : "Not Collected"}
      </span>
    ),
  }),
  colHelper.accessor("paymentRaisedBy", {
    header: "Raised By",
    enableSorting: false,
    cell: (info) => <ContactFromId contactId={info.getValue()!} />,
  }),
  colHelper.accessor("paymentApprovedBy", {
    header: "Approved By",
    enableSorting: false,
    cell: (info) => <ContactFromId contactId={info.getValue()!} />,
  }),
];

export default function PaymentsTable({ payments }: { payments: ILead[] }) {
  const { approvePayments, cancelPayments } = usePayments();
  const { saveHandlers } = useDefaultCRUDHandlers("Leads");
  const { claims, loading } = useAuth();
  const [open, setOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "vehicle.collectedOn", desc: false },
  ]);
  const [isApprovingLoading, setIsApprovingLoading] = useState(false);
  const [isCancellingLoading, setIsCancellingLoading] = useState(false);

  const handleApprovePayments = () => {
    var leadIds = new Array();
    setIsApprovingLoading(true);

    table.getSelectedRowModel().flatRows.forEach((lead) => {
      leadIds.push(lead.original.id);
    });

    const body = {
      leads: leadIds,
    };

    approvePayments.mutate(body, {
      onSuccess: () => {
        table.resetRowSelection();
        saveHandlers.onSuccess();
        setOpen(false);
      },
      onError: saveHandlers.onError,
      onSettled: () => setIsApprovingLoading(false),
    });
  };

  const handleCancelPayments = () => {
    setIsCancellingLoading(true);
    var leadIds = new Array();

    table.getSelectedRowModel().flatRows.forEach((lead) => {
      leadIds.push(lead.original.id);
    });

    const body = {
      leads: leadIds,
    };

    cancelPayments.mutate(body, {
      onSuccess: () => {
        table.resetRowSelection();
        saveHandlers.onSuccess();
        setCancelOpen(false);
      },
      onError: saveHandlers.onError,
      onSettled: () => setIsCancellingLoading(false),
    });
  };

  const table = useReactTable({
    data: payments,
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (loading) {
    return (
      <div className="pt-[15vh]">
        <LoadingWheel />
      </div>
    );
  } else {
    return (
      <>
        <ConfirmModal
          open={open}
          setOpen={setOpen}
          title="Approve Payments?"
          confirmButtonText="Approve"
          message={`Are you sure you would like to approve these ${
            table.getSelectedRowModel().flatRows.length
          } payments? \n 
        This will automatically approve them in Telleroo.`}
          isLoading={isApprovingLoading}
          onConfirm={handleApprovePayments}
        />
        <ConfirmModal
          open={cancelOpen}
          setOpen={setCancelOpen}
          title="Cancel Payments?"
          confirmButtonText="Cancel Payments"
          message={`Are you sure you would like to cancel these ${
            table.getSelectedRowModel().flatRows.length
          } payments? \n 
        This will remove them from this table, and try to cancel them in Telleroo if they are still Approved and not yet Sent.`}
          isLoading={isCancellingLoading}
          onConfirm={handleCancelPayments}
        />
        <div className="flex justify-between">
          <button
            className={classNames(
              table.getSelectedRowModel().flatRows.length === 0 ||
                claims!.isAdmin !== true
                ? "bg-gray-200"
                : "bg-primary-600 hover:bg-primary-700",
              "mt-4 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
            )}
            type="button"
            disabled={
              table.getSelectedRowModel().flatRows.length === 0 ||
              claims!.isAdmin !== true
            }
            onClick={() => setOpen(true)}
          >
            Approve Payments
          </button>
          <button
            className={classNames(
              table.getSelectedRowModel().flatRows.length === 0 ||
                claims!.isAdmin !== true
                ? "bg-gray-200"
                : "bg-red-600 hover:bg-red-700",
              "mt-4 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
            )}
            type="button"
            disabled={
              table.getSelectedRowModel().flatRows.length === 0 ||
              claims!.isAdmin !== true
            }
            onClick={() => setCancelOpen(true)}
          >
            Cancel Payments
          </button>
        </div>
        <div className="mt-4 bg-white overflow-auto">
          <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="bg-primary-50 text-gray-800"
                >
                  {headerGroup.headers.map((header, i) => (
                    <th
                      key={header.id}
                      scope="col"
                      className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left whitespace-pre"
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? "cursor-pointer select-none"
                              : "",
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: " ▲",
                            desc: " ▼",
                          }[header.column.getIsSorted() as string] ?? null}
                        </div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, i) => {
                let rowCells = row.getVisibleCells();
                return (
                  <Fragment key={i}>
                    <tr
                      key={row.id}
                      onClick={() => openInNewTab(`leads/${row.original.id}`)}
                      className="hover:bg-gray-100 hover:cursor-pointer"
                    >
                      {rowCells.map((cell) => (
                        <td key={cell.id} className="px-3 py-3 border text-sm">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                    {row.getIsExpanded() && (
                      <></>
                      // <tr>
                      //   {/* 2nd row is a custom 1 cell row */}
                      //   <td colSpan={row.getVisibleCells().length}>
                      //     <LeadsTableSubComponent lead={row.original} />
                      //   </td>
                      // </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
